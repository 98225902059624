import React from 'react';
import PageIntro from '../components/PageIntro';
import CountrySort from '../components/CountrySort';
import FiveCountries from '../components/FiveCountries';
import CountryPagination from '../components/CountryPagination';
import DisplayAdvert from '../components/DisplayAdvert'; 

function Countries() {

  let skipVal = 0;
  let sortOrder = "-fields.publishedDate";
  let search = window.location.search;
  const countryParams = new URLSearchParams(search);
  let countryPage = countryParams.get('page');
  if (!countryPage) {
    countryPage = 1;
  }
  if (countryPage === 1) {
    skipVal = 0;
  }
  else {
    skipVal = (countryPage - 1) * 5;
  }
  let sortParam = countryParams.get('sort');
  if (!sortParam) {
    sortParam = "latest";
  }
  if (sortParam === "latest") {
    sortOrder = "-fields.publishedDate";
  }
  else if (sortParam === "AZ") {
    sortOrder = "fields.countryName";
  }
  else if (sortParam === "ZA") {
    sortOrder = "-fields.countryName";
  }

  // console.log("countryPage = " + countryPage);
  // console.log("skipVal = " + skipVal);

    return (
      <div id="mainContent">
        <PageIntro pageTitle="Countries" output="meta"/>
        <div id="countriesSection1" className="pageSection">
            <div className="row">
              <div className="col-md-9">
                <div id="pageIntroCountries">
                  <PageIntro pageTitle="Countries" output="intro"/>
                </div>
                <div className="sortCountries">
                  <CountrySort page={countryPage} sort={sortParam} />
                </div>
                <div className="countryPanel">
                  <FiveCountries skip={skipVal} sort={sortOrder} />
                </div>
                <div className="countryPagination">
                  <CountryPagination page={countryPage} sort={sortParam} />
                </div>
                <div className="affilAd adLand">
                <PageIntro pageTitle="Countries" output="advert"/>
                </div>
              </div>
              <div className="col-md-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
export default Countries;
