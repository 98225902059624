import { useEffect, useState } from 'react'
import { getSinglePageIntro } from '../Contentful'

export default function useSinglePageIntro(page) {
  const promise = getSinglePageIntro(page)
  //console.log("test 1");
  const [intro, setIntro] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setIntro(result[0])
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [intro, isLoading]
}
