import React from 'react';
import useSinglePageIntro from '../custom-hooks/useSinglePageIntro';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactHtmlParser from 'html-react-parser';

function PageIntro (props) {
  const page = props.pageTitle;
  const output = props.output;
  // console.log('page = ', page);
  const [pageIntro, isLoading] = useSinglePageIntro(page);
  // const [pageIntros, isLoading] = usePageIntros(page);
  // return <h1>Page Title = {props.pageTitle}</h1>;
  if (isLoading === true) {
    return <p>Loading...</p>;
  }
  // console.log("pageIntro = ", pageIntro);

  const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
            `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
    },
};

  const rawRichTextField = pageIntro.fields.introduction;
  const outputHTML = documentToHtmlString(rawRichTextField, options);
  const advertHTML = pageIntro.fields.advert.fields.advertHtml;

  if (output === "intro") {
    return (
      <div className="introText">
        {ReactHtmlParser(outputHTML)}
      </div>
    );
  }
  else if (output === "meta") {
    return (
      <HelmetProvider>
      <Helmet>
        <meta name="keywords" content={pageIntro.fields.keywords} />
        <meta name="description" content={pageIntro.fields.metaDescription} />
        <meta name="title" content={page} />
        <title>{page} - The Ordinary Tourist</title>
      </Helmet>
      </HelmetProvider>
    )
  }
  else if (output == "advert") {
    return (
      <div className="advertLink">
        {ReactHtmlParser(advertHTML)}
      </div>
    );
  }
}

export default PageIntro;
