import React from 'react';
import { Link } from 'react-router-dom';
import useFeaturedGames from '../custom-hooks/useFeaturedGames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import remarkUnwrapImages from 'remark-unwrap-images';

function FeaturedGames (props) {
  const [games, isLoading] = useFeaturedGames();
  if(isLoading === true) {
    return <p>Loading...</p>;
  }

//   const options = {
//     renderNode: {
//         [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
//             `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
//     },
// };

return (
    <div>
    {games.map(game => {
      // console.log("game = ", game);
      const linkURL = "../" + game.fields.url;
      const summaryArray = game.fields.summary.content[0].content;
      let markdownText1 = "";
      summaryArray.forEach(summary => {
        markdownText1 = markdownText1 + summary.value;
        }
      );
    return (
      <div className="featuredGame" key={game.fields.name}>
        <div className="row">
          <div className="col-3 gameCol">
            <Link to={linkURL} reloadDocument className="gameImgLink">
              <img className="gameImage img-fluid mx-auto roundedImage" src={game.fields.rollUpImage.fields.file.url} alt={game.fields.rollUpImage.fields.title} />
            </Link>
          </div>
          <div className="col-9 authorCol">
            <div className="gameBlock" id={game.fields.name}>
            <Link to={linkURL} reloadDocument className="gameListLink"><h2>{game.fields.name}</h2></Link>
              <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText1}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    );
  })}
    </div>
  );
}

export default FeaturedGames;
