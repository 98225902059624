import React from 'react';
import { Link } from 'react-router-dom';
import PageIntro from '../components/PageIntro';
import DisplayAdvert from '../components/DisplayAdvert';  
import {FacebookShareButton, TwitterShareButton} from "react-share";

let curRange = 1;
let yesArray = [];
let totalYes = 0;

const places1F = ["Anchorage", "Badger"];
const places1S = ["Fairbanks"];
const places1T = ["Juneau"];
const places2F = ["Ewa Gentry","Hilo","Honolulu"];
const places2S = ["Kahului","Kailua","Kaneohe"];
const places2T = ["Mililani Town","Pearl City","Waipahu"];
const places3F = ["Bellevue","Bellingham","Bremerton","Everett","Kennewick"];
const places3S = ["Kent","Marysville","Olympia","Renton","Seattle"];
const places3T = ["Spokane","Tacoma","Vancouver","Yakima"];
const places4F = ["Bend","Eugene","Gresham"];
const places4S = ["Hillsboro","Medford"];
const places4T = ["Portland","Salem"];
const places5F = ["Bakersfield","Concord","Fresno","Los Angeles"];
const places5S = ["Mission Viejo","Riverside","Sacramento"];
const places5T = ["San Diego","San Francisco","San Jose"];
const places6F = ["Carson City","Enterprise","Henderson"];
const places6S = ["Las Vegas","Paradise","Reno"];
const places6T = ["Sparks","Spring Valley","Sunrise Manor"];
const places7F = ["Boise","Caldwell","Coeur d'Alene"];
const places7S = ["Idaho Falls","Lewiston","Meridian"];
const places7T = ["Nampa","Pocatello","Twin Falls"];
const places8F = ["Billings","Bozeman","Butte"];
const places8S = ["Great Falls","Helena"];
const places8T = ["Kalispell","Missoula"];
const places9F = ["Casper","Cheyenne"];
const places9S = ["Gillette","Laramie"];
const places9T = ["Rock Springs"];
const places10F = ["Logan","Ogden","Provo"];
const places10S = ["Salt Lake City","St. George"];
const places10T = ["West Jordan","West Valley City"];
const places11F = ["Chandler","Gilbert","Glendale"];
const places11S = ["Mesa","Phoenix"];
const places11T = ["Scottsdale","Tucson"];
const places12F = ["Albuquerque","Farmington","Las Cruces"];
const places12S = ["Los Lunas","Rio Rancho"];
const places12T = ["Roswell","Santa Fe"];
const places13F = ["Aurora","Boulder","Colorado Springs"];
const places13S = ["Denver","Fort Collins","Grand Junction"];
const places13T = ["Lakewood","Pueblo","Thornton"];
const places14F = ["Bismarck","Fargo"];
const places14S = ["Grand Forks","Minot"];
const places14T = ["West Fargo"];
const places15F = ["Aberdeen","Pierre"];
const places15S = ["Rapid City"];
const places15T = ["Sioux Falls"];
const places16F = ["Bellevue","Fremont","Grand Island"];
const places16S = ["Kearney","Lincoln"];
const places16T = ["Norfolk","Omaha"];
const places17F = ["Kansas City","Lawrence","Lenexa"];
const places17S = ["Manhattan","Olathe","Overland Park"];
const places17T = ["Shawnee","Topeka","Wichita"];
const places18F = ["Broken Arrow","Norman"];
const places18S = ["Oklahoma City"];
const places18T = ["Tulsa"];
const places19F = ["Arlington","Austin","Corpus Christi","Dallas","Denton"];
const places19S = ["El Paso","Fort Worth","Houston","Killeen","Laredo"];
const places19T = ["Lubbock","McAllen","McKinney","Plano","San Antonio"];
const places20F = ["Baton Rouge","Houma","Lafayette"];
const places20S = ["Lake Charles","Metairie","Monroe"];
const places20T = ["New Orleans","Shreveport"];
const places21F = ["Fayetteville","Fort Smith"];
const places21S = ["Jonesboro","Little Rock"];
const places21T = ["Springdale"];
const places22F = ["Columbia","Independence"];
const places22S = ["Jefferson City","Kansas City"];
const places22T = ["Springfield","St. Louis"];
const places23F = ["Cedar Rapids","Davenport"];
const places23S = ["Des Moines","Iowa City"];
const places23T = ["Sioux City","Waterloo"];
const places24F = ["Bloomington","Brooklyn Park","Duluth"];
const places24S = ["Minneapolis","Plymouth","Rochester"];
const places24T = ["St. Cloud","St. Paul"];
const places25F = ["Appleton","Eau Claire","Green Bay"];
const places25S = ["Kenosha","La Crosse","Madison"];
const places25T = ["Milwaukee","Racine"];
const places26F = ["Aurora","Bloomington","Champaign","Chicago"];
const places26S = ["Elgin","Joliet","Naperville","Peoria"];
const places26T = ["Rockford","Round Lake Beach","Springfield"];
const places27F = ["Biloxi","Gulfport","Hattiesburg"];
const places27S = ["Jackson","Pascagoula"];
const places27T = ["Southaven","Tupelo"];
const places28F = ["Birmingham","Huntsville"];
const places28S = ["Mobile","Montgomery"];
const places28T = ["Tuscaloosa"];
const places29F = ["Chattanooga","Clarksville","Johnson City"];
const places29S = ["Kingsport","Knoxville","Memphis"];
const places29T = ["Murfreesboro","Nashville"];
const places30F = ["Bowling Green","Covington","Elizabethtown"];
const places30S = ["Frankfort","Lexington","Louisville"];
const places30T = ["Owensboro","Paducah","Richmond"];
const places31F = ["Bloomington","Carmel","Elkhart"];
const places31S = ["Evansville","Fort Wayne","Indianapolis"];
const places31T = ["Lafayette","South Bend"];
const places32F = ["Ann Arbor","Detroit","Flint","Grand Rapids"];
const places32S = ["Holland","Kalamazoo","Lansing","Muskegon"];
const places32T = ["Saginaw","South Lyon","Sterling Heights","Warren"];
const places33F = ["Akron","Canton","Cincinnati"];
const places33S = ["Cleveland","Columbus","Dayton"];
const places33T = ["Lorain","Toledo","Youngstown"];
const places34F = ["Beckley","Charleston","Huntington"];
const places34S = ["Morgantown","Parkersburg"];
const places34T = ["Weirton","Wheeling"];
const places35F = ["Alexandria","Arlington","Charlottesville","Chesapeake"];
const places35S = ["Fredericksburg","Hampton","Lynchburg","Newport News"];
const places35T = ["Norfolk","Richmond","Roanoke","Virginia Beach"];
const places36F = ["Asheville","Charlotte","Concord"];
const places36S = ["Durham","Fayetteville","Greensboro"];
const places36T = ["Raleigh","Wilmington","Winston-Salem"];
const places37F = ["Charleston","Columbia","Greenville"];
const places37S = ["Mauldin","Myrtle Beach","North Charleston"];
const places37T = ["Rock Hill","Spartanburg"];
const places38F = ["Athens","Atlanta","Augusta"];
const places38S = ["Columbus","Gainesville","Macon"];
const places38T = ["Savannah","Warner Robins"];
const places39F = ["Bonita Springs","Cape Coral","Jacksonville","Kissimmee","Miami"];
const places39S = ["Orlando","Palm Bay","Palm Coast","Pensacola","Port St. Lucie"];
const places39T = ["Sarasota","St. Petersburg","Tallahassee","Tampa"];
const places40F = ["Albany","Bronx","Brookhaven","Brooklyn","Buffalo"];
const places40S = ["Hempstead","Huntington","Manhattan","New York","Queens"];
const places40T = ["Rochester","Staten Island","Syracuse","Yonkers"];
const places41F = ["Allentown","Erie","Harrisburg","Lancaster"];
const places41S = ["Philadelphia","Pittsburgh","Pottstown"];
const places41T = ["Reading","Scranton","York"];
const places42F = ["Annapolis","Baltimore","Columbia"];
const places42S = ["Frederick","Germantown","Hagerstown"];
const places42T = ["Salisbury","Silver Spring","Waldorf"];
const places43F = ["Washington D.C"];
const places43S = [""];
const places43T = [""];
const places44F = ["Bear","Dover","Lewes"];
const places44S = ["Middletown","Milford"];
const places44T = ["Newark","Wilmington"];
const places45F = ["Atlantic City","Edison","Elizabeth"];
const places45S = ["Jersey City","Lakewood","Newark"];
const places45T = ["Paterson","Trenton","Woodbridge"];
const places46F = ["Bridgeport","Danbury","Hartford"];
const places46S = ["New Haven","Norwich"];
const places46T = ["Stamford","Waterbury"];
const places47F = ["Cranston","East Providence"];
const places47S = ["Pawtucket","Providence"];
const places47T = ["Warwick","Woonsocket"];
const places48F = ["Barnstable","Boston","Cambridge"];
const places48S = ["Leominster","Lowell","New Bedford"];
const places48T = ["Springfield","Worcester"];
const places49F = ["Barre","Burlington","Colchester"];
const places49S = ["Essex","Montpelier"];
const places49T = ["Rutland","South Burlington"];
const places50F = ["Concord","Dover"];
const places50S = ["Manchester","Nashua"];
const places50T = ["Portsmouth"];
const places51F = ["Auburn","Augusta","Bangor"];
const places51S = ["Lewiston","Portland","Sanford"];
const places51T = ["South Portland","Waterville"];

let placesObj = {places1F, places1S, places1T, places2F, places2S, places2T, places3F, places3S, places3T, places4F, places4S, places4T, places5F, places5S, places5T, places6F, places6S, places6T, places7F, places7S, places7T, places8F, places8S, places8T, places9F, places9S, places9T, places10F, places10S, places10T, places11F, places11S, places11T, places12F, places12S, places12T, places13F, places13S, places13T, places14F, places14S, places14T, places15F, places15S, places15T, places16F, places16S, places16T, places17F, places17S, places17T, places18F, places18S, places18T, places19F, places19S, places19T, places20F, places20S, places20T, places21F, places21S, places21T, places22F, places22S, places22T, places23F, places23S, places23T, places24F, places24S, places24T, places25F, places25S, places25T, places26F, places26S, places26T, places27F, places27S, places27T, places28F, places28S, places28T, places29F, places29S, places29T
    , places30F, places30S, places30T, places31F, places31S, places31T, places32F, places32S, places32T, places33F, places33S, places33T, places34F, places34S, places34T, places35F, places35S, places35T, places36F, places36S, places36T, places37F, places37S, places37T, places38F, places38S, places38T, places39F, places39S, places39T
    , places40F, places40S, places40T, places41F, places41S, places41T, places42F, places42S, places42T, places43F, places43S, places43T, places44F, places44S, places44T, places45F, places45S, places45T, places46F, places46S, places46T, places47F, places47S, places47T, places48F, places48S, places48T, places49F, places49S, places49T
    , places50F, places50S, places50T, places51F, places51S, places51T};

const title1 = ["Alaska"];
const title2 = ["Hawaii"];
const title3 = ["Washington"];
const title4 = ["Oregon"];
const title5 = ["California"];
const title6 = ["Nevada"];
const title7 = ["Idaho"];
const title8 = ["Montana"];
const title9 = ["Wyoming"];
const title10 = ["Utah"];
const title11 = ["Arizona"];
const title12 = ["New Mexico"];
const title13 = ["Colorado"];
const title14 = ["North Dakota"];
const title15 = ["South Dakota"];
const title16 = ["Nebraska"];
const title17 = ["Kansas"];
const title18 = ["Oklahoma"];
const title19 = ["Texas"];
const title20 = ["Lousiana"];
const title21 = ["Arkansas"];
const title22 = ["Missouri"];
const title23 = ["Iowa"];
const title24 = ["Minnesota"];
const title25 = ["Wisconsin"];
const title26 = ["Illinois"];
const title27 = ["Mississippi"];
const title28 = ["Alabama"];
const title29 = ["Tennessee"];
const title30 = ["Kentucky"];
const title31 = ["Indiana"];
const title32 = ["Michigan"];
const title33 = ["Ohio"];
const title34 = ["West Virginia"];
const title35 = ["Virginia"];
const title36 = ["North Carolina"];
const title37 = ["South Carolina"];
const title38 = ["Georgia"];
const title39 = ["Florida"];
const title40 = ["New York"];
const title41 = ["Pennsylvania"];
const title42 = ["Maryland"];
const title43 = ["District of Columbia"];
const title44 = ["Delaware"];
const title45 = ["New Jersey"];
const title46 = ["Connecticut"];
const title47 = ["Rhode Island"];
const title48 = ["Massachusetts"];
const title49 = ["Vermont"];
const title50 = ["New Hampshire"];
const title51 = ["Maine"];

let titleObj = {title1, title2, title3, title4, title5, title6, title7, title8, title9, title10,title11, title12, title13, title14, title15, title16, title17, title18, title19, title20,title21, title22, title23, title24, title25, title26, title27, title28, title29, title30,title31, title32, title33, title34, title35, title36, title37, title38, title39, title40,
    title41, title42, title43, title44, title45, title46, title47, title48, title49, title50, title51};

function States() {
  const linkURL = window.location.href;
  const [isPrevDisabled, setIsPrevDisabled] = React.useState(true);
  const [isNextDisabled, setIsNextDisabled] = React.useState(false);

  const prevRange = (e) => {
    e.preventDefault();
    const places1 = document.getElementById("places1");
    const places2 = document.getElementById("places2");
    const places3 = document.getElementById("places3");
    const stateText = document.getElementById("stateText");
    const checkboxSlider = document.getElementById("checkboxSlider");
    const prevRange = curRange - 1;
    const currAct = "state" + curRange + "Act";
    const prevAct = "state" + prevRange + "Act";
    document.getElementById(currAct).classList.add("hidden");
    document.getElementById(prevAct).classList.remove("hidden");
    if (curRange === 2){
      setIsPrevDisabled(!isPrevDisabled);
    }
    if (curRange === 51){
      setIsNextDisabled(!isNextDisabled);
    }
    curRange--;
    const rangeCheck = yesArray.includes(curRange);
    if (rangeCheck === true) {
      checkboxSlider.checked = true;
    }
    else {
      checkboxSlider.checked = false;
    }
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const key1 = "places" + curRange + "F";
    const key2 = "places" + curRange + "S";
    const key3 = "places" + curRange + "T";
    const lookup1 = placesObj[key1];
    const lookup2 = placesObj[key2];
    const lookup3 = placesObj[key3];
    lookup1.forEach(function(el){
       places1.innerHTML = text1+= el + '</br>';
     });
    lookup2.forEach(function(el){
       places2.innerHTML = text2+= el + '</br>';
     });
    lookup3.forEach(function(el){
         places3.innerHTML = text3+= el + '</br>';
      });
      const stateKey = "title" + curRange;
      const stateLabel = titleObj[stateKey];
      stateText.innerHTML = "Have you been to " + stateLabel + "?";
    return true;
  }

  const nextRange = (e) => {
    e.preventDefault();
    const places1 = document.getElementById("places1");
    const places2 = document.getElementById("places2");
    const places3 = document.getElementById("places3");
    const stateText = document.getElementById("stateText");
    const checkboxSlider = document.getElementById("checkboxSlider");
    const nextRange = curRange + 1;
    const currAct = "state" + curRange + "Act";
    const nextAct = "state" + nextRange + "Act";
    document.getElementById(currAct).classList.add("hidden");
    document.getElementById(nextAct).classList.remove("hidden");
    if (curRange === 1){
      setIsPrevDisabled(!isPrevDisabled);
    }
    if (curRange === 50){
      setIsNextDisabled(!isNextDisabled);
    }
    curRange++;
    const rangeCheck = yesArray.includes(curRange);
    if (rangeCheck === true) {
      checkboxSlider.checked = true;
    }
    else {
      checkboxSlider.checked = false;
    }
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const key1 = "places" + curRange + "F";
    const key2 = "places" + curRange + "S";
    const key3 = "places" + curRange + "T";
    const lookup1 = placesObj[key1];
    const lookup2 = placesObj[key2];
    const lookup3 = placesObj[key3];
    lookup1.forEach(function(el){
       places1.innerHTML = text1+= el + '</br>';
     });
    lookup2.forEach(function(el){
       places2.innerHTML = text2+= el + '</br>';
     });
    lookup3.forEach(function(el){
         places3.innerHTML = text3+= el + '</br>';
      });
    const stateKey = "title" + curRange;
    const stateLabel = titleObj[stateKey];
    stateText.innerHTML = "Have you been to " + stateLabel + "?";
    return true;
  }

  const checkYes = (e) => {
    const checkboxSlider = document.getElementById("checkboxSlider");
    const resultText = document.getElementById("resultText");
    if (checkboxSlider.checked === true){
      totalYes++;
      const rangeID = 'state' + curRange + 'Yes';
      document.getElementById(rangeID).classList.remove("hidden");
      yesArray.push(curRange);
      let x = Math.round(totalYes/51*100);
      resultText.innerHTML = "You have been to " + x + "%";
      // console.log("totalYes = ", totalYes);
      return true;
    }
    else {
      totalYes--;
      const rangeID = 'state' + curRange + 'Yes';
      document.getElementById(rangeID).classList.add("hidden");
      const index = yesArray.indexOf(curRange);
      if (index > -1) { // only splice array when item is found
        yesArray.splice(index, 1); // 2nd parameter means remove one item only
      }
      let x = Math.round(totalYes/51*100);
      resultText.innerHTML = "You have been to " + x + "%";
      // console.log("totalYes = ", totalYes);
      return true
    }

    
  }

  const handleClick = () => {
    console.log("Button clicked!");
  };
  

    return (
      <div id="mainContent">
        <PageIntro pageTitle="States" output="meta"/>
        <div id="stateSection1" className="pageSection">
            <div className="row">
              <div className="col-md-9">
                <div id="pageIntrostate">
                  <PageIntro pageTitle="States" output="intro"/>
                </div>
                <div className="statePanel">
                    <div className="resultRow">
                        <h2 id="resultText">You have been to 0%</h2>
                    </div>
                    <div className="stateImage">
                    <svg version="1.1" id="usa" x="0px" y="0px"
	 viewBox="0 0 728 504.3">
<path id="Massachusetts" class="st0" d="M691.8,139l3,0.7l-2.6,0.9L691,142L691.8,139z M684.1,137.4l-0.1-0.9l-0.5,0.5l-1.7-1.1
	l-1.6-3.5l-5.1,1.4l-11.8,2.8l-8.9,2l-0.5-0.4l0.2-11.9l4.3-1l5.5-1.2l0,0l14.2-3.5l1.4-2.4l3.8-2.2l1.2,2.5l2.6,0.7l-3,2.5
	l-0.9,4.3l1.6-0.6l2.7,1.1l4.1,5.8l3.6,0.7l2.7-2.2l-1.6-4l2.2,2.6l0.9,3.1l-3,1.1l-5.5,3.8l-0.6-3.2l-2.6,2.2l0.1,1.6l-2.2,1.4
	l-0.7-2.5h-1L684.1,137.4L684.1,137.4z"/>
<path id="Washington" onClick={handleClick} class="st0" d="M68.8,12.4l1.4,2l-2.4,0.7l0.4,3L65,15.5l1.6-0.4L68.8,12.4z M70.5,19.6l-2.1,2.1l1.2,0.9
	l1.5,5.1l-2.2-1.4l0.2-3.1l-1-2L70.5,19.6L70,19.1l-0.1-2.6l2,2.4l1.2-2l0.2-2.8l-1.6-1l-0.9-3.5l0.9-0.7L134.5,26l-10.4,44.3
	l0.7,3.7l-0.4,3.1L99.9,71l-1.4,0.6l-5.8-1.1l-8.3,1.1l-4.5-1.1L76.2,71l-3.1-0.7l-0.7-1.6l-5.9-2l-6.2,1l-5-3.3l0.7-5.5l-0.6-2.5
	l-2.7-2.2L50.3,54l-0.5-2.4l-2.4-1.4L45,50.7L43.4,48l1.6-4.8l-0.4,3.6l1.6-1.2l1-3.3l-2.1-0.6l0.4-2.5l4-0.1l-3.6-2.6v-5.8l0.9-6.7
	l-1.5-3.6l0.4-4.7l2.8-2.7l4.7,5.1l8.5,3.8l1.5-0.7l1,2.8l3-1l0.4,4.6L65,30.8l3.8-3.1l-2.4,5l1.5,4.3l-4.2-0.5l-1.4,2.6l0.5-3.7
	l-3.6,3.7l4.2,1.1l2.1-2.8l2.7-0.5v-3.3l1-3.5l3.1-2.8l-1.2-4.8l0.9-1.1L70.5,19.6z" onclick="alert('You have clicked Washington.')" />
<path id="California" class="st0" d="M19.2,119.5l51.3,15.2l-13.3,50l18.7,28.1l15,23.2l21.8,32.8l1.7,9.8l2.6,3.8l-4.1,1.9
	l-2.6,2.8l-0.2,3.7l-3,4.6l-1.4,0.1l-0.9,5.5l2.8,2.5l-1.7,4l-2.7-0.1l-34.6-4.6l0.5-2.7l-1.7-0.6l0.7-7.1l-1.7-4.3l-7.4-9.5
	l-3.5-0.5l0.7-1.6l-1.2-4l-3.8-0.2l-5-4l-0.4-2l-4.1-4l-3.6-0.7l-3.2-2.1l-4.7-0.9l-1.7-3l2.6-9l-2.8-2.8l1.2-1.1l-3.1-6.3l-1.5-1
	l-3.3-10.5l-1.7-2.4l0.2-4.8l2.7-1.1l0.9-2l-1.1-3.3l-2.8-0.7l-2.2-4.6l0.9-2.7l-0.7-3.3l1.4-4.1l1.5-0.1l-0.4,3.7l3.5,3.6l-0.5-4
	l-1.6-2.6l0.6-4l3.2,0.5l-2.8-2.8l-1.6,0.2l-1.7,4.8l-3.8-5.1l-0.7-7.8l-5.2-10l1-1.6l-0.1-5.8l1.7-3.1l0.5-5.1l-1.9-5.5l-2.4-3.6
	l0.4-3.1l6.7-8.7l2.7-6.3l0.4-5.3l-1-1L19.2,119.5z"/>
<path id="Oregon" class="st0" d="M70.6,134.7l-51.3-15.2l-1.2-2.5l0.2-4l2-5.3l-0.6-3.5l2.2-2.7l2.5-4.8l2.7-2.8l2.7-5.2l5.1-12
	l0.7-3.2L41.4,62l0.1-3.7l1.6-3.3l0.5-3.8l4.2,1.6l1.9-0.7l0.5,2.2l2.5,0.1l2.7,2.2l0.6,2.5l-0.7,5.5l5,3.3l6.2-1l5.9,2l0.7,1.6
	l3.1,0.7l3.7-0.5l4.5,1.1l8.3-1.1l5.8,1.1l1.4-0.6l24.7,6.1l0.7,3.1l2.4,2.1l0.1,3.1l-3.1,3.5l-3.8,6.7l-6.2,6.3l-0.6,3.1l3.1,2.8
	l-3,6.3l-6.9,30L70.6,134.7z"/>
<path id="Wisconsin" class="st0" d="M480.4,112l-0.6,4l-2.5,5.3l-1.5-1.6l1.6-4.6L480.4,112z M439.2,164.4l-0.9-2.4l-4.3-1.4
	l-1.9-7.1l0.9-2.2l-2.2-3.7l-0.9-7.2l-1.7-1.7l-3-0.7l-5.7-6.1l-3.7-2.4l-2.4-0.5l-3-2.5l0.6-3l-0.1-6.4l0.9-2.5l-2.7-2.2l-0.1-2.5
	l2-3.5l4.8-3.1l-0.4-9.9l2-2l3.8,0.9l7.1-3.1l2.6-1.6l2.6,0.2l-0.5,2.6l1.7,2.2l3,0.7l2.2,1.4l1.5,2.8l12,2.5l3.6,1.6l7.8,0.7
	l1.6,2.8l3.5,1.4l-0.2,5.8l2.5-0.1l-0.6,3l1.6,1.2l-2.5,2.8l-2,6.8l1.4,0.1l3.2-5.3l2-0.9l1.6,1.2l-1.9,8.8l0.7,1.5l-2.4,5.3
	l0.6,3.7l-1.9,7.8l0.5,4.2l1.7,3.5l0.2,5.5l-22.2,1.6l-11.4,0.6L439.2,164.4z"/>
<path id="Maine" class="st0" d="M704.4,83.4l1.4-2.4l1.5,1l-1.6,3.8L704.4,83.4z M678.3,105.3l-9.5-28.6l2.4,0.5l1-6.2l2.5-3.6
	l0.6-4.3l-1.1-1.7v-5.2l1-1.4l-0.6-5l4.7-14.9l2.2-0.5l1.4,3l1.7,0.5l7.2-5.1l5.9,3.7l7.3,22.4l4.3-0.1l1.4,5.5l2,1.5l2-1l4.8,4.6
	l-2.1,4.3l-1.9-0.5l-0.4,2.1L710,78l0.4,2.5l-1.5,1.6l-1.4-2.2l-3.8,1l-1.4,4l1.6,4l-4-4.6l-0.1-3l-2.4,2.8l0.7,6.3l-1.6,2.7
	l-1.5-0.2l-1,2.8l-2.2,0.7l-0.9,2.5l-2.1-2l-2.7,3.5l1,2.1l-3.1,6.1l-1,3.7l-4.1-4.2L678.3,105.3z"/>
<path id="Michigan" class="st0" d="M519.6,94.6l0.4,2.5l-2.8,0.1l0.6-2.8L519.6,94.6z M458,64.7l-1.9,3l-5,3.5L451,69L458,64.7z
	 M531,168.8l-10.5,2l-6.3,1l-0.1-1l-25.8,2.8l2.7-3.3l2.4-5.8l1.2-8.1l-0.5-4.3l-5.2-11l1-2.8l-1.5-5.1l2.6-5.3l-0.6-5.5l1.9-1.2v-3
	l2.7-0.9l2-4.3l1,2l-0.6,3.7l1.2,1l1.5-2.7l-0.2-5.1l2.4-3l2.2-0.4l-1.6-4.2l3.3-3.3l3.5,1.6l-1.2-2.2l2.7,0.1l-1,2.2l2.8,0.1
	l1.9,2.2l7.3,1.5l1.7,1.2l1.4,3.5l-1.6,1.4l2.7,4.8l0.1,6.6l-2,1.4l-0.2,3.6l-3.1,1.6l0.2,5.3l2.8,1l2.1-2l2.5-5.3l3.7-2l3.2,2.1
	l3.6,10.8l2,4.2l-0.2,6.6l-3.8,0.5l-0.4,4.3l-2.6,2.8l-0.9,5.9L531,168.8z M458.8,82.5l1.7,2.7l0.1,3.2l2.5-2.8l5.2,0.2l2.1,1.1
	l3.8,5.1l4.3-0.9l1.2,1.4l3.5-0.5l4.8-4.5l7.3-0.7l3.6-2.1l2.8-0.5l-0.7,4.6l2.7,0.9l2-1l1.2,1.2l1.6-1.6l3.6-0.7l0.2,5l3.5,3.6
	l-1.5,1l-6.1,0.4l-1.6-1l-0.5,3.6l-3.7-2.5l-4-1l-2.4,0.5l-1.2,2.1l-6.9,1.4l-1.2,2.7l-3.8,2.7l1.4-4l-2.5,0.7l-2.4,2.8l-0.5-3.2
	l-5.8,14.6l-1.6-1.2l0.6-3l-2.5,0.1l0.2-5.8l-3.5-1.4l-1.6-2.8l-7.8-0.7l-3.6-1.6l-12-2.5l-1.5-2.8l-2.2-1.4l4.5-2.1l2.5-2.5l4.6-1
	l3-2.6l1.9-0.5l3.6-4.1l0.5,1l1.6-3.5l4.2-2.2l3.8,0.5l-5.6,4.8l-1.9,3.6l-0.2-2.4L458.8,82.5z"/>
<path id="Nevada" class="st0" d="M70.6,134.7l36.7,9.4l25.4,5.7l11.5,2.5l-9.4,47.4l-7.3,36.9l-2.7,13.1l-1.6,2.7l-5.5-3.1l-2.5,0.7
	v3.8l-1.4,6.1l0.2,5.8l-1.4,3.1L90.8,236l-15-23.2l-18.7-28.2L70.6,134.7z"/>
<path id="NewMexico" class="st0" d="M194.5,248.4l81.9,9.2l-0.5,6.4l-0.1,2.1l-0.6,0.4l-6.3,76.9l-52-5.1l1.7,4.3l-24.8-3.3l-1,7.7
	l-12.1-1.6l6.9-48.7L194.5,248.4z"/>
<path id="Colorado" class="st0" d="M276.4,257.6l-81.9-9.2l8.2-57.5l1.6-10.7l63.8,7.6l25.8,2.1l-1.2,17.1l-0.6,11.3l-2.6,40.3
	L276.4,257.6z"/>
<path id="Wyoming" class="st0" d="M268.1,187.8l-63.8-7.6l-25.5-4l2.8-17.1l2.2-13.3l4.8-29.2l1.5-8.5l84.1,10.9l-2.5,28l-0.6,6.4
	l-0.5,6.4L268.1,187.8z"/>
<path id="Kansas" class="st0" d="M292.6,207l88,2.6l5.7,3.1l-2.8,5l2.7,2.5l0.9,2.8l2.8,1.6l0.6,36.5l-101.1-2.6l2.6-40.3L292.6,207
	z"/>
<path id="Nebraska" class="st0" d="M380.6,209.6l-88-2.6l1.2-17.1l-25.8-2.1l2.5-27.9l0.5-6.4l69.5,4.1l5.1,3.5l3-1l6.7-0.2l2.1,1.7
	l5.2,2l2.8,2.8l1,0.4l0.6,5.1l2.5,3.7l1.2,3.3l-0.2,4.2l2,1.4l0.7,2.8l0.5,8.8l0.9,3.1v0.2l0,0h-0.1l0,0l1.9,4.5L380.6,209.6z"/>
<path id="Oklahoma" class="st0" d="M289.4,258.6l101.1,2.6v2.2l0.1,6.4l2.7,19.1l-0.1,30l-5.6-1.7l-5-3.6l-1.9,1.6l-3.5-1.4
	l-2.5,1.6l-2.4-0.6l-5.6,3.5l-4.5-3.7l-3,0.7l-0.9-1.4l-2.4,1.6l-0.5,2.4l-1.4-3.2l-2.4,1.4l-4.3-2.7l-4,1.5l-1.9-4.3l-5.6,1
	l-6.4-2.5l-3.5-0.5l-0.9-3.1h-5.2l-4.3-3.2l1.3-33.2l-41-2.6l0.1-2.1l0.5-6.4L289.4,258.6z"/>
<path id="Missouri" class="st0" d="M390.6,269.9l-0.1-6.4v-2.2l-0.6-36.5l-2.8-1.6l-0.9-2.8l-2.7-2.5l2.8-5l-5.7-3.1l-4.1-5.8
	l-1.9-4.5l0,0h0.1l16.6,0.2l18-0.5l17.6-1.4l4,4l0,0l0,0l-0.9,3.6l1.2,5.3l1.4,2.6l4.2,4.2l3.1,2.1l1.9,5.5l1.7,2l2-1.6l4.1,2.1
	l-2.8,9.3l4,4.6l2.6,1l5.3,4l1.6,4.3l-0.7,3.1l2.1,2.8l3.7,1.6l0.1,4.3l-1.4,3l-1.6-1.1l-0.7,2.5l-0.6,0.9l-0.2-0.7l-1.2-1.1l0.2,1
	l0.5,2l-2.1,2.5l1.2,1.6l-1.4,2.5l-8.9,0.7l3.8-5.5l-1.5-3.5L390.6,269.9z"/>
<path id="Illinois" class="st0" d="M474.7,162.1l0.1,3.7l4.7,9l3.6,40.6l-1.4,4.3l1.9,2.4l0.7,3.5l-1.5,4.3l-1.5,1.1l-1.9,5.3
	l-1.2,1.2l0.2,5.1l-1.4,3l0.7,2.8l-4.5,1.5l0.2,5.8l-5.9-2l-1.9,0.4l-1.6,2.6l0.9,1.4l-3.7-1.6l-2.1-2.8l0.7-3.1l-1.6-4.3l-5.3-4
	l-2.6-1l-4-4.6l2.8-9.3l-4.1-2.1l-2,1.6l-1.7-2l-1.9-5.5l-3.1-2.1l-4.2-4.2l-1.4-2.6l-1.2-5.3l0.9-3.6l0,0l0,0l0.6-3.7l2.5-1
	l2.6-5.2l0.1-2.2l-1.7-2l0.9-4.2l3.7-0.5l4.3-3l1.6-3.7l0.1-5.2l-3.1-2l-3.2-4.5l0,0l2-0.1l15.9-0.9L474.7,162.1z"/>
<path id="Indiana" class="st0" d="M479.6,174.8l3.1,1.5l5.5-2.8l25.8-2.8l0.1,1l0.9,6.9l4.2,37.5l-0.4,3.1l1,2.1l-4.7,2.2l-3.6-0.1
	l0.7,3.1l-2.4,2.2l-0.4,2.2l-2,0.6l-0.9,4l-1.6,1.9l-3.5-2.6l-3,2.5v1.6l-2.7,0.7l-1.2-1.5l-4.3,3l-3.7-1.4l-2.2,1.7l-4-0.9l-2,2.4
	l-0.2-5.1l1.2-1.2l1.9-5.3l1.5-1.1l1.5-4.3l-0.7-3.5l-1.9-2.4l1.4-4.3L479.6,174.8z"/>
<path id="Vermont" class="st0" d="M658.5,125.4l-4.3,1l-3.2-13.5l-2.4-0.1v-2.7l-2.1-5l0.5-3.3l-0.6-4.1l-1.2-1.5l-1.6-7.3l7.6-2.1
	l14-3.8l-0.2,5l1.6,2.1l-0.7,2.8l-3.6,4l0.5,2.5l-0.4,6.4l-1.1,4.8l1,4v7.6l1.7,2.2l0,0L658.5,125.4z"/>
<path id="Arkansas" class="st0" d="M399.8,329.9l-0.2-9.3h-4l-2.4-1.6l0.1-30l-2.7-19.1l61.1-2.5l1.5,3.5l-3.8,5.5l8.9-0.9l0.2,2.8
	l-2.8,1.9l0.5,3.2l-2.5,3l0.2,4.5l-2.6,2.4l0.5,1.4l-2.6,1.9l-1.6,3.3l0.2,3.7l-4.8,5l0.7,2l-2.5,0.7l0.7,3.1l-2.5,2.4l1.6,3.5
	l-0.6,2.8l1.4,2.8l-0.9,2.5l-2.7,0.1L399.8,329.9z"/>
<path id="Texas" class="st0" d="M393.2,319l2.4,1.6h4l0.2,9.3l0.4,17.5l3.5,4.8l-0.1,2.2l4.8,9.3l-0.7,4l-2.2,4.5l0.4,8.4l-1.2,1.6
	l-1.9,3.7l0.9,1.7l-2.8-0.1l-8.8,4.2l1.4-2.1l-2.8,0.9l0.7-4l-4.2,1.4l-0.6,2.2l4.6,3.6l-3.2,2l0.7-2.7l-4.1,5.8l-6.6,5l-5.3,1.4
	l-0.9,1.6l-6.1,3.5l-0.2,1.1l-4.3,2.5l-4.8,5.1l2.1-4.2l-2.1-1.4l-3,2.4l3,0.6l-2.8,3.7l-2.7-0.1l1.9,2.7l-2.4,5.9l-1.2-1.1
	l-3.6,2.2h4.2l-1.9,4.6l3.6,15.6l2.6,2.8l-4.5,2l-5.8-3.7l-6.6-0.4l-2.2-2.2l-4.2-1l-2.5-2.2l-4.5-0.9l-1-5.5l-4.5-6.1l-0.7-4.5
	l0.4-4.6l-3.2-1.5l-5.3-8.9l-3.1-2.6l-1.5-5.3l-3.1-5.8l-0.5-3.5l-5-5.1l1.2-1.4l-3-0.9l-3.6-4.8l-10.5-1l-3.1-1.9l-1.4,2l-4.2,0.2
	l-3.8,7.8l0.5,0.9l-4.7,4l-2.4-0.5l-7.1-5.5l-3.8-1.4l-2.8-3.5l-3.7-2.8l-1.9-5l0.4-4.3l-4-8.8l-5-3.6l-8.4-10.5l-2.2-1.1l-2.4-5
	l-2-1l-1.7-4.3l52,5.1l6.3-76.9l0.6-0.4l41,2.6l-1.4,33.1l4.3,3.2h5.8l0.9,3.1l3.5,0.5l6.4,2.5l5.6-1l1.9,4.3l4-1.5l4.3,2.7l2.4-1.4
	l1.4,3.2l0.5-2.4l2.4-1.6l0.9,1.4l3-0.7l4.5,3.7l5.6-3.5l2.4,0.6l2.5-1.6l3.5,1.4l1.9-1.6l5,3.6L393.2,319z M353.6,423.2l-3.2,7.8
	l-0.1,4.8l1.7,8.2l-2-6.3l0.5-9L353.6,423.2z"/>
<path id="RhodeIsland" class="st0" d="M685.8,139.9l-0.9,0.5l-0.7-2.8h0.9L685.8,139.9z M675.2,133.8l5.1-1.4l1.6,3.5l1.7,1.1l1,3.7
	l-2.4,1l-0.4,2l-4.1,2.2l-0.2-3.7L675.2,133.8z"/>
<path id="Alabama" class="st0" d="M498.9,367.8l-3.1,4.3l-9.8,1.1l3-1l-1.2-1.5l-3.2-0.1l-3.3-25.9l1.4-52.2l-1.4-1.7l-0.1-0.1
	l35.9-3.3l9.8,34.3l2.4,5.5l3.3,5.5l-1.9,2.6l-0.7,5.6l2.1,4.8l-0.5,4.6l2.1,4.5l-37.9,4.2l-0.4,2.2l3.7,3.7v3L498.9,367.8
	L498.9,367.8z"/>
<path id="Mississippi" class="st0" d="M484.5,370.8l-6.7,0.1l-6.9,2.4l-2.7,2.2l-5.3-8.2l1.2-5.6l-28.2,1.6l1.1-1l-1.2-3.7l1.5-0.2
	v-3.7l1.4-1.4l1.2-5.1l3.5-2.8l2.2-5.8l-2.7-1.7l-1.2-5.5l1-2.7l-1.5-1.5l0.9-2.5l-1.4-2.8l0.6-2.8l-1.6-3.5l2.5-2.4l-0.7-3.1
	l2.5-0.7l-0.7-2l4.8-5l-0.2-3.7l1.6-3.3l2.6-1.9l-0.5-1.4l23.5-1.6l6.1-0.5l1.4,1.7l-1.4,52.2L484.5,370.8z"/>
<path id="NorthCarolina" class="st0" d="M645,238.4l0.2,0.7l-1.7-0.5l0,0l0.6-0.1l0.1,0.1l0.2-0.1l0.2-0.1H645z M645.7,238.3
	l1.9,4.3l6.3,8.5l-4.6-5l-4-7.8l0.1-0.1L645.7,238.3L645.7,238.3z M567.4,252.4l5.9-0.2l15.4-2.1l54.6-11.3l5.1,7.1l-6.4-2.5
	l2.5,1.9l-5.7,4l-2.4-0.2l-0.2,2l9-2.6l1.5,5.5l-0.4-4.1l1.5-2l2.4,2.2l0.1,4.5l-1.6-0.7l-1.5,4.8l-2.7,1.2l-4.7-1.8v1.9l-5.7-0.5
	l7.4,1.6l-1.9,2.8l1.7,0.5l-3.3,3.1l-3.8-2.1l2.6,2.6l2.7,0.6l3-1l0.6-2.6l2.4,2.5l-3.3,4.5l-6.9,1.6l-6.3,6.7l-1.7,3.3l-0.7,6.6
	l-4.2-0.5l-4.3,1.6l-17.8-12.6l-15.6,2.2l-0.6-2.2l-4.5-2.7l-16.8,1.9l-10.9,5.2l-5.2,0.9l-11.5,1.6l0.2-4.7l2.4-0.4l0.7-3l3-2.7
	l3.7-0.7l5.3-4.6l1.9-3l3.6-2.5l0.9,1.4l4.7-4l1.7,0.7l1.7-3.7l2.1-2L567.4,252.4z"/>
<path id="Virginia" class="st0" d="M645.3,238.4l-1.7-2.5l0.5,2.7l-0.6,0.1l0,0l-0.5-0.5l0.4,0.6l-54.6,11.3l-15.4,2.1l-5.9,0.2
	l-4,0.9l-23.3,3.3l6.9-3.5l3.1-3l2.7-5.1l3.6-2.5l4.5-5.2l2.6,4l4,0.7l2.5-2.5l1.9,1.1l3.6-1.4l1-2.5l1.7,0.5l5.5-3.1l-0.4-3.1
	l4.8-11.1l1.1-5.7l5,2l2.7-7.8l1.6,0.7l3.2-4.2l1.6-3.5l0.2-5l7.3,4l0.6-3.5l3.8,0.7l-0.4,1.6l5.9,2.6l0.4,0.4h0.4l0.9,3.1l-2.1,1.5
	l-0.7,4.5l1.6,1.1l2.5-1.7l2.8,3.2l3.6-0.4l1.5,2l4.2,1.2l0.1,4.8h-2.4l-2.5-2.4l-4.5-1.9l6.6,5l4.2,2l-2.4,2l-0.2,2l1.2,0.1
	l2.2,3.5l-1.6,1l-4-3.7l-5.1,0.1l4.5,1.5l4.1,3.5l2.6-1.5h4l3.1,5.7h-0.2H645.3z M634.5,228.2h-0.4l0,0l0,0l-4.5-3.8l3.1,3.6
	l1.4,0.2l0,0l0,0l0.4,0.2V228.2z M644.5,238.5l0.2-0.5l0.2,0.5h-0.2H644.5z M648.2,211.6l-0.6,2.5l0.4-2.4L648.2,211.6z
	 M643.1,213.7l0.2-0.5l3.1-1.1l-0.9,3.5l-3.5,9.2l1.5,2.5l-1.2,2.2l-1.7-3.7v-4.6L643.1,213.7z"/>
<path id="Iowa" class="st0" d="M439.2,164.4l3.2,4.5l3.1,2l-0.1,5.2l-1.6,3.7l-4.3,3l-3.7,0.5l-0.9,4.2l1.7,2l-0.1,2.2l-2.6,5.2
	l-2.5,1l-0.6,3.7l0,0l0,0l-4-4l-17.6,1.4l-18,0.5l-16.6-0.2h-0.1l-0.7-3.3l-0.5-8.8l-0.7-2.8l-2-1.4l0.2-4.2l-1.2-3.3l-2.5-3.7
	l-0.6-5.1l-1-0.4l-2-4l2.4-6.3l-1.4-1.9l-0.4-4.7l1.7-0.1l44.5-0.6l20.2-0.9l2.2,3.7l-0.9,2.2l1.9,7.1l4.3,1.4l1,2L439.2,164.4
	L439.2,164.4z"/>
<path id="Maryland" class="st0" d="M647.9,211.7l0.5-1.5l-0.2,1.4L647.9,211.7z M649,203.8v2V203.8L649,203.8L649,203.8z
	 M646.4,212.1l-3.1,1.1l-0.2,0.5l-2.7,1.2l0.2-4l-4-1.7l0.1,1.2l-4.3-3.1l3.7-2.5l-1.7-1.9l-2.8-0.6l2.1-2.5l-2.1-0.7l-1-3.3
	l2.2-4.8l0.5-3.7l-2.6,4.7l-1.6-0.4l-0.2,2.8l-2.5,0.2l2.6,2.5l-0.9,3.7l1.6,6.1l2,3.2l-3.3-2l5.3,4.7l0.4,1.6l-3.7-2l-3.8-0.2
	l-3.3-3.2l-1.9,2.5l-1.2-2l2.4-4.2l-0.2-1.6l1.2-1.9l-2.8-0.1l-5.9-2.6l0.4-1.6l-3.8-0.7l-2.4-4.5l-4-0.7l-3.6,2.1l-0.2,1.6
	l-2.8,0.4l-1.5-1.2l-2.2,3.6l-1.4-0.2l-4.7,5.6l-1.5-8.7l22.3-4.3l25.1-5.5l5.9,20.9l7.9-1.7l-0.1,3.7l-1.1,0.6L646.4,212.1z"/>
<path id="Delaware" class="st0" d="M648.9,203.8h-0.4v0.1l-7.9,1.7l-5.9-20.9l2-2.5h2.4l-1.6,3.7l0.7,2.5l2.6,2l1.5,4.2l4,3.7l1-0.2
	L648.9,203.8L648.9,203.8L648.9,203.8z"/>
<path id="Pennsylvania" class="st0" d="M638.1,161.7l1.4,1.2l-1.1,2.7l0.7,3.1h1.2l6.1,5.5l-3.1,3.3l-1.2,2.2l-3.1,2.5h-2.4l-2,2.5
	l-25.1,5.5l-22.3,4.3l-13.4,2.4l-2.7-15.6l0,0l-3.8-22.7l1.7-1l6.9-5.5l0.7,4.6l55-11.4l3.8,2.2l1,3.5l5.6,3.2l0,0L638.1,161.7z"/>
<path id="NewJersey" class="st0" d="M638.1,161.7l4.1-7.6l5.6,1.9l5.7,1.9l-0.4,5.1l-2,1.9l-0.4,2.5l4,0.6l0.6,1.2l0.7,10.4
	l-2.1,3.6l-0.2,3.7l-2.5,3.3l-1.9,5l-1.2,0.5l0.2-3.8l-3.5,0.1l-6.1-3.7l-0.9-2.2l0.9-3.1l3.5-2.5l1-2.8l3.1-3.3l-6.1-5.5H639
	l-0.7-3.1l1.1-2.7L638.1,161.7z"/>
<path id="NewYork" class="st0" d="M642.1,154.1l-5.6-3.2l-1-3.5l-3.8-2.2l-55,11.4L576,152l6.6-6.7l2.6-5.2l-3.2-4l-0.5-3l4.2-2.2
	l6.9-2l4.6-0.1l2.8,1l2-1.1l4.7-0.7l3.1-1.6l2.8-4l2.6-1.6l-1.4-4.5l0.9-2.8l-2.1,1l-1.5-2.5l1.9-3.2l3.2-2.2l1.1-3.8l4-5.9l4.2-3.7
	l2-0.2l15.9-4l1.6,7.3l1.2,1.5l0.6,4.1l-0.5,3.3l2.1,5v2.7l2.4,0.1l3.2,13.5l-0.2,11.9l0.5,0.4l2.4,12.9l1,1.1l-2.5,2.7l1.5,1.5
	l-1,3.1l3.2-2.8l3.5-0.1l1.4-1.4l6.1-2l2.6-3.5l2.6,1.6l0.2-1.6l0.5,1.2l3.1-1.9l-5.9,5.5l-3.8,2l-3.3,3.2l-6.3,3.5l-5.2,1.4l-3,2
	l0.1-2.2l1.7-0.2l1.2-3.1l-1,0.7l0.2-4.3l-5.7-1.9l-4.2-1.4L642.1,154.1L642.1,154.1z"/>
<path id="Idaho" class="st0" d="M134.6,25.9l10.9,2.5l-3.7,16.8l2.6,6.1l-1,4.6l3.7,3.7l3.6,7.1l-0.1,1.4l3.2,4.2l2.6-0.1l-0.4,2.5
	l-2.2,4.2l-1,4.6l0.5,2l-2.2,1.5l-0.6,3.7l2.1,2l5.1-2.7l1.6,2.2V97l2.4,5.8l-0.7,1l1.4,2.7l1.7,0.1l1.1,2.8l0.1,3.5l1.5,1.7
	l2.4-1.9l3.7,1.4l1.9-1.4l5.1,1.6l3.7-0.1l1-2.4l1.7,0.1l2.5,4.6l-4.8,29.2l-2.2,13.3l-16.4-2.8l-20.9-4l-11.5-2.5l-25.4-5.7l6.9-30
	l3-6.3l-3.1-2.8l0.6-3.1l6.2-6.3l3.8-6.7l3.1-3.5l-0.1-3.1l-2.4-2.1l-0.7-3.1l0.4-3.1l-0.7-3.7L134.6,25.9z"/>
<path id="SouthDakota" class="st0" d="M271.1,153.5l0.6-6.4l2.5-28h0.2l1.4-16.2l75.4,4.1l14,0.1l-1,3.1l-2.7,2.8l2.1,3.5l2.8,2
	l-0.2,31l-1.7,0.1l0.4,4.7l1.4,1.9l-2.4,6.3l2,4l-2.8-2.8l-5.2-2l-2.1-1.7l-6.7,0.2l-3,1l-5.1-3.5L271.1,153.5z"/>
<path id="Connecticut" class="st0" d="M677.8,145.7l-0.7-0.5l-7.2,3.6l-4.7,1.5l-3.3,2.1l-5,4.5l-1.5-1.5l2.5-2.7l-1-1.1l-2.4-12.9
	l8.9-2l11.8-2.8l2.4,8.2L677.8,145.7z"/>
<path id="NewHampshire" class="st0" d="M683.1,112.4l0.5,0.1l-0.4,3.5l-3.8,2.2l-1.4,2.4l-14.2,3.5l0,0l-1.7-2.2v-7.6l-1-4l1.1-4.8
	l0.4-6.4l-0.5-2.5l3.6-4l0.7-2.8l-1.6-2.1l0.2-5l0.7-5.2l2.8-1l9.5,28.6l0.6,3L683.1,112.4z"/>
<path id="Kentucky" class="st0" d="M460,266.9l-0.2-1l1.1,0.9L460,266.9z M461.9,266.6l0.7-2.5l1.6,1.1l1.4-3l-0.1-4.3l-0.9-1.4
	l1.6-2.6l1.9-0.4l5.9,2l-0.2-5.8l4.5-1.5l-0.7-2.8l1.4-3l2-2.4l4,0.9l2.2-1.7l3.7,1.4l4.3-3l1.2,1.5l2.7-0.7v-1.6l3-2.5l3.5,2.6
	l1.6-1.9l0.9-4l2-0.6l0.4-2.2l2.4-2.2l-0.7-3.1l3.6,0.1l4.7-2.2l-1-2.1l0.4-3.1l5-0.7l1.7,1l2.1,3.5l4.5,0.2l2.4,1.9l2.1-1.6
	l3.2,1.4l5.2-3.2l1.2,2.6l3.6,2.4l0,0l0,0l0.1,4.6l5.6,7.2l4.8,2l-4.5,5.2l-3.6,2.5l-2.7,5.1l-3.1,3l-6.9,3.5l-0.7,0.5l-19,1.9
	l-4.1,0.5l-15.4,1.1l-17.5,2l-3.2-0.5l0.6,3.2L461.9,266.6z M550.2,223.5L550.2,223.5L550.2,223.5L550.2,223.5L550.2,223.5
	L550.2,223.5z"/>
<path id="Ohio" class="st0" d="M514,171.6l6.3-1l10.5-2l7.8,2.4l-0.9,1l5.9,0.7l5.6-2.8l4.7-0.9l3.6-4.2l9.7-6.1l3.8,22.7l-1.9,1.4
	l1.7,5l-1.9,9.3l0.4,2.5l-4,4.7l-3.2,0.5l-2.4,2.6l-1.4,3.6l1.1,2.2l-1.6,1.4l-2.5-1.9l-1.4,4l0.9,2.8l-1.6,3.1l-3.2,1h-0.1
	l-3.6-2.4l-1.2-2.6l-5.2,3.2l-3.2-1.4l-2.1,1.6l-2.4-1.9l-4.5-0.2l-2.1-3.5l-1.7-1l-5,0.7l-4.2-37.7L514,171.6z"/>
<path id="Tennessee" class="st0" d="M481.1,291.2l-6.1,0.5l-23.5,1.6l2.6-2.4l-0.2-4.5l2.5-3l-0.5-3.2l2.8-1.9l-0.2-2.8l1.4-2.5
	l-1.2-1.6l2.1-2.5l-0.5-2l0.9-0.1l0.2,0.7l0.6-0.9l19-1.5l-0.6-3.2l3.2,0.5l17.5-2l15.4-1.1l4.1-0.5l19-1.9l0.7-0.5l23.3-3.3l4-0.9
	l-0.6,4l-2.1,2l-1.7,3.7l-1.7-0.7l-4.7,4l-0.9-1.4l-3.6,2.5l-1.9,3l-5.3,4.6l-3.7,0.7l-3,2.7l-0.7,3l-2.4,0.4l-0.2,4.7l-7.6,1
	l-10.3,1.2l-35.9,3.3L481.1,291.2z"/>
<path id="WestVirginia" class="st0" d="M550.2,223.5L550.2,223.5l3.3-1l1.6-3.1l-0.9-2.8l1.4-4l2.5,1.9l1.6-1.4l-1.1-2.2l1.4-3.6
	l2.4-2.6l3.2-0.5l4-4.7l-0.4-2.5l1.9-9.3l-1.7-5l1.9-1.4l2.7,15.6l13.4-2.4l1.5,8.7l4.7-5.6l1.4,0.2l1.9-3.6l1.5,1.2l2.8-0.4
	l0.2-1.6l3.6-2.1l4,0.7l2.4,4.5l-0.6,3.5l-7.3-4l-0.2,5l-1.6,3.5l-3.2,4.2l-1.6-0.7l-2.7,7.8l-5-2l-1.1,5.7l-4.8,11.1l0.4,3.1
	l-5.5,3.1l-1.7-0.5l-1,2.5l-3.6,1.4l-1.9-1.1l-2.5,2.5l-4-0.7l-2.6-4l-4.8-2l-5.6-7.2L550.2,223.5L550.2,223.5L550.2,223.5
	L550.2,223.5L550.2,223.5z"/>
<path id="Louisiana" class="st0" d="M468.2,375.6l-6.7-0.7l-3.8-2l-2.8,4.3l1.5,1.4l4.6,0.1l2.1-2.2h3.6l-2.4,3.6l2.8,1l2.2-3.5
	l2.1,2.5l-5.6,5.2l3,4l5.2,0.5l2.7,2.2l-1.5,3.1l-3.6-0.5l-1.9-2.4l-5-1l0.9-1.4l-5.1-1.7l0.7,5.1l-2.5,2.5l-1-2.5l-3.2-1l-2,3.1
	l-3.7,0.2L442,393l0.7-3.5l-3.9-0.4l-2.5-3.7l-2.6,0.7l0.1-2l-4.2,1.9l0.5,2l2.7-0.6l3.1,1l-1.7,1.9l-4.5-1.6l-2.1,1l-6.2-1
	l-7.8-2.7l-3.8,0.2l-5.6,1.4l0.4-5l1.2-1.6l-0.4-8.4l2.2-4.5l0.7-4l-5-9.7l0.1-2.2l-3.5-4.8l-0.4-17.5l38.5-1.5l2.7-0.1l1.5,1.5
	l-1,2.7l1.2,5.5l2.7,1.7l-2.2,5.8l-3.5,2.8l-1.2,5.1l-1.4,1.4v3.7l-1.5,0.2l1.2,3.7l-1.1,1l28.2-1.6l-1.2,5.6L468.2,375.6z"/>
<path id="Florida" class="st0" d="M498.9,367.8V365l-3.7-3.7l0.4-2.2l37.9-4.2l2.6,4.6l38.9-2.7l1.4,3.3l1.9-0.5l-0.7-6.3l1.7-1.6
	l6.2,0.9h0.9l1.7,6.8l3.8,9.3l6.6,10.8l7.7,9.2l-0.7,1.4l2,6.4l3.6,5.1l6.4,11.8l1.6,7.1l0.6,11.6l-1.9,7.7l0.7,5.8l-2.8,4.2
	l1.4-4.2l-1.2-1.1l-2.8,2.2l-2.4-0.4l-3.5,1.7l-1.7-1.6v-2.6l-3-5.1l-5.9-3.3l-1.7,0.6l-3-8.2l-4,0.5l-0.5-7.8l-2.8-1.4l1.5,2.6
	l-2.6-0.4l-6.8-9.3l3.1-6.9l-0.6-2l-2.7,1l-0.1,3.1l-3.1-1.4l-0.2-6.2l1.1-5.2l-0.6-7.4l-3.2-4.8l-3.3,0.5l-3.5-3.7l-3.1-1.6l-5-6.1
	l-5.5-2.5l-5,1.9l0.7,2.2h-2.4l-0.9,2.2l-5.3,3.8l-5.8-0.5l0.5-2.1l-1.9-2.2l-4.3-2.8l-6.2-2.5l-6.8-1l-14.9,3.7l2.5-2.8
	L498.9,367.8z"/>
<path id="Georgia" class="st0" d="M579.3,351.9l-1.7,1.6l0.7,6.3l-1.9,0.5L575,357l-38.9,2.7l-2.6-4.6l-2.1-4.5l0.5-4.6l-2.1-4.8
	l0.7-5.6l1.9-2.6l-3.3-5.5l-2.3-5.4l-9.8-34.3l10.3-1.2l7.6-1l11.5-1.6l5.2-0.9l-2.7,5.5l4.8,3l2.7,0.1l2.5,4.5l2.8,3.5l5.2,3l1,1.9
	l4.1,2.2l0.4,2l3,3.1l3.7,1.4l2.7,6.9l3.2,2l2.8,6.4l2.8,0.5l0.9,0.5l-5.9,9l1.6,4.5l-2.5,2l1.2,4.5l-0.6,3.8L579.3,351.9z"/>
<path id="SouthCarolina" class="st0" d="M617.8,291.2l-4.3,5.2l-2,4.3l0.1,4.1l-2.1,3.2l-2.1,0.1l-0.6,2.4l-5,6.1l-4.3,3.1l-5,0.5
	l3.8,1.1l-5.5,7.6l-2.8-0.5l-2.8-6.4l-3.2-2l-2.7-6.9l-3.7-1.4l-3-3.1l-0.4-2l-4.1-2.2l-1-1.9l-5.2-3l-2.8-3.5l-2.5-4.5l-2.7-0.1
	l-4.8-3l2.7-5.5l10.9-5.2l16.8-1.9l4.5,2.7l0.6,2.2l15.6-2.2L617.8,291.2z"/>
<path id="Minnesota" class="st0" d="M357.8,53.8l23.3,0.1l0.1-6.7l3.8,1.4l1.5,8.4l1.4,1.4l4.1,1.2l4.1,0.1l1.5,2l3-0.5l2.6-1.6
	l3.2,0.1l3.8,1.2l3.2,5.2l0.9-1.4l3-0.5l4.2,4l3.1,1l5.7-3.7l1.6,2.1L439,67l2.5,1.6l4-0.1l-5,3.7l-5.1,2l-5.3,4.1l-5.5,6.8
	l-6.8,6.4l-2,2l0.4,9.9l-4.8,3.1l-2,3.5l0.1,2.5l2.7,2.2l-0.9,2.5l0.1,6.4l-0.6,3l3,2.5l2.4,0.5l3.7,2.4l5.7,6.1l3,0.7l1.7,1.7
	l0.9,7.2l-20.2,0.9l-44.5,0.6l0.2-31l-2.8-2l-2.1-3.5l2.7-2.8l1-3.1l-0.5-6.7l-2.2-5.5l0.5-4.8l-0.7-1.5l-0.2-8.4L359.3,68l-0.2-6.3
	l0.6-2L357.8,53.8z"/>
<path id="Montana" class="st0" d="M145.5,28.5l39,8.2l95.8,13.1L276,99.5l-0.2,3.3l-1.4,16.2h-0.2l-84.1-10.9l-1.5,8.5l-2.5-4.6
	l-1.7-0.1l-1,2.4l-3.7,0.1l-5.1-1.6l-1.9,1.4l-3.7-1.4l-2.4,1.9l-1.5-1.7l-0.1-3.5l-1.1-2.8l-1.7-0.1l-1.4-2.7l0.7-1l-2.4-5.8v-4.8
	l-1.6-2.2l-5.1,2.7l-2.1-2l0.6-3.7l2.2-1.5l-0.5-2l1-4.6l2.2-4.2l0.4-2.5l-2.6,0.1l-3.2-4.2l0.1-1.4l-3.5-7.2l-3.7-3.7l1-4.6
	l-2.6-6.1L145.5,28.5z"/>
<path id="NorthDakota" class="st0" d="M275.7,102.9l0.2-3.3l4.3-49.7l20.8,1.6l56.7,2.4l1.4,6.1l-0.6,2l0.2,6.3l3.1,11.9l0.2,8.4
	l0.7,1.5l-0.5,4.8l2.2,5.5l0.5,6.7l-14-0.1L275.7,102.9z"/>
<path id="Arizona" class="st0" d="M180.6,345.4l-28.5-4.6l-6.8-3.7l-44.1-26.5l2.1-3.3l2.7,0.1l1.7-4l-2.8-2.5l0.9-5.5l1.4-0.1
	l3-4.6l0.2-3.7l2.6-2.8l4.1-1.9l-2.6-3.8l-1.7-9.8l1.4-3.1l-0.2-5.8l1.4-6.1v-3.8l2.5-0.7l5.5,3.1l1.6-2.7l2.7-13.1l43.9,8.2
	l23.2,3.7l-6.9,48.3L180.6,345.4z"/>
<path id="Utah" class="st0" d="M194.5,248.4l-23.2-3.7l-43.9-8.2l7.3-36.9l9.4-47.4l20.9,4l16.4,2.8l-2.8,17.1l25.5,4l-1.6,10.7
	L194.5,248.4z"/>
<path id="Hawaii" class="st0" d="M263.2,466.7l0.2,0.2l0.2-0.1h0.5l1.4,0.6l1.1,0.7l2.2,1.9l0.7,0.9l0.4,0.6v1.7v0.7h0.4l0.5-0.4
	l0.4,0.4l0.1,0.4v0.7l0.1,0.5l0.2,0.5l0.6,0.9l0.7,0.4l0.2,0.4l0.1,0.4v0.5l-0.7,1.2l-0.6,0.2l-0.9,1.1l-0.6,0.2v0.2l-0.5,0.1
	l-0.4,0.4l-1.5,0.6l-0.5-0.1h-0.4l-0.2,0.1l-0.7,0.6l-0.2,0.4l-0.5,0.1l-0.5,0.5l-0.6,0.5l-0.2,0.1l-0.6,0.7l-0.1,0.2v0.6l-0.6,0.9
	l-0.2,0.7l-0.2,0.2l-0.5,0.6l-0.1-0.1v-0.4l-0.2-0.2l-0.5-0.4l-1-0.7l-0.7-0.2l-0.1-0.6l-0.1-0.1l-0.1-0.5l-0.1-0.7l0.1-1l0.2-2.7
	v-0.5l-0.1-0.4l-0.5-1.9l-0.2-0.6v-0.4l-0.1-0.4l-0.2-1l-0.2-0.5l-0.2-0.2l-0.2-0.5l-0.4-1.1l0.2-1l0.6-0.7l0.1-0.4l0.1-0.2l0.5-0.2
	l0.4-0.9l0.2-0.7l0.2-0.5h0.2l0.1-0.9l-0.1-0.4l-0.2-0.2l-0.5-0.9l-0.2-0.7v-1.2l0.2-0.6l0.1-0.2h0.5l1.2,0.4l0.4,0.9l0.5,0.2
	l0.4,0.2l0.2,0.4L263.2,466.7z M250.9,459.6l-0.1,0.4h-0.4l-0.4-0.1l-0.6,0.1H249l-0.2-0.4l0.1-0.2l0.2-0.4l0.7-0.6l0.4-0.1h0.2
	l0.4,0.5l-0.1,0.5L250.9,459.6z M245.9,455.9h-0.6l-0.2-0.5L245,455v-0.7v-0.4l-0.2-0.1l-0.5-0.2l-0.1-0.4l0.1-0.4l0.2-0.2l0.5-0.1
	l1.2,0.2l0.6,0.7l0.4,0.6l0.1,0.5l-0.1,0.2l-0.2,0.6l-0.6,0.4L245.9,455.9z M250.1,450.5l0.1,0.1l0.5,0.2v0.2h0.1v0.5l0.1,0.2
	l0.4,0.9h0.2l0.1-0.2h0.2l0.7-0.1l0.4-0.4l0.2-0.1h0.4l0.7,0.2l0.2,0.1l0.1,0.2l0.4,0.5l0.4,0.4v0.1l0.2-0.1l0.1,0.1l0.1,0.4
	l0.5,0.1l0.5,0.2l0.6,0.2l0.2,0.7l-0.1,0.7l-0.2,0.6l-0.4,0.1l-0.2,0.5h-0.4l-0.7,0.5h-0.7h-0.2l-1.2,0.9l-0.6-0.2h-0.1h-0.5
	l-0.4-0.5l-0.2-0.5l0.1-0.1v-0.4l-0.1-1.2l-0.1-0.4v-0.4l-0.1-0.2l-0.2-0.2l-0.4,0.1l-0.1,0.2h-0.1l-0.4-0.4l-0.2-0.1l-0.2-0.2h-0.1
	l-0.5-0.7l-0.4-0.6V452l-0.1-0.2l0.1-0.9l0.1-0.5l0.1-0.2h0.1l0.2-0.4h0.2L250.1,450.5L250.1,450.5z M241.6,446.9h0.5h0.1l0.1,0.2
	h0.2l1.9,0.4h0.5l0.1-0.5h0.1l0.1,0.2l0.1,0.4l0.6,0.2h0.6h0.2l0.4-0.1h0.4l0.4,0.1h0.1l0.1,0.2l0.2,0.1l-0.2,0.6l-0.4,0.5l-0.5,0.4
	l-0.5,0.2l-0.5,0.1l-0.5-0.1l-0.5-0.1l-1-0.4l-0.6-0.2l-1.5,0.1l-0.5,0.1H241l-0.2-0.1l-0.1-0.2v-0.4l0.2-0.5l0.2-0.1l0.2-0.4
	l0.1-0.4l-0.1-0.4L241.6,446.9z M233.2,440.1l0.1,0.4l0.1,0.1l0.1,0.4h0.2l0.2,0.4v0.4h-0.4l0.1,0.9l0.2,0.1l0.2,0.5l0.2,0.1
	l0.1,0.2l0.2-0.4l-0.2-0.1v-0.2l0.1-0.1l0.5,0.1l-0.2,0.2v0.6l0.2,0.1l0.2,0.4v0.2l0.1,0.5l0.5,0.5l0.1,0.1l-0.6,0.7h-0.1V446
	l-0.1-0.1l-0.6,0.1l-0.4,0.4h-0.2l-0.1-0.4l-0.6-0.4l-0.2-0.5V445l-0.2,0.2l0.1,0.2h-0.5l0.1-0.1l-0.2-0.1v-0.6l0.4-0.2v-0.1
	l-0.4-0.4l-0.1,0.2l-0.2-0.4l0.1,0.5h-0.1l-0.4-0.5l0.1,0.2l0.5,0.6l-0.1,0.2l-0.2,0.1l-1.2,0.4l-0.2-0.2l-0.1-0.6l-0.1-0.5
	l-0.2-0.5l-0.2-0.1v-0.5l-0.1-0.2l-0.4-0.4l-0.1-0.4v-0.7l-0.1-0.1l-0.5-0.6l0.5-0.1h0.5h1l0.1-0.4l0.2-0.1l0.4-0.4v-0.1l0.2-0.5
	l0.5-0.5l0.4-0.1l0.2,0.1l0.4,0.5l0.2,0.6v0.4V440.1z M203.1,436.3l-0.1,0.1l-0.4-0.2l-0.1-0.6v-0.4l0.1-0.4l0.5-0.7l0.4-0.4
	l0.5-0.4l0.1-0.5v-0.4l0.2-0.1h0.2l0.2,0.1l0.1,0.4l-0.2,0.4l-0.1,0.4l0.1,0.5l-0.1,0.4l-0.4,0.2l-0.2,0.1l-0.5,0.5l-0.1,0.2
	L203.1,436.3z M213.7,428.3l0.2,0.1h0.2l0.2,0.1l0.1,0.2l0.2,0.2l0.1,0.6l0.1,0.1v0.7l-0.1,0.2l-0.2,0.7l-0.2,0.1v0.5v0.6v0.5
	l-0.4,0.1l0.1,0.2l-0.2,0.1l-0.2,0.2l-0.1,0.2l-0.5,0.5l-0.1,0.2l-0.6-0.2h-0.6l-0.7-0.1l-0.1-0.1l-0.2,0.1V434l-0.4-0.2l-0.2-0.5
	H210l-0.1-0.2h-0.2l-0.4-0.2l-0.5-0.1l-0.1-0.5l-0.2-0.2v-0.9h0.1l0.5-0.9v-0.5l0.2-0.2l0.6-0.2l0.4-0.2l0.2-0.2l0.2-0.1l0.1-0.1
	l0.5-0.1l0.1,0.2l0.5,0.1v-0.2l0.2-0.1h0.5l0.2,0.1l0.2-0.2L213.7,428.3z"/>
<path id="Alaska" class="st0" d="M94.9,379.1l-0.1-0.4L96,378l1.4,0.6l2.1,0.1l2.1-1l1.2-1.4l2.4,1.4l-0.1,0.7l-1.1,0.5l0.1,1h0.7
	l1.2-1.6l1.1,1l-0.2,1l1,0.6l0.6-0.7l1.4,0.1l2.2,1.1l-1.2,1.5l2,0.4l-0.6,0.6l1.7,0.4l2.8-0.1l1.9,0.5l1.4,1.4l0.5-0.2l0.7,0.9
	l1.5,0.5l2.7,0.1l1.4,1.4l1.6,0.1l1.2-0.6l1.7-0.2l1.6,0.7l0.7,1l1,0.1l0.9,1.1l0.9-0.1l2.1,62.6l3.2,0.7l1.2-1l1.7-0.1l-0.2,1.9
	l1.7,1.1l0.4,0.9l3.6,3.1l0.9,2.2l1.9-1.9l0.7-0.1l0.6-3.1l2.7-1.6l1.7,1.6l-0.1,0.9l2.6,2.1l0.7,1.4l1.4,0.5l2,2l5.2,7.6l0.9,1
	l-0.1,1.1l0.9,0.4l0.5,1.4l0.7-0.1l5.9,2.2l0.6,1.4l-0.4,1.1l1.2,2.8l-0.9,2.8l-1,1.1l-0.9-0.1l-1-2.5l0.5-1l-0.5-2.5l-1.5-1.5
	l-1.9,0.9l-0.4,2.8l-1.4-1.6l0.7-0.5l0.2-2.8l-2.6-2.2l-0.4-1l-2.8-2.5l-1.2,0.1l0.5-1.6l-1.1-1l-0.5-1.4l-1.6-1.6l-0.1-2.2
	l-0.6,1.4l-0.5-0.1l0.4,0.4l-1.4-0.2l-0.2-0.5l0.9,0.1l-1.4-0.6l-2.7-5.6l0.2,2.2l1.6,3.7l-0.2,1l-1.2-1l-1.6,0.2l0.1-1l-1.1-2.2
	l-0.2,0.9l-3-1.9l0.2,1.2l1.5,0.1l2,2.1l0.1,1.1l-1.7,0.1l-0.2,0.9l-3.7-2.4l-2.4-2.8l-3.2-1.4l-2.4-1.4l1.2-1.4l-0.6-1l-2.4,1.4
	l-3.7-1l0.5-1l-1.6,0.4l-3.6-0.9l-3.7,0.7l-1-1l-2.2-1l0.6-2.5l-0.9,1l-0.4,1.4l-1.5-1l-1.2-0.1l1.1-1.5l-2.1,0.1l-1.4-0.7l0.6-0.5
	l-0.7-1l-1.7,0.4l-1.4-0.5l-1.5,0.5l0.1-1.7l-1.1,3.2l0.7-1l0.7,2l-0.9,1.1l-0.9,2.4l-2.5-0.5l-1.6,0.6l-0.5,1.5l-0.5-0.5l-1.9,1.6
	l0.7-1.6l-1.9,2.5l-1,0.5l-0.9-1.2l-1.5,0.5l-0.5-1l1.9-0.9l1.9-1.9l-1.7,0.7l-1.2-1.2l1.7-2.5l0.9-2.2l-0.4-1.2l1.2-0.4l2.1-1.4
	l1.2,0.9l0.7-0.2l2.2,0.7l-2.8-2.2l0.4-0.5l-1.5-0.1l-0.2-0.9l-2.2,1.9l-1.4,0.2l-3,3.1l0.2,0.6l-1.4,1l0.1,0.7l-2,2.5l-2.2,0.1
	l-0.2,1.1l-1.5,0.4l-0.5,2.2h1.6l1.2,1.9l-3.1,1.9l0.2,0.7l-1.4,0.7l-1,1.4l-1.6-0.2l-1.6,1.7l-0.6-0.6l-0.7,1.6l-0.9-0.5l-2.2,1.5
	l0.7,0.1l-1.1,2l-0.9-0.5l-1.7,1.5l-0.7-0.7l-0.5,1.5l-1-0.6l-3.2,1.6l1,0.7l-2.1,1.6l-3.3,0.6l-1.1-0.9l-3.7,1.9l-0.7-0.6l-1.6,0.7
	l-0.7-0.5l0.7-1.1l-0.7-0.5l-2.1,2.7l-1.5,0.6l-0.5-2.4l-1.5,2.2l-0.6-1.4l-1.6,1.5l0.5-1.9l3.6-1.5l3.5-2l3.7,0.1l0.1,0.7l1.9,0.9
	l-1-1.4l1.2-1.6l2.7-1.5l3.2-1l1,0.7l0.2-1.7l1.7-1.6l2.6-1.2l1.9-5.8l1.9-1.4l0.1-1.1l-4,1l-1-1.6l0.7-1.6l-2,1.6l0.1,1.7l-1.1,0.5
	l-1-3.7l-1.4,0.7l-0.9-0.7l-0.1-1.6l-2,1h-1.6l-0.5,0.7l-2.8-0.7l1.7-0.2l0.2-1.7l-0.4-2l1.6-1.1l-0.9-4.6l0.2-1.9l-1.1,2.5l-4,0.1
	l-1.5-0.7l0.4-1.1l-1.1-2.5l-0.9-0.7l-0.9-1.9l3-0.9l2.1,1l0.6,1.6l1-0.9l-1.5-1.2l-2-0.6l-1.6-1l0.7-0.4l-0.9-1.7l-0.4,1l-0.9-2.8
	l0.7-0.5l-1.1-1l1.5-0.1l-0.6-1.2l1.5,0.5l0.4-2l2.6-2l0.7,0.1l0.6-2l1.5-1.6l1.6-0.4l1,0.6l0.7,1.6l0.7,0.1l1.9-1l1.4-1.2l0.2,0.6
	l3,0.5l1.6-1.4l0.4-3.5l-0.9-1.6l2.1-0.9l-0.5-2.2l-1,0.9l-1.9-0.2l-1.9,1l-1.6,0.1l-0.7-1.4l-2.4-0.9l-2,0.1l-2.7-2.1l-0.5-1.2
	l0.6-1l-0.9-2.2l1,0.5l1.5-0.5l-3-2.1l-1.2-2l0.9-0.7l1.9-0.4l0.5,0.5l1.2-0.9l4.3-1l2.5-0.1l2,0.5l-1.4,2.4l0.4,1l3.8,1.4l0.5,0.9
	l1.4-1.6l1.5,0.6l-1-1.5l-1.2,0.1l0.5-0.9l-1-3.3l0.9-0.1l0.5,1.5l-0.4,0.5l0.7,1.9l1.2-0.2l0.7,1.4l1.4,0.1l0.4-0.7l-1.5-1.4
	l-1.7,0.5l-0.6-1.4l0.7-2.2l-1.6-0.1l-2.8-1.7l0.2-1.6l-0.2-2.1l-2-4l-1-1l-0.9-2l1.2-1l0.7-2l1.2,0.6l3.1,0.7l2.1-0.6l1.7-1.4
	l0.5-1.7l0.7-1.1l1.5-1.4l0.4,0.5l1.6-1.5l0.2,0.7h2l2-1.4L94.9,379.1z M94.9,379.1l0.1,0.1l0,0l0,0l-0.7,1.6l1-1l-0.2-0.6l0,0l0,0
	l0.1-0.1H94.9z M14.5,482.3l-1.1-0.1l1.2-0.6l0.4,0.6L14.5,482.3z M25.4,482.7l1,0.1l-0.4,0.6l-0.4-0.5L25.4,482.7z M31.6,484
	l-0.4-0.5l-2.1,0.5l2.7-2.1l0.6,0.5l0.7-1.4l1.7-0.1l0.1,1.4l-2,0.2L31.6,484z M45.1,480.4l-1.2,0.4l-0.7-0.9l0.7-0.5L45.1,480.4z
	 M56.3,482.2l0.1-0.7l0.7,0.6l0.2,0.6L56.3,482.2z M54,476.7l0.6,0.2l0.2,2.1l-1.2,0.7l-1.8-0.6l-1.4,0.9l-1.7-0.5l-0.1-1.2l1.1-0.2
	l1-1.1l1.2,0.2L54,476.7z M70,481.2l0.1,0.2l-0.7-0.5l0.5-0.5L70,481.2z M171.1,486.9l-0.2,1l-1.7-2.5l0.2-1l1,2L171.1,486.9z
	 M176.6,484.5l-0.1,1.5l-1.4-0.9l0.1-1.9L176.6,484.5z M68.5,479.7l-0.1,0.7l-1.6,0.7l1.2-1L68.5,479.7z M67.3,478.1l-0.9,0.1
	l-0.4,1.5l-1-0.1l0.2-1.5H67.3z M174.1,482.6l0.7,0.6l-0.2,1.1l-0.6-1.4L174.1,482.6z M167.4,483.2l0.1,0.1l0.6-0.2l-0.6,1.9
	L167.4,483.2z M168.1,480.8l-0.6,0.7l-0.6-0.2l0.1-0.5H168.1z M175.7,478.7l1.2,1.5l0.4,2.1l-0.6,1.5l-2.6-1.5l0.5-0.7l-0.4-2
	L175.7,478.7z M166.2,480.4l0.4-1.1l1.4-0.7l0.1,0.9L166.2,480.4z M170.9,477.8l1.5,0.4l-0.6,1.4l-1.7-1l0.5-1.6L170.9,477.8z
	 M170,476.4l0.1,1l-1.6-0.1l0.4-0.9H170L170,476.4z M171.9,476.6l1,0.9l-0.4,0.6l-1.2-0.9l-0.2-1.4L171.9,476.6z M90.5,474.7
	l-0.1,0.5l-1,0.7l0.2-0.9L90.5,474.7z M91.9,475.4l0.2,0.4l-1.4-0.2l0.2-0.5L91.9,475.4z M168.8,474l1.5,1.1l-0.7,0.7H169L168.8,474
	z M95.7,471.7l0.7,0.1l-1.6,0.9l-0.1-0.5L95.7,471.7z M158.7,472.4v1.6l-0.7,0.1l0.4-1.2L158.7,472.4z M155.5,467.4l-0.4,1.1l-0.5-1
	l0.2-0.6L155.5,467.4z M96.4,465.6l1,0.7h-0.5l-1.4-0.7H96.4z M98.1,464.5l0.6-1.1l0.9,1l0.9-0.1l0.4,1.1l-0.6,0.5l-2.6,0.7l-1.2-1
	l0.4-1h1.4v-0.1H98.1z M99.4,463.4l-0.6-0.5l1.1-0.5l-0.1,0.6L99.4,463.4z M71.1,456.3l-1,0.5l0.4-1l1.5-0.5L71.1,456.3z M116,454.4
	l-0.1,0.5l-0.9,0.2l0.7-1.4L116,454.4z M118.6,452.7l0.6,0.4l-2,2.4l-1.5,0.9l0.7-1.4l1.7-1.6L118.6,452.7z M57.5,439.4l1.1,1
	l-0.6,1.5l0.2,1h-2.1l-1.5-0.7l-1.4-1.9l-0.4-1.4l1.9,0.6l0.5-0.5L57.5,439.4z M120.6,452l1.4,0.4l-1.6,1.2l0.4-0.7L120.6,452z
	 M116.7,451.5l0.2,0.5l-0.5,1.2l-0.4-0.7L116.7,451.5z M37.7,430.8l-0.2,0.5l-0.9-1.6l0.2-0.6L37.7,430.8z M122.1,452l-0.6,0.1v-0.5
	l1.7-0.4L122.1,452z M42.5,480.9l-2,1.1l2.1-0.2l-1,0.7l-5.3,0.7l-1.5-0.5l3-0.1l1.6-3L41,480l-0.4,1l1.4-0.7L42.5,480.9z
	 M169,484.4l0.6-2.5l-1.5,0.1l0.4-1.9l-0.5-1.7h-1.1l-0.4-1l1.6-0.1l0.6,1.9l1.2,0.1l3.1,4.3l1,3.1l-0.6,0.7l-1.7-2.5l-1.4,0.7
	l0.1-1.6L169,484.4z M158.5,471.7l0.6-1.4l1.9,1.4l1.6,3.8l0.2,3.7l-2.4-3.1l0.1-1l-0.9,0.1l0.6-1.5l-0.7-0.2v-1.4L158.5,471.7z
	 M97.5,466.7l-0.5,1.1l1.7-1l0.4,1.2l-0.4,1.1l1,0.1l-0.9,1.1l-2.1-0.9l1.1,1l-0.2,0.6l-1.7-0.2l-2.2,1.6l-1.6,2l-0.2-0.7l1.5-2.2
	h-1.1l-0.6,1.4l-1-0.7l0.2-1.4l-0.7-0.7l0.4-1l2-1.4l0.7,0.5l0.2,1.6l0.5-1.6l-0.2-1l1-0.5l0.2,1.4l0.5-1.9l1.2,1.1l-0.5-1.2
	L97.5,466.7z M157.5,469.1l1.1,2l-1,0.5l-0.5-1.5l-1.7-1.2l0.2-1l1.6-2.1l3.2,1.4l0.1,1.4l-1.6,0.2l0.6,0.6l1-0.5l0.5,1.9l-0.9,0.1
	L157.5,469.1z M162.7,471.4l-0.9-1.9l-1-3.3l-0.7-1.9l1.1,1.4h1.7l1.4,2.1l-0.2,0.7l-1.2-2.2l0.2,1.4l1.2,1.4l0.2,2l-2.1,2.4
	l-0.2-1.5L162.7,471.4z M51.1,412.8l0.5,0.5l1.1-0.4l1,0.5v1.6l1.1,1.4l2.2,1.4l-0.6,0.7l-1.4-0.7l-1.9,0.9l0.1-1.2l-1.6-2.7l-1-0.7
	l-1.2,0.2l-0.6-0.7l0.1-1L50,411v1.4L51.1,412.8z M165.8,474.7l-0.5,2.8l0.4,0.9l-1,1.2l-0.5-2l0.7-1l-1-0.5l-0.6-1.9l1-0.2l1,1
	L165.8,474.7l-0.1-0.4l-1.2-1.6l0.9-0.1l3,0.5l0.2,2.2l-1.5-1.4l1.2,1.9l-0.1,0.5l-1.9,0.2L165.8,474.7z"/>
<g id="WashDC">
	<path id="DC" class="st0" d="M622,203.7l-0.1-0.9l-0.9-0.7h-0.4l-0.4-0.4l2.8,0.1L622,203.7z"/>
	<path id="DC_00000145772002542606720790000011264157249013777309_" class="st0" d="M679,225.3l-0.7-6l-6-4.6h-2.6l-2.6-2.6
		l18.5,0.7L679,225.3z"/>
	<line class="st1" x1="623" y1="201.8" x2="676.4" y2="205.6"/>
	<line class="st1" x1="621.4" y1="202.3" x2="671.6" y2="226.9"/>
	<circle class="st2" cx="676.4" cy="216.8" r="11.2"/>
</g>
</svg>
                    </div>
                    <div className="stateControls">
            
                    </div>
                </div>
                <div className="affilAd adLand">
                <PageIntro pageTitle="States" output="advert"/>
                </div>
                <div className="shareButtonsBottom">
            <div className="shareText">
              <p className="shareP">SHARE:</p>
            </div>
            <div className="shareIcon" id="shareFacebook">
              <FacebookShareButton 
                url={linkURL}
                quote={"The Ordinary Tourist - We help you explore the world"}
                hashtag="#theordinarytourist"
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
              </FacebookShareButton>
            </div>
            <div className="shareIcon" id="shareTwitter">
              <TwitterShareButton
                url={linkURL}
                title={"How many states have you been to?"}
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
<path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
	S18.6,0,12,0z M5.7,6.1l4.9,6.5L5.7,18h1.1l4.3-4.7l3.5,4.7h3.8L13.2,11l4.6-5h-1.1l-4,4.3L9.5,6.1H5.7z M7.3,6.9h1.7l7.7,10.2H15
	L7.3,6.9z"/>
</svg>
              </TwitterShareButton>
            </div>
          </div>
                <div className="pageLinks">
                  <Link to={'../fun'}>View more fun games</Link>
                </div>
              </div>
              <div className="col-md-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
export default States;
