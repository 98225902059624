import { useEffect, useState } from 'react'
import { getCountrySummaries } from '../Contentful'

export default function useCountrySummaries (skip, sortOrder, limit) {
  const promise = getCountrySummaries (skip, sortOrder, limit);
  //console.log("test 1");
  const [countrySummaries, setCountrySummaries] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    promise.then(result => {
      setCountrySummaries(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [countrySummaries, isLoading]
}
