import { useEffect, useState } from 'react'
import { getFeaturedGames } from '../Contentful'

export default function useFeaturedGames (tag) {
  const promise = getFeaturedGames (tag)
  // console.log("test 1");
  const [gamesSummaries, setGamesSummaries] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setGamesSummaries(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [gamesSummaries, isLoading]
}
