import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

let valCaptcha = '';

const ContactForm = () => {
  const form = useRef();
  const captchaRef = useRef(null)
  const [formStatus, setFormStatus] = React.useState('Send');
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isHidden, setIsHidden] = React.useState(true);

  const getCaptcha = (captchaValue) => {
    // console.log("captchaValue = ", captchaValue);
    valCaptcha = captchaValue;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const fromName = document.getElementById('name').value;
    const fromEmail = document.getElementById('email').value;
    const message = document.getElementById('message').value;

    // if ((fromName == null || fromName == "") && (fromEmail == null || fromEmail == "") && (message == null || message == "")) {
    //   alert("Please Fill In All Required Fields");
    //   return false;
    // }
    if ((valCaptcha == null || valCaptcha === "")) {
      alert("Please fill in the reCAPTCHA");
      return false;
    }
    const params = {
        'from_name':fromName,
        'from_email':fromEmail,
        'message':message,
        'g-recaptcha-response': valCaptcha
      };
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        params,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          setIsDisabled(!isDisabled);
          setIsHidden(!isHidden);
          setFormStatus('Sent');
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );
  };
  return (
    <div className="contactForm">
      <h2>Message us</h2>
      <form ref={form} onSubmit={sendEmail}>
          <label className="form-label" htmlFor="name">
            Name <span className="asterix"> * </span>
          </label>
          <input className="form-control" type="text" id="name" name="from_name" disabled={isDisabled} required />
          <label className="form-label" htmlFor="email">
            Email <span className="asterix"> * </span>
          </label>
          <input className="form-control" type="email" id="email" name="from_email" disabled={isDisabled} required />
          <label className="form-label" htmlFor="message">
            Message <span className="asterix"> * </span>
          </label>
          <textarea className="form-control" id="message" name="message" disabled={isDisabled} required />
          <div className="recaptchaArea">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              ref={captchaRef}
              onChange={getCaptcha}
              disabled={isDisabled}
            />
          </div>
          <div className = "btnContact">
            <button className="btn btn-primary" type="submit" disabled={isDisabled}>
              {formStatus}
            </button>
          </div>
          <div className = "confirmMessage" hidden={isHidden}>
            <p>Thank you for your message.</p>
          </div>
      </form>
    </div>
  )
}
export default ContactForm
