import React from 'react';
import { Link } from 'react-router-dom';
import useMultipleSummaries from '../custom-hooks/useMultipleSummaries';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import moment from 'moment';
import ReactHtmlParser from 'html-react-parser';

function RelatedBlogs (props) {
  const postTag = props.tag;
  const [summaries, isLoading] = useMultipleSummaries(postTag);
  // console.log("postTag = ", postTag);
  let summaryCheck = 0;
  if(isLoading === true) {
    return <p>Loading...</p>;
  }

  if(summaries.length === 0) {
    //do something
    summaryCheck = 1;
    // console.log("no summaries");
  }
  else {
    summaryCheck = 2;
  }
  // console.log("summaries = ", summaries);

  const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
            `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
    },
};

  // const rawRichTextField = authors[0].content;
  // const outputHTML = documentToHtmlString(rawRichTextField, options);

//fields.title,fields.summary,fields.rollUpImage,fields.tags,fields.publishedDate

  return (
      <div>
        <h2>Related articles</h2>
      {summaryCheck === 1 &&
        <p>No related articles</p>
      }
      {summaries.map(summaryPost => {
        const updated = moment(summaryPost.fields.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
        const rawRichTextField = summaryPost.fields.summary;
        const outputHTML = documentToHtmlString(rawRichTextField, options);
        const linkURL = "../blog/" + summaryPost.fields.slug;
        // console.log(summaryPost.fields.title + updated);
      return (
        <div className="relatedBlog" key={summaryPost.fields.title}>
          <div className="row">
            <div className="col-md-3 relatedCol">
              <img className="relatedImage roundedImage img-fluid mx-auto" src={summaryPost.fields.rollUpImage.fields.file.url} alt={summaryPost.fields.rollUpImage.fields.title} />
            </div>
            <div className="col-md-9 relatedCol">
              <div className="summaryBlock" id={summaryPost.fields.title}>
                <Link to={linkURL} reloadDocument className="relatedLink"><h2>{summaryPost.fields.title}</h2></Link>
                <div className="summary">
                  {ReactHtmlParser(outputHTML)}
                </div>
                <p className="published">Published: {updated}</p>
                <div className="tags">
                  {summaryPost.fields.tags.map(tag => {
                    const tagURL = "../search?prod_ATLAS%5BrefinementList%5D%5Btags%5D%5B0%5D=" + tag;
                    return (
                      <Link to={tagURL} reloadDocument className="tagLink" key={tag}><span className="badge bg-pill bg-primary" key={tag}>{tag}</span></Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })}
      </div>
    );
}

export default RelatedBlogs;
