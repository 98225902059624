import { useEffect, useState } from 'react'
import { getAuthors} from '../Contentful'

export default function useAuthors() {
  const promise = getAuthors()
  //console.log("test 1");
  const [authors, setAuthors] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setAuthors(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [authors, isLoading]
}
