import { useEffect, useState } from 'react'
import { getQuizSummaries } from '../Contentful'

export default function useQuizSummaries (skip, sortOrder,limit) {
  const promise = getQuizSummaries (skip, sortOrder, limit);
  //console.log("test 1");
  const [quizSummaries, setQuizSummaries] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    promise.then(result => {
      setQuizSummaries(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [quizSummaries, isLoading]
}
