import React from 'react';
import useAuthors from '../custom-hooks/useAuthors'

function AuthorsList (props) {

  const [authors, isLoading] = useAuthors();
  // const [pageIntros, isLoading] = usePageIntros(page);
  // return <h1>Page Title = {props.pageTitle}</h1>;
  if (isLoading === true) {
    return <p>Loading...</p>;
  }
  // console.log("authors = ", authors);

  return (
      <div>
      {authors.map(author => {
      return (
        <div className="authors" key={author.fields.title}>
          <div className="row">
            <div className="col-md-3 authorCol">
              <img className="authorImage img-fluid mx-auto" src={author.fields.headshot.fields.file.url} alt={author.fields.headshot.fields.title} />
            </div>
            <div className="col-md-9 authorCol">
              <div className="authorBlock" id={author.fields.title}>
                <h3>{author.fields.title}</h3>
                <p>{author.fields.description}</p>
                <p>Countries visited: {author.fields.countryCount}</p>
                <p>Favourite place: {author.fields.favouritePlace}</p>
              </div>
            </div>
          </div>
        </div>
      );
    })}
      </div>
    );
}

export default AuthorsList;
