import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCountryTotal from '../custom-hooks/useCountryTotal';

function CountryPagination (props) {
  const navigate = useNavigate();
  const page = props.page;
  const sortVal = props.sort;
  const [totalCountries, isLoading] = useCountryTotal();
  if (isLoading === true) {
    return <p>Loading...</p>;
  }

  const countryTotal = totalCountries.length;
  const nPages = Math.ceil(countryTotal/ 5);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
  const currentPage = Number(page);

  //add in logic to set pageNumbers to be five pages only

  const setCurrentPage = (newPage) => {
    const newPath = "../countries?page=" + newPage + "&sort=" + sortVal;
    navigate(newPath);
    navigate(0);
  };

  const nextPage = () => {
    if(currentPage !== nPages) {
      const goToPage = currentPage + 1;
      setCurrentPage(goToPage);
    }
    };

    const lastPage = () => {
      if(currentPage !== nPages) {
        const goToPage = nPages;
        setCurrentPage(goToPage);
      }
      };

  const prevPage = () => {
    if(currentPage !== 1) {
      const goToPage = currentPage - 1;
      setCurrentPage(goToPage);
    }
    };

    const firstPage = () => {
      if(currentPage !== 1) {
        const goToPage = 1;
        setCurrentPage(goToPage);
      }
      };

    return (
        <nav>
            <ul className='pagination justify-content-center'>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''} `}>
                    <button className="page-link" onClick={firstPage} >
                      <span aria-hidden="true">‹‹</span>
                      <span className="sr-only">First</span>
                    </button>
                </li>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''} `}>
                    <button className="page-link" onClick={prevPage} >
                      <span aria-hidden="true">‹</span>
                      <span className="sr-only">Previous</span>
                    </button>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} className= {`page-item ${currentPage === pgNumber ? 'active' : ''} `} >
                        <button onClick={() => setCurrentPage(pgNumber)} className='page-link'>{pgNumber} </button>
                    </li>
                ))}
                <li className={`page-item ${currentPage === nPages ? 'disabled' : ''} `}>
                    <button className="page-link" onClick={nextPage}>
                      <span aria-hidden="true">›</span>
                      <span className="sr-only">Next</span>
                    </button>
                </li>
                <li className={`page-item ${currentPage === nPages ? 'disabled' : ''} `}>
                    <button className="page-link" onClick={lastPage}>
                      <span aria-hidden="true">››</span>
                      <span className="sr-only">Last</span>
                    </button>
                </li>
            </ul>
        </nav>
      );
}

export default CountryPagination;
