import { useEffect, useState } from 'react'
import { getQuizTotal } from '../Contentful'

export default function useQuizTotal() {
  const promise = getQuizTotal();
  //console.log("test 1");
  const [quizPostTotals, setQuizPostTotals] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setQuizPostTotals(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [quizPostTotals, isLoading]
}
