import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function BlogSort (props) {
  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);
  const navigate = useNavigate();
  const page = props.page;
  const sortVal = props.sort;
  let curSort = "Latest";
  if (sortVal === "latest") {
    curSort = "Latest";
  }
  else if (sortVal === "AZ") {
    curSort = "A-Z";
  }
  else if (sortVal === "ZA") {
    curSort = "Z-A";
  }

  const changeSort = (sortParam) => {
    const newPath = "../blog?page=" + page + "&sort=" + sortParam;
    navigate(newPath);
    navigate(0);
  };

    return (
      <div className="sortSection">
        <p className="sortTitle">Sort: </p>
        <div className="dropdown">
          <button className="btn btn-blue-outline dropdown-toggle dropButText" onClick={toggleOpen} data-bs-toggle="dropdown" aria-expanded="false">
            {curSort}
          </button>
          <ul className={`dropdown-menu ${dropdown ? 'show' : ''}`}>
            <li><button className={`dropdown-item firstDD ${sortVal === "latest" ? 'active' : ''} `} onClick={() => changeSort("latest")} >Latest</button></li>
            <li><button className={`dropdown-item ${sortVal === "AZ" ? 'active' : ''} `} onClick={() => changeSort("AZ")} >A-Z</button></li>
            <li><button className={`dropdown-item lastDD ${sortVal === "ZA" ? 'active' : ''} `} onClick={() => changeSort("ZA")} >Z-A</button></li>
          </ul>
        </div>
      </div>
      );
}

export default BlogSort;
