import { useEffect, useState } from 'react'
import { getMultipleBlogSummaries } from '../Contentful'

export default function useMultipleBlogSummaries (blogs) {
  const promise = getMultipleBlogSummaries (blogs);
  //console.log("test 1");
  const [blogSummaries, setBlogSummaries] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    promise.then(result => {
      setBlogSummaries(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [blogSummaries, isLoading]
}
