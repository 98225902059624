import { useEffect, useState } from 'react'
import { getBlogTotal } from '../Contentful'

export default function useBlogTotal() {
  const promise = getBlogTotal();
  //console.log("test 1");
  const [blogPostTotals, setBlogPostTotals] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setBlogPostTotals(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [blogPostTotals, isLoading]
}
