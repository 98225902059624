import React, { useState } from 'react';
import { BrowserRouter, Routes, NavLink, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";

import About from './pages/About';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Countries from './pages/Countries';
import Country from './pages/Country';
import Fun from './pages/Fun';
import Quiz from './pages/Quiz';
import Search from './pages/Search';
import Contact from './pages/Contact';
import Longitude from './pages/Longitude';
import Latitude from './pages/Latitude';
import States from './pages/States';
import States2 from './pages/States2';
import logo from './images/totLogo.png';

function App() {
  const [hamburgerOpen, setHamburgerOpen] = useState(true);

    const toggleHamburger = () =>{
        setHamburgerOpen(!hamburgerOpen);
        // console.log('hamburgerOpen = ', hamburgerOpen);
    }

  // render the fetched Contentful data
  return (
    <div className="App">
      <BrowserRouter>
        <div className="header parentDiv">
          <div className="headerDisp">
          </div>
            <div className="headerDivs" id="headerLogo">
              <Link to={'./'} reloadDocument className="homeLogo img-fluid mx-auto">
                <img className="totlogo" id="totlogo" alt="The Ordinary Tourist logo" src={logo} />
              </Link>
            </div>
            <div className="headerDivs" id="navLinks">
              <div className="row" id="navLinksRow">
                <div className="col-md-6">
                  <div className="headerDivs" id="navLinksLeft">
                    <NavLink activeclassname="active" reloadDocument className="headerNav" to="/blog">Blog</NavLink>
                    <NavLink activeclassname="active" reloadDocument className="headerNav" to="/countries">Countries</NavLink>
                    <NavLink activeclassname="active" reloadDocument className="headerNav" to="/fun">Fun</NavLink>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="headerDivs" id="navLinksRight">
                    <NavLink activeclassname="active" reloadDocument className="headerNav" to="/about">About</NavLink>
                    <NavLink activeclassname="active" reloadDocument className="headerNav" to="/search">Search</NavLink>
                    <NavLink activeclassname="active" reloadDocument className="headerNav" to="/contact">Contact</NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className={hamburgerOpen ? 'hamburger' : 'hamburger open'} onClick={toggleHamburger}>
              <div className="bar-one" />
              <div className="bar-two" />
              <div className="bar-three" />
            </div>
            <div className={hamburgerOpen ? 'hamburgerMenu' : 'hamburgerMenu open'}>
              <div className="hamMenu">
                <ul>
                  <li><NavLink activeclassname="active" reloadDocument className="headerNav" to="/blog">Blog</NavLink></li>
                  <li><NavLink activeclassname="active" reloadDocument className="headerNav" to="/countries">Countries</NavLink></li>
                  <li><NavLink activeclassname="active" reloadDocument className="headerNav" to="/fun">Fun</NavLink></li>
                  <li><NavLink activeclassname="active" reloadDocument className="headerNav" to="/about">About</NavLink></li>
                  <li><NavLink activeclassname="active" reloadDocument className="headerNav" to="/search">Search</NavLink></li>
                  <li><NavLink activeclassname="active" reloadDocument className="headerNav" to="/contact">Contact</NavLink></li>
                </ul>
              </div>
            
              </div>
        </div>
            <div className="content parentDiv">
              <Routes>
              <Route exact path='/' element={<Home/>}/>
              <Route path='/about' element={<About/>}/>
              <Route path='/blog' element={<Blog/>}/>
              <Route path='/blog/:id' element={<BlogPost/>}/>
              <Route path='/countries' element={<Countries/>}/>
              <Route path='/countries/:id' element={<Country/>}/>
              <Route path='/fun' element={<Fun/>}/>
              <Route path='/fun/:id' element={<Quiz/>}/>
              <Route path='/search' element={<Search/>}/>
              <Route path='/contact' element={<Contact/>}/>
              <Route path='/longitude' element={<Longitude/>}/>
              <Route path='/latitude' element={<Latitude/>}/>
              <Route path='/states' element={<States/>}/>
              <Route path='/states2' element={<States2/>}/>
              </Routes>
            </div>
            <div className="footer parentDiv">
              <div className="row">
                <div className="col-sm-8 col-6" id="footerLogoPanel">
                  <div className="footerDivs" id="footerLogo">
                    <Link to={'./'}><img className="totlogo" id="totFooterlogo" alt="The Ordinary Tourist logo" src={logo} />
                    </Link>
                  </div>
                  <div className="copyright">
                    <p>© Siso 2023. All rights reserved</p>
                  </div>
                </div>
                <div className="col-sm-4 col-6" id="footerNavPanel">
                <div className="footerLinksTitle">
                  <p>Content</p>
                </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="footerDivs footerLinks" id="footerNavLinks">
                      <ul  id="footerLinksCol1">
                        <li><NavLink activeclassname="active" className="footerNav" to="/blog">Blog</NavLink></li>
                        <li><NavLink activeclassname="active" className="footerNav" to="/countries">Countries</NavLink></li>
                        <li><NavLink activeclassname="active" className="footerNav" to="/fun">Fun</NavLink></li>
                      </ul>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="footerDivs footerLinks" id="footerNavLinks">
                      <ul id="footerLinksCol2">
                        <li><NavLink activeclassname="active" className="footerNav" to="/about">About</NavLink></li>
                        <li><NavLink activeclassname="active" className="footerNav" to="/search">Search</NavLink></li>
                        <li><NavLink activeclassname="active" className="footerNav" to="/contact">Contact</NavLink></li>
                      </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
        </BrowserRouter>
      </div>
  );
}

export default App;
