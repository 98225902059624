import React from 'react';
import PageIntro from '../components/PageIntro';
import FivePosts from '../components/FivePosts';
import BlogPagination from '../components/BlogPagination';
import BlogSort from '../components/BlogSort';
import DisplayAdvert from '../components/DisplayAdvert';  

function Blog() {

    let skipVal = 0;
    let sortOrder = "-fields.publishedDate";
    let search = window.location.search;
    const blogParams = new URLSearchParams(search);
    let blogPage = blogParams.get('page');
    if (!blogPage) {
      blogPage = 1;
    }
    if (blogPage === 1) {
      skipVal = 0;
    }
    else {
      skipVal = (blogPage - 1) * 5;
    }
    let sortParam = blogParams.get('sort');
    if (!sortParam) {
      sortParam = "latest";
    }
    if (sortParam === "latest") {
      sortOrder = "-fields.publishedDate";
    }
    else if (sortParam === "AZ") {
      sortOrder = "fields.title";
    }
    else if (sortParam === "ZA") {
      sortOrder = "-fields.title";
    }

    // console.log("blogPage = " + blogPage);
    // console.log("skipVal = " + skipVal);

    return (
      <div id="mainContent">
        <PageIntro pageTitle="Blog" output="meta"/>
        <div id="blogSection1" className="pageSection">
            <div className="row">
              <div className="col-md-9">
                <div id="pageIntroBlog">
                  <PageIntro pageTitle="Blog" output="intro"/>
                </div>
                <div className="sortBlogs">
                  <BlogSort page={blogPage} sort={sortParam} />
                </div>
                <div className="blogList">
                  <FivePosts skip={skipVal} sort={sortOrder} />
                </div>
                <div className="blogPagination">
                  <BlogPagination page={blogPage} sort={sortParam} />
                </div>
                <div className="affilAd adLand">
                <PageIntro pageTitle="Blog" output="advert"/>
                </div>
              </div>
              <div className="col-md-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
export default Blog;
