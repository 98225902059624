import React from 'react';
import useSingleAsset from '../custom-hooks/useSingleAsset';

function SearchImage (props) {
  const assetID = props.assetID;
  const [assetRes, isLoading] = useSingleAsset(assetID);
  let imageCheck = 0;

  if(isLoading === true) {
    return <p>Loading...</p>;
  }
  if(assetRes.length === 0) {
    imageCheck = 1;
  }
  else {
    imageCheck = 2;
  }

  return (
      <div>
      {imageCheck === 2 &&
        <div className="searchImg">
          <img className="relatedImage roundedImage img-fluid mx-auto" src={assetRes[0].fields.file.url} alt={assetRes[0].fields.title} />
        </div>
      }
      </div>
    );
}

export default SearchImage;
