import React from 'react';
import { Link } from 'react-router-dom';
import useCountrySummaries from '../custom-hooks/useCountrySummaries';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import moment from 'moment';
import ReactHtmlParser from 'html-react-parser';

function CountryTiles (props) {
  const skip = props.skip;
  const sortOrder = props.sort;
  const limit = props.limit
  const [summaries, isLoading] = useCountrySummaries(skip,sortOrder,limit);

  if (isLoading === true) {
    return <p>Loading...</p>;
  }
  // console.log("summaries = ", summaries);

  const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
            `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
    },
};

  // const rawRichTextField = authors[0].content;
  // const outputHTML = documentToHtmlString(rawRichTextField, options);

//fields.title,fields.summary,fields.rollUpImage,fields.tags,fields.publishedDate

  return (
      <div className="countryList row">
      {summaries.map((summaryPost, index) => {
        const updated = moment(summaryPost.fields.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
        const rawRichTextField = summaryPost.fields.summary;
        const outputHTML = documentToHtmlString(rawRichTextField, options);
        const linkURL = "../countries/" + summaryPost.fields.slug;
        let classText = "countryListColumn";
        // console.log("index = ", index);
        if (index === 1) {
          classText = classText + " middleColumn";
        }
        if (index === 3) {
          classText = classText + " lastColumn";
        }
        // console.log(summaryPost.fields.title + updated);
      return (
        <div className="countryListCountry" key={summaryPost.fields.countryName}>
          <div className={classText}>
              <Link to={linkURL} reloadDocument className="countryListLink">
                <img className="countryListImage roundedImage img-fluid mx-auto" src={summaryPost.fields.rollUpImage.fields.file.url} alt={summaryPost.fields.rollUpImage.fields.title} />
                </Link>
              <div className="summaryBlock" id={summaryPost.fields.countryName}>
                <Link to={linkURL} reloadDocument className="countryListLink"><h2>{summaryPost.fields.countryName}</h2></Link>
                <div className="summary">
                  {ReactHtmlParser(outputHTML)}
                </div>
                <p className="published">Published: {updated}</p>
              </div>
            </div>
          </div>
      );
    })}
      </div>
    );
}

export default CountryTiles;
