import React from 'react';
import { Link } from 'react-router-dom';
import PageIntro from '../components/PageIntro';
import SearchImage from '../components/SearchImage';
import algoliasearch from 'algoliasearch/lite';
import DisplayAdvert from '../components/DisplayAdvert';  

import { InstantSearch, SearchBox, Hits, RefinementList, Pagination, Configure } from 'react-instantsearch-hooks-web';
import 'instantsearch.css/themes/satellite.css';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import remarkUnwrapImages from 'remark-unwrap-images';
import moment from 'moment';

const searchClient = algoliasearch('HWE3YE58WM', '650a758a5acd8c89cd9b62dfeadb980b');
const defaultStr = "Thailand";

function Hit({ hit }) {
  // console.log("hit = ", hit);
  if (hit.contentType === "blogPost") {
    const assetID = hit.rollUpID;
    const linkURL = "../blog/" + hit.slug;
    const summaryArray = hit.summary.content[0].content;
    let markdownText1 = "";
    summaryArray.map(summary => {
      markdownText1 = markdownText1 + summary.value;
      }
    );
    const updated = moment(hit.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
  return (
    <article>
    <div className="row">
      <div className="col-3 relatedCol">
      <Link to={linkURL} reloadDocument className="searchLink">
          <SearchImage assetID={assetID}/>
        </Link>
      </div>
      <div className="col-9 relatedCol">
        <div className="summaryBlock" id={hit.title}>
          <Link to={linkURL} reloadDocument className="searchLink"><h2>{hit.title}</h2></Link>
          <div className="summary">
            <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText1}</ReactMarkdown>
          </div>
          <p className="published">Published: {updated}</p>
        </div>
      </div>
    </div>
    </article>
  );
  }
  else if (hit.contentType === "country") {
    const assetID = hit.rollUpID;
    const linkURL = "../countries/" + hit.slug;
    const summaryArray = hit.summary.content[0].content;
    let markdownText1 = "";
    summaryArray.map(summary => {
      markdownText1 = markdownText1 + summary.value;
      }
    );
    //console.log("markdownText1 = ",markdownText1);

    //const markdownText1 = hit.summary.content[0].content[0].value;
    const updated = moment(hit.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
  return (
    <article>
    <div className="row">
      <div className="col-3 relatedCol">
        <Link to={linkURL} reloadDocument className="searchLink">
          <SearchImage assetID={assetID}/>
        </Link>
      </div>
      <div className="col-9 relatedCol">
        <div className="summaryBlock" id={hit.countryName}>
          <Link to={linkURL} reloadDocument className="searchLink">
            <h2>{hit.countryName}</h2>
            </Link>
          <div className="summary">
            <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText1}</ReactMarkdown>
          </div>
          <p className="published">Published: {updated}</p>
        </div>
      </div>
    </div>
    </article>
  );
  }
  else if (hit.contentType === "quizzes") {
    const assetID = hit.rollUpID;
    const linkURL = "../fun/" + hit.slug;
    const summaryArray = hit.summary.content[0].content;
    let markdownText1 = "";
    summaryArray.map(summary => {
      markdownText1 = markdownText1 + summary.value;
      }
    );
    //console.log("markdownText1 = ",markdownText1);

    //const markdownText1 = hit.summary.content[0].content[0].value;
    const updated = moment(hit.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
  return (
    <article>
    <div className="row">
      <div className="col-3 relatedCol">
      <Link to={linkURL} reloadDocument className="searchLink">
          <SearchImage assetID={assetID}/>
        </Link>
      </div>
      <div className="col-9 relatedCol">
        <div className="summaryBlock" id={hit.name}>
          <Link to={linkURL} reloadDocument className="searchLink"><h2>{hit.name}</h2></Link>
          <div className="summary">
            <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText1}</ReactMarkdown>
          </div>
          <p className="published">Published: {updated}</p>
        </div>
      </div>
    </div>
    </article>
  );
  }
  else {
    return (
      <article>
        <p>Unable to load {hit.contentType}</p>
      </article>
    );
  }
}

function Search() {

    return (
      <div id="mainContent">
        <PageIntro pageTitle="Search" output="meta"/>
        <div id="searchSection1" className="pageSection">
            <div className="row">
              <div className="col-lg-9">
                <div id="pageIntroAbout">
                  <PageIntro pageTitle="Search" output="intro"/>
                </div>
                <div className="searchPanel">
                  <InstantSearch searchClient={searchClient} indexName="prod_ATLAS" routing={true}>
                    <Configure hitsPerPage={5} />
                    <SearchBox />
                    <RefinementList
                      attribute="tags"
                      sortBy={['count:desc', 'name:asc']}
                      operator="and"
                      showMore={true}
                      showMoreLimit={20}
                    />
                    <Hits hitComponent={Hit} />
                    <Pagination />
                  </InstantSearch>
                </div>
              </div>
              <div className="col-lg-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
export default Search;
