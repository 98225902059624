import React from 'react';
import useSingleBlogPost from '../custom-hooks/useSingleBlogPost';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import moment from 'moment';
import remarkUnwrapImages from 'remark-unwrap-images';
import RelatedContent from "./RelatedContent.js";
import { useNavigate } from 'react-router-dom';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import ReactHtmlParser from 'html-react-parser';
import {FacebookShareButton, TwitterShareButton} from "react-share";

function SingleBlog (props) {
  const navigate = useNavigate();
  const blog = props.blogTitle;
  // console.log(" props.blogTitle = ", props.blogTitle);
  const [blogPost, isLoading] = useSingleBlogPost(blog);

  if(isLoading === true) {
    return <p>Loading...</p>;
  }

  if(!blogPost) {
    const newPath = "../blog";
    navigate(newPath);
  }
  // console.log("blogPost = ", blogPost);

   const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
            `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
    },
};

   const markdownText1 = blogPost.fields.content1;
   const markdownText2 = blogPost.fields.content2;
   const markdownText3 = blogPost.fields.content3;
   const updated = moment(blogPost.fields.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
   let relatedSlugs = '';
   const relatedContent = blogPost.fields.relatedPosts;
   let relatedCheck = 0;
  //  console.log('relatedContent = ',relatedContent);
   if (relatedContent === undefined) {
     relatedCheck = 1;
   }
   else {
     const arrayLength = relatedContent.length;
     for (var i = 0; i < arrayLength; i++) {
       if (i > 0) {
         relatedSlugs = relatedSlugs + ",";
       }
       relatedSlugs = relatedSlugs + relatedContent[i];
       //console.log("relatedSlugs = ", relatedSlugs);
     }
     relatedCheck = 2;
   }

   const rawRichTextField = blogPost.fields.summary;
   const outputHTML = documentToHtmlString(rawRichTextField, options);
   let plainString = outputHTML.replace(/<[^>]+>/g, '');
   plainString = plainString.replace(/&quot;/g, '');
   let titleString = blogPost.fields.title + " - The Ordinary Tourist";
   const linkURL = window.location.href;

  return (
      <div className="blogPostContent">
      <HelmetProvider>
      <Helmet>
        <meta name="keywords" content={blogPost.fields.keywords} />
        <meta name="description" content={plainString} />
        <meta name="title" content={titleString} />
        <title>{blogPost.fields.title} - The Ordinary Tourist</title>
      </Helmet>
      </HelmetProvider>
        <div className="introSection">
          <h1>{blogPost.fields.title}</h1>
          <p className="authorMeta">By {blogPost.fields.author.fields.title}</p>
          <p className="blogMeta"><span className="boldText">Travel date: </span>{blogPost.fields.travelDate}</p>
          <p className="blogMeta"><span className="boldText">Published: </span>{updated}</p>
          <div className="row">
            <div className="col-6">
              <div className="tags">
                {blogPost.fields.tags.map(tag => {
                  const tagURL = "../search?prod_ATLAS%5BrefinementList%5D%5Btags%5D%5B0%5D=" + tag;
                  return (
                    <Link to={tagURL} reloadDocument className="tagLink" key={tag}><span className="badge bg-pill bg-primary" key={tag}>{tag}</span></Link>
                  );
                })}
              </div>
            </div>
            <div className="col-6">
              <div className="shareButtonsTop">
                <div className="shareText">
                  <p className="shareP">SHARE:</p>
                </div>
                <div className="shareIcon" id="shareFacebook">
              <FacebookShareButton 
                url={linkURL}
                quote={"The Ordinary Tourist - We help you explore the world"}
                hashtag="#theordinarytourist"
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
              </FacebookShareButton>
            </div>
            <div className="shareIcon" id="shareTwitter">
              <TwitterShareButton
                url={linkURL}
                title={blogPost.fields.title}
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
<path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
	S18.6,0,12,0z M5.7,6.1l4.9,6.5L5.7,18h1.1l4.3-4.7l3.5,4.7h3.8L13.2,11l4.6-5h-1.1l-4,4.3L9.5,6.1H5.7z M7.3,6.9h1.7l7.7,10.2H15
	L7.3,6.9z"/>
</svg>
              </TwitterShareButton>
            </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bannerImage">
          <img className="bannerImg img-fluid mx-auto" src={blogPost.fields.bannerImage.fields.file.url} alt={blogPost.fields.bannerImage.fields.title} />
        </div>
        <div className="mainBlogContent">
        <div className="blogContent" id="blogContent1">
          <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText1}</ReactMarkdown>
        </div>
        <div className="affilAd adLand">
        {ReactHtmlParser(blogPost.fields.advert1.fields.advertHtml)}
        </div>
        <div className="blogContent" id="blogContent2">
          <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText2}</ReactMarkdown>
        </div>
        <div className="affilAd adLand">
        {ReactHtmlParser(blogPost.fields.advert2.fields.advertHtml)}
        </div>
        <div className="blogContent" id="blogContent3">
          <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText3}</ReactMarkdown>
        </div>
        <div className="affilAd adLand">
        {ReactHtmlParser(blogPost.fields.advert3.fields.advertHtml)}
        </div>
        </div>
          <div className="shareButtonsBottom">
            <div className="shareText">
              <p className="shareP">SHARE:</p>
            </div>
            <div className="shareIcon" id="shareFacebook">
              <FacebookShareButton 
                url={linkURL}
                quote={"The Ordinary Tourist - We help you explore the world"}
                hashtag="#theordinarytourist"
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
              </FacebookShareButton>
            </div>
            <div className="shareIcon" id="shareTwitter">
              <TwitterShareButton
                url={linkURL}
                title={blogPost.fields.title}
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
<path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
	S18.6,0,12,0z M5.7,6.1l4.9,6.5L5.7,18h1.1l4.3-4.7l3.5,4.7h3.8L13.2,11l4.6-5h-1.1l-4,4.3L9.5,6.1H5.7z M7.3,6.9h1.7l7.7,10.2H15
	L7.3,6.9z"/>
</svg>
              </TwitterShareButton>
            </div>
          </div>
          {relatedCheck === 2 &&
            <div className="relatedContent">
              <h2>Related articles</h2>
              <RelatedContent slugs={relatedSlugs} />
            </div>
          }
      </div>
    )
}

export default SingleBlog;
