import React from 'react';
import PageIntro from '../components/PageIntro';
import FeaturedGames from '../components/FeaturedGames';
import QuizSort from '../components/QuizSort';
import FiveQuizzes from '../components/FiveQuizzes';
import QuizPagination from '../components/QuizPagination';
import DisplayAdvert from '../components/DisplayAdvert'; 

function Fun() {

  let skipVal = 0;
  let sortOrder = "-fields.publishedDate";
  const limit = 5;
  let search = window.location.search;
  const quizParams = new URLSearchParams(search);
  let quizPage = quizParams.get('page');
  if (!quizPage) {
    quizPage = 1;
  }
  if (quizPage === 1) {
    skipVal = 0;
  }
  else {
    skipVal = (quizPage - 1) * 5;
  }
  let sortParam = quizParams.get('sort');
  if (!sortParam) {
    sortParam = "latest";
  }
  if (sortParam === "latest") {
    sortOrder = "-fields.publishedDate";
  }
  else if (sortParam === "AZ") {
    sortOrder = "fields.name";
  }
  else if (sortParam === "ZA") {
    sortOrder = "-fields.name";
  }

    return (
      <div id="mainContent">
        <PageIntro pageTitle="Fun" output="meta"/>
        <div id="funSection1" className="pageSection">
            <div className="row">
              <div className="col-md-9">
                <div id="pageIntroFun">
                  <PageIntro pageTitle="Fun" output="intro"/>
                </div>
                <div className="gamesList">
                  <h2>Featured games</h2>
                  <FeaturedGames />
                </div>
                <div className="affilAd adLand">
                <PageIntro pageTitle="Fun" output="advert"/>
                </div>
                <div className="quizzesArea">
                  <h2>Scavenger Hunts</h2>
                  <div className="sortQuizzes">
                    <QuizSort page={quizPage} sort={sortParam} />
                  </div>
                  <div className="quizzesList">
                    <FiveQuizzes skip={skipVal} sort={sortOrder} limit={limit} />
                  </div>
                  <div className="quizPagination">
                    <QuizPagination page={quizPage} sort={sortParam} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
export default Fun;
