import React from 'react';
import PageIntro from '../components/PageIntro'
import { Link } from 'react-router-dom';
import FivePostTiles from '../components/FivePostTiles';
import FivePostList from '../components/FivePostList';
import CountryTiles from '../components/CountryTiles';
import QuizTiles from '../components/QuizTiles';
import SearchImage from '../components/SearchImage';
import algoliasearch from 'algoliasearch/lite';

import { InstantSearch, Hits, Configure } from 'react-instantsearch-hooks-web';
import 'instantsearch.css/themes/satellite.css';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import remarkUnwrapImages from 'remark-unwrap-images';
import moment from 'moment';

const searchClient = algoliasearch('HWE3YE58WM', '650a758a5acd8c89cd9b62dfeadb980b');
const defaultStr = "Thailand";

function Hit({ hit }) {
  // console.log("hit = ", hit);
  if (hit.contentType === "blogPost") {
    const assetID = hit.rollUpID;
    const linkURL = "../blog/" + hit.slug;
    const summaryArray = hit.summary.content[0].content;
    let classText = "latestArticleColumn";
    if (hit.__position === 2) {
      classText = classText + " middleColumn";
    }
    if (hit.__position === 4) {
      classText = classText + " lastColumn";
    }
    let markdownText1 = "";
    summaryArray.map(summary => {
      markdownText1 = markdownText1 + summary.value;
      }
    );
    const updated = moment(hit.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
  return (
    <div className={classText}>
        <Link to={linkURL} reloadDocument className="articleLink"><SearchImage assetID={assetID}/></Link>
        <p className="artType">BLOG</p>
        <div className="summaryBlock" id={hit.title}>
          <Link to={linkURL} reloadDocument className="articleLink"><h2>{hit.title}</h2></Link>
          <div className="summary">
            <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText1}</ReactMarkdown>
          </div>
          <p className="published">Published: {updated}</p>
        </div>
      </div>
  );
  }
  else if (hit.contentType === "country") {
    const assetID = hit.rollUpID;
    const linkURL = "../countries/" + hit.slug;
    const summaryArray = hit.summary.content[0].content;
    let classText = "latestArticleColumn";
    if (hit.__position === 2) {
      classText = classText + " middleColumn";
    }if (hit.__position === 4) {
      classText = classText + " lastColumn";
    }
    let markdownText1 = "";
    summaryArray.map(summary => {
      markdownText1 = markdownText1 + summary.value;
      }
    );
    //console.log("markdownText1 = ",markdownText1);

    //const markdownText1 = hit.summary.content[0].content[0].value;
    const updated = moment(hit.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
  return (
    <div className={classText}>
      <Link to={linkURL} reloadDocument className="articleLink"><SearchImage assetID={assetID}/></Link>
        <p className="artType">COUNTRY</p>
        <div className="summaryBlock" id={hit.countryName}>
          <Link to={linkURL} reloadDocument className="articleLink"><h2>{hit.countryName}</h2></Link>
          <div className="summary">
            <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText1}</ReactMarkdown>
          </div>
          <p className="published">Published: {updated}</p>
        </div>
      </div>
  );
  }
  else if (hit.contentType === "quizzes") {
    const assetID = hit.rollUpID;
    const linkURL = "../fun/" + hit.slug;
    const summaryArray = hit.summary.content[0].content;
    let classText = "latestArticleColumn";
    if (hit.__position === 2) {
      classText = classText + " middleColumn";
    }
    if (hit.__position === 4) {
      classText = classText + " lastColumn";
    }
    let markdownText1 = "";
    summaryArray.map(summary => {
      markdownText1 = markdownText1 + summary.value;
      }
    );
    //console.log("markdownText1 = ",markdownText1);

    //const markdownText1 = hit.summary.content[0].content[0].value;
    const updated = moment(hit.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
  return (
    <div className={classText}>
    <Link to={linkURL} reloadDocument className="articleLink"><SearchImage assetID={assetID}/></Link>
        <p className="artType">FUN</p>
        <div className="summaryBlock" id={hit.name}>
          <Link to={linkURL} reloadDocument className="articleLink"><h2>{hit.name}</h2></Link>
          <div className="summary">
            <ReactMarkdown remarkPlugins={[gfm, remarkUnwrapImages]}>{markdownText1}</ReactMarkdown>
          </div>
          <p className="published">Published: {updated}</p>
        </div>
      </div>
  );
  }
  else {
    return (
      <div>
        <p>Unable to load {hit.contentType}</p>
      </div>
    );
  }
}

function Home() {
  const skipVal = 0;
  const skipNext = 5;
  const limitFour = 4;
  const sortOrder = "fields.order";
  const sortOrderPubDate = "-fields.publishedDate";

    return (
      <div id="mainContent">
      <PageIntro pageTitle="Home" output="meta"/>
      <div id="homeContent">
          <PageIntro pageTitle="Home" output="meta"/>
          <div id="homeBlogs" className="homeSection">
            <div className="featuredArticles">
              <FivePostTiles skip={skipVal} sort={sortOrder} />
            </div>
            <div className="centerText">
              <Link to={'./blog'} className="linkButton">View all blog posts</Link>
            </div>
          </div>
          <div className="horDiv"></div>
          <div id="homePosts" className="homeSection">
            <div className="nextArticles">
            <div className="centerText">
              <h1>Latest articles</h1>
            </div>
              <div className="latestArticles row">
                  <InstantSearch searchClient={searchClient} indexName="prod_ATLAS" routing={true}>
                    <Configure hitsPerPage={4} />
                    <Hits hitComponent={Hit} />
                  </InstantSearch>
              </div>
              <div className="centerText">
                <Link to={'./search'}>Search for articles</Link>
              </div>
              <div className="horDiv"></div>
            </div>
          </div>
          <div id="homeCountries" className="homeSection">
            <div className="countryPosts">
              <div className="centerText">
                <h1>Latest countries</h1>
              </div>
              <div className="latestCountries">
              <CountryTiles skip={skipVal} sort={sortOrderPubDate} limit={limitFour} />
            </div>
            <div className="centerText">
                <Link to={'./countries'}>View all countries</Link>
              </div>
              <div className="horDiv"></div>
            </div>
          </div>
          <div id="homeQuizzes" className="homeSection">
            <div className="quizPosts">
              <div className="centerText">
                <h1>Latest scavenger hunts</h1>
              </div>
              <div className="latestQuizzes">
              <QuizTiles skip={skipVal} sort={sortOrderPubDate} limit={limitFour} />
            </div>
            <div className="centerText">
                <Link to={'./fun'}>View all scavenger hunts</Link>
              </div>
              <div className="horDiv"></div>
            </div>
          </div>
          <div id="homeSocialIcons" className="homeSection">
            <div className="followUsLinks">
              <div className="centerText">
                <h1>Follow us</h1>
              </div>
              <div className="socialLinks">
                <div className="row">
                  <div className="col-sm-4" id="facebookLink">
                    <Link to={'https://www.facebook.com/theordinarytourist'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
                      <p className="socialName">THEORDINARYTOURIST</p>
                    </Link>
                  </div>
                  <div className="col-sm-4" id="twitterLink">
                    <Link to={'https://twitter.com/ord_tourist'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"/></svg>
                      <p className="socialName">@ORD_TOURIST</p>
                    </Link>
                  </div>
                  <div className="col-sm-4" id="instaLink">
                    <Link to={'https://www.instagram.com/the.ordinary.tourist/'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z"/></svg>
                      <p className="socialName">THE.ORDINARY.TOURIST</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
export default Home;
