import React from 'react';
import { Link } from 'react-router-dom';
import PageIntro from '../components/PageIntro';
import DisplayAdvert from '../components/DisplayAdvert'; 
import {FacebookShareButton, TwitterShareButton} from "react-share";

import latMap from '../images/lat/mapLatBack.png';
import lat1Act from '../images/lat/lat1Act.png';
import lat2Act from '../images/lat/lat2Act.png';
import lat3Act from '../images/lat/lat3Act.png';
import lat4Act from '../images/lat/lat4Act.png';
import lat5Act from '../images/lat/lat5Act.png';
import lat6Act from '../images/lat/lat6Act.png';
import lat7Act from '../images/lat/lat7Act.png';
import lat8Act from '../images/lat/lat8Act.png';
import lat9Act from '../images/lat/lat9Act.png';
import lat10Act from '../images/lat/lat10Act.png';
import lat11Act from '../images/lat/lat11Act.png';
import lat12Act from '../images/lat/lat12Act.png';
import lat13Act from '../images/lat/lat13Act.png';
import lat14Act from '../images/lat/lat14Act.png';
import lat15Act from '../images/lat/lat15Act.png';
import lat16Act from '../images/lat/lat16Act.png';
import lat17Act from '../images/lat/lat17Act.png';
import lat18Act from '../images/lat/lat18Act.png';
import lat1Yes from '../images/lat/lat1Yes.png';
import lat2Yes from '../images/lat/lat2Yes.png';
import lat3Yes from '../images/lat/lat3Yes.png';
import lat4Yes from '../images/lat/lat4Yes.png';
import lat5Yes from '../images/lat/lat5Yes.png';
import lat6Yes from '../images/lat/lat6Yes.png';
import lat7Yes from '../images/lat/lat7Yes.png';
import lat8Yes from '../images/lat/lat8Yes.png';
import lat9Yes from '../images/lat/lat9Yes.png';
import lat10Yes from '../images/lat/lat10Yes.png';
import lat11Yes from '../images/lat/lat11Yes.png';
import lat12Yes from '../images/lat/lat12Yes.png';
import lat13Yes from '../images/lat/lat13Yes.png';
import lat14Yes from '../images/lat/lat14Yes.png';
import lat15Yes from '../images/lat/lat15Yes.png';
import lat16Yes from '../images/lat/lat16Yes.png';
import lat17Yes from '../images/lat/lat17Yes.png';
import lat18Yes from '../images/lat/lat18Yes.png';

let curRange = 1;
let yesArray = [];
let totalYes = 0;

const places1F = ["North Pole, Arctic"];
const places1S = ["Nord, Greenland"];
const places1T = [""];
const places2F = ["Uummannaq, Greenland","Hammerfest, Norway"];
const places2S = ["Boyarka, Russia","Tiksi, Russia"];
const places2T = ["Longyearbyen, Svalbard"];
const places3F = ["Whitehorse, Canada","Yellowknife, Canada","Tórshavn, Faroe Islands","Helsinki, Finland","Tampere, Finland","Nuuk, Greenland"];
const places3S = ["Qaqortoq, Greenland","Reykjavík, Iceland","Bergen, Norway","Trondheim, Norway","Arkhangelsk, Russia","Surgut, Russia"];
const places3T = ["Yakutsk, Russia","Gävle, Sweden","Umeå, Sweden","Lerwick, United Kingdom","Anchorage, United States","Bethel, United States"];
const places4F = ["Minsk, Belarus","Brussels, Belgium","Calgary, Canada","Edmonton, Canada","Heihe, China","Prague, Czechia","Copenhagen, Denmark","Tallinn, Estonia","Raseborg, Finland"];
const places4S = ["Lille, France","Dublin, Ireland","Douglas, Isle Of Man","Nur-Sultan, Kazakhstan","Riga, Latvia","Vilnius, Lithuania","Amsterdam, Netherlands","Oslo, Norway"];
const places4T = ["Warsaw, Poland","Moscow, Russia","Saint Petersburg, Russia","Stockholm, Sweden","Kyiv, Ukraine","Birmingham, United Kingdom","London, United Kingdom","Juneau, United States"];
const places5F = ["Tirana, Albania","Andorra la Vella, Andorra","Yerevan, Armenia","Vienna, Austria","Baku, Azerbaijan","Sarajevo, Bosnia And Herzegovina","Sofia, Bulgaria","Montréal, Canada","Toronto, Canada","Changchun, China","Shenyang, China","Zagreb, Croatia","Brno, Czechia","Paris, France","Tbilisi, Georgia","Munich, Germany","Thessaloníki, Greece","Budapest, Hungary"];
const places5S = ["Milan, Italy","Rome, Italy","Sapporo, Japan","Saint Helier, Jersey","Almaty, Kazakhstan","Pristina, Kosovo","Bishkek, Kyrgyzstan","Vaduz, Liechtenstein","Luxembourg, Luxembourg","Skopje, Macedonia","Chisinau, Moldova","Monaco, Monaco","Ulaanbaatar, Mongolia","Podgorica, Montenegro","Ch’ŏngjin, North Korea","Bielsko-Biała, Poland","Porto, Portugal","Bucharest, Romania"];
const places5T = ["Rostov, Russia","Volgograd, Russia","Saint-Pierre, Saint Pierre And Miquelon","San Marino, San Marino","Belgrade, Serbia","Bratislava, Slovakia","Ljubljana, Slovenia","Barcelona, Spain","Madrid, Spain","Geneva, Switzerland","Khŭjand, Tajikistan","Istanbul, Turkey","Daşoguz, Turkmenistan","Odesa, Ukraine","Chicago, United States","New York, United States","Tashkent, Uzbekistan","Vatican City, Vatican City"];
const places6F = ["Kabul, Afghanistan","Sarandë, Albania","Algiers, Algeria","Kapan, Armenia","Naxçıvan, Azerbaijan","Hamilton, Bermuda","Beijing, China","Chengdu, China","Shanghai, China","Nicosia, Cyprus","Cairo, Egypt","Gaza, Gaza Strip","Gibraltar, Gibraltar","Athens, Greece","Ludhiāna, India","Tehran, Iran"];
const places6S = ["Baghdad, Iraq","Jerusalem, Israel","Palermo, Italy","Ōsaka, Japan","Tokyo, Japan","Amman, Jordan","Isfana, Kyrgyzstan","Beirut, Lebanon","Tripoli, Libya","Valletta, Malta","Tijuana, Mexico","Casablanca, Morocco","Pyongyang, North Korea","Lahore, Pakistan","Lisbon, Portugal","Sakākā, Saudi Arabia"];
const places6T = ["Seoul, South Korea","Valencia, Spain","Damascus, Syria","Dushanbe, Tajikistan","Tunis, Tunisia","Elazığ, Turkey","Ashgabat, Turkmenistan","Atlanta, United States","Dallas, United States","Las Vegas, United States","Los Angeles, United States","New Orleans, United States","Washington, United States","Samarkand, Uzbekistan","Al Quds, West Bank"];
const places7F = ["Tamanrasset, Algeria","Manama, Bahrain","Dhaka, Bangladesh","Thimphu, Bhutan","Zouar, Chad","Chongqing, China","Guangzhou, China","Nanning, China","Shenzhen, China","Havana, Cuba","Giza, Egypt","Hong Kong, Hong Kong","Delhi, India","Kolkāta, India","Shīrāz, Iran","Al Fāw, Iraq","Eilat, Israel"];
const places7S = ["Naha, Japan","Okinawa, Japan","Al ‘Aqabah, Jordan","Kuwait City, Kuwait","Xam Nua, Laos","Sabhā, Libya","Macau, Macau","Nouadhibou, Mauritania","Cancún, Mexico","Guadalajara, Mexico","León de los Aldama, Mexico","Laâyoune, Morocco","Mandalay, Myanmar","Kathmandu, Nepal","Muscat, Oman","Hyderabad City, Pakistan","Karachi, Pakistan"];
const places7T = ["Doha, Qatar","Jeddah, Saudi Arabia","Riyadh, Saudi Arabia","Las Palmas, Spain","Wādī Ḩalfā’, Sudan","Taipei, Taiwan","Mae Sai, Thailand","Nassau, The Bahamas","Grand Turk, Turks And Caicos Islands","Abu Dhabi, United Arab Emirates","Dubai, United Arab Emirates","Sharjah, United Arab Emirates","Honolulu, United States","Houston, United States","Miami, United States","Hanoi, Vietnam"];
const places8F = ["The Valley, Anguilla","Saint John’s, Antigua And Barbuda","Oranjestad, Aruba","Bridgetown, Barbados","Belize City, Belize","Kralendijk, Bonaire","Road Town, British Virgin Islands","Ouagadougou, Burkina Faso","Praia, Cabo Verde","Phnom Penh, Cambodia","George Town, Cayman Islands","N’Djamena, Chad","Nada, China","Barranquilla, Colombia","Puerto Limón, Costa Rica","Willemstad, Curaçao","Djibouti, Djibouti","Roseau, Dominica","Santo Domingo, Dominican Republic","San Salvador, El Salvador","Asmara, Eritrea","Gonder, Ethiopia"];
const places8S = ["Wa, Ghana","Saint George’s, Grenada","Pointe-à-Pitre, Guadeloupe","Hagåtña, Guam","Guatemala City, Guatemala","Siguiri, Guinea","Bissau, Guinea-Bissau","Port-au-Prince, Haiti","Tegucigalpa, Honduras","Mumbai, India","Kingston, Jamaica","Vientiane, Laos","Bamako, Mali","Fort-de-France, Martinique","Nouakchott, Mauritania","Mexico City, Mexico","Brades, Montserrat","Rangoon, Myanmar","Managua, Nicaragua","Niamey, Niger","Kano, Nigeria","Capitol Hill, Northern Mariana Islands"];
const places8T = ["Şalālah, Oman","Manila, Philippines","San Juan, Puerto Rico","Gustavia, Saint Barthelemy","Basseterre, Saint Kitts And Nevis","Castries, Saint Lucia","Marigot, Saint Martin","Kingstown, Saint Vincent And The Grenadines","Abhā, Saudi Arabia","Dakar, Senegal","Philipsburg, Sint Maarten","Boosaaso, Somalia","Khartoum, Sudan","Bangkok, Thailand","Banjul, The Gambia","Sansanné-Mango, Togo","Port of Spain, Trinidad And Tobago","Charlotte Amalie, U.S. Virgin Islands","Hilo, United States","Caracas, Venezuela","Ho Chi Minh City, Vietnam","Sanaa, Yemen"];
const places9F = ["Cotonou, Benin","Vila Velha, Brazil","Bandar Seri Begawan, Brunei","Yaoundé, Cameroon","Bangui, Central African Republic","Moundou, Chad","Bogotá, Colombia","Ouésso, Congo (Brazzaville)","Kisangani, Congo (Kinshasa)","San José, Costa Rica","Abidjan, Côte d'Ivoire","Esmeraldas, Ecuador","Malabo, Equatorial Guinea","Addis Ababa, Ethiopia","Palikir, Federated States of Micronesia"];
const places9S = ["Cayenne, French Guiana","Libreville, Gabon","Accra, Ghana","Conakry, Guinea","Georgetown, Guyana","Madurai, India","Medan, Indonesia","Meru, Kenya","Tarawa, Kiribati","Monrovia, Liberia","Kuala Lumpur, Malaysia","Male, Maldives","Majuro, Marshall Islands","Lagos, Nigeria","Ngerulmud, Palau"];
const places9T = ["Panama City, Panama","Puerto Princesa, Philippines","São Tomé, Sao Tome And Principe","Freetown, Sierra Leone","Singapore, Singapore","Mogadishu, Somalia","Juba, South Sudan","Colombo, Sri Lanka","Paramaribo, Suriname","Ko Samui, Thailand","Lomé, Togo","Kampala, Uganda","Ciudad Guayana, Venezuela","Cà Mau, Vietnam"];
const places10F = ["Luanda, Angola","Fortaleza, Brazil","Manaus, Brazil","Bujumbura, Burundi","Brazzaville, Congo (Brazzaville)","Kinshasa, Congo (Kinshasa)","Quito, Ecuador","Port-Gentil, Gabon","Denpasar, Indonesia"];
const places10S = ["Jakarta, Indonesia","Mombasa, Kenya","Nairobi, Kenya","Karonga, Malawi","Yaren, Nauru","Port Moresby, Papua New Guinea","Trujillo, Peru","Kigali, Rwanda","Georgetown, Saint Helena, Ascension, And Tristan Da Cunha"];
const places10T = ["Victoria, Seychelles","Honiara, Solomon Islands","Kismaayo, Somalia","Dar es Salaam, Tanzania","Dili, Timor-Leste","Funafuti, Tuvalu","Mbarara, Uganda","Kashikishi, Zambia"];
const places11F = ["Pago Pago, American Samoa","Lubango, Angola","Cairns, Australia","Darwin, Australia","La Paz, Bolivia","Maun, Botswana","Belo Horizonte, Brazil","Brasília, Brazil","Salvador, Brazil","Arica, Chile","Flying Fish Cove, Christmas Island"];
const places11S = ["Moroni, Comoros","Lubumbashi, Congo (Kinshasa)","Nadi, Fiji","Papeete, French Polynesia","Kupang, Indonesia","Antananarivo, Madagascar","Blantyre, Malawi","Lilongwe, Malawi","Mamoudzou, Mayotte","Nampula, Mozambique","Rundu, Namibia"];
const places11T = ["Alofi, Niue","Alotau, Papua New Guinea","Lima, Peru","Jamestown, Saint Helena, Ascension, And Tristan Da Cunha","Apia, Samoa","Songea, Tanzania","Port-Vila, Vanuatu","Mata-Utu, Wallis And Futuna","Lusaka, Zambia","Harare, Zimbabwe"];
const places12F = ["San Miguel de Tucumán, Argentina","Brisbane, Australia","Tarija, Bolivia","Gaborone, Botswana","Rio de Janeiro, Brazil","São Paulo, Brazil","Antofagasta, Chile","Avarua, Cook Islands"];
const places12S = ["Maseru, Lesotho","Fianarantsoa, Madagascar","Port Louis, Mauritius","Maputo, Mozambique","Windhoek, Namibia","Nouméa, New Caledonia","Kingston, Norfolk Island","Asunción, Paraguay"];
const places12T = ["Adamstown, Pitcairn Islands","Saint-Denis, Reunion","Durban, South Africa","Johannesburg, South Africa","Manzini, Swaziland","Nuku‘alofa, Tonga","Bulawayo, Zimbabwe"];
const places13F = ["Buenos Aires, Argentina","Córdoba, Argentina","Adelaide, Australia","Melbourne, Australia","Perth, Australia"];
const places13S = ["Sydney, Australia","Porto Alegre, Brazil","Puente Alto, Chile","Santiago, Chile","Auckland, New Zealand"];
const places13T = ["Hamilton, New Zealand","Edinburgh of the Seven Seas, Saint Helena, Ascension, And Tristan Da Cunha","Cape Town, South Africa","Port Elizabeth, South Africa","Montevideo, Uruguay"];
const places14F = ["Comodoro Rivadavia, Argentina","Hobart, Australia","Osorno, Chile"];
const places14S = ["Puerto Montt, Chile","Christchurch, New Zealand","Dunedin, New Zealand"];
const places14T = ["Invercargill, New Zealand","Wellington, New Zealand"];
const places15F = ["Río Gallegos, Argentina","Ushuaia, Argentina"];
const places15S = ["Puerto Williams, Chile","Punta Arenas, Chile"];
const places15T = ["Stanley, Falkland Islands","King Edward Point, South Georgia And South Sandwich Islands"];
const places16F = ["Adelie Land, Antarctica"];
const places16S = ["Greenwich Island, Antarctica"];
const places16T = ["King George Island, Antarctica"];
const places17F = ["Brunt Ice Shelf, Antarctica"];
const places17S = ["Queen Maud Land, Antarctica"];
const places17T = ["Ross Island, Antarctica"];
const places18F = ["South Pole, Antarctica"];
const places18S = [""];
const places18T = [""];

let placesObj = {places1F, places1S, places1T, places2F, places2S, places2T, places3F, places3S, places3T, places4F, places4S, places4T, places5F, places5S, places5T, places6F, places6S, places6T, places7F, places7S, places7T, places8F, places8S, places8T, places9F, places9S, places9T, places10F, places10S, places10T, places11F, places11S, places11T, places12F, places12S, places12T, places13F, places13S, places13T, places14F, places14S, places14T, places15F, places15S, places15T, places16F, places16S, places16T, places17F, places17S, places17T, places18F, places18S, places18T};

function Latitude() {
  
  const linkURL = window.location.href;
  const [isPrevDisabled, setIsPrevDisabled] = React.useState(true);
  const [isNextDisabled, setIsNextDisabled] = React.useState(false);

  const prevRange = (e) => {
    e.preventDefault();
    const places1 = document.getElementById("places1");
    const places2 = document.getElementById("places2");
    const places3 = document.getElementById("places3");
    const checkboxSlider = document.getElementById("checkboxSlider");
    const prevRange = curRange - 1;
    const currAct = "lat" + curRange + "Act";
    const prevAct = "lat" + prevRange + "Act";
    document.getElementById(currAct).classList.add("hidden");
    document.getElementById(prevAct).classList.remove("hidden");
    if (curRange === 2){
      setIsPrevDisabled(!isPrevDisabled);
    }
    if (curRange === 18){
      setIsNextDisabled(!isNextDisabled);
    }
    curRange--;
    const rangeCheck = yesArray.includes(curRange);
    if (rangeCheck === true) {
      checkboxSlider.checked = true;
    }
    else {
      checkboxSlider.checked = false;
    }
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const key1 = "places" + curRange + "F";
    const key2 = "places" + curRange + "S";
    const key3 = "places" + curRange + "T";
    const lookup1 = placesObj[key1];
    const lookup2 = placesObj[key2];
    const lookup3 = placesObj[key3];
    lookup1.forEach(function(el){
       places1.innerHTML = text1+= el + '</br>';
     });
    lookup2.forEach(function(el){
       places2.innerHTML = text2+= el + '</br>';
     });
    lookup3.forEach(function(el){
         places3.innerHTML = text3+= el + '</br>';
      });
    return true;
  }

  const nextRange = (e) => {
    e.preventDefault();
    const places1 = document.getElementById("places1");
    const places2 = document.getElementById("places2");
    const places3 = document.getElementById("places3");
    const checkboxSlider = document.getElementById("checkboxSlider");
    const nextRange = curRange + 1;
    const currAct = "lat" + curRange + "Act";
    const nextAct = "lat" + nextRange + "Act";
    document.getElementById(currAct).classList.add("hidden");
    document.getElementById(nextAct).classList.remove("hidden");
    if (curRange === 1){
      setIsPrevDisabled(!isPrevDisabled);
    }
    if (curRange === 17){
      setIsNextDisabled(!isNextDisabled);
    }
    curRange++;
    const rangeCheck = yesArray.includes(curRange);
    if (rangeCheck === true) {
      checkboxSlider.checked = true;
    }
    else {
      checkboxSlider.checked = false;
    }
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const key1 = "places" + curRange + "F";
    const key2 = "places" + curRange + "S";
    const key3 = "places" + curRange + "T";
    const lookup1 = placesObj[key1];
    const lookup2 = placesObj[key2];
    const lookup3 = placesObj[key3];
    lookup1.forEach(function(el){
       places1.innerHTML = text1+= el + '</br>';
     });
    lookup2.forEach(function(el){
       places2.innerHTML = text2+= el + '</br>';
     });
    lookup3.forEach(function(el){
         places3.innerHTML = text3+= el + '</br>';
      });
    return true;
  }

  const checkYes = (e) => {
    const checkboxSlider = document.getElementById("checkboxSlider");
    const resultText = document.getElementById("resultText");
    if (checkboxSlider.checked === true){
      totalYes++;
      const rangeID = 'lat' + curRange + 'Yes';
      document.getElementById(rangeID).classList.remove("hidden");
      yesArray.push(curRange);
      let x = Math.round(totalYes/18*100);
      resultText.innerHTML = "You have been to " + x + "% of Latitudinal ranges";
      // console.log("totalYes = ", totalYes);
      return true;
    }
    else {
      totalYes--;
      const rangeID = 'lat' + curRange + 'Yes';
      document.getElementById(rangeID).classList.add("hidden");
      const index = yesArray.indexOf(curRange);
      if (index > -1) { // only splice array when item is found
        yesArray.splice(index, 1); // 2nd parameter means remove one item only
      }
      let x = Math.round(totalYes/18*100);
      resultText.innerHTML = "You have been to " + x + "% of Latitudinal ranges";
      // console.log("totalYes = ", totalYes);
      return true
    }
  }

    return (
      <div id="mainContent">
        <PageIntro pageTitle="Latitude" output="meta"/>
        <div id="latSection1" className="pageSection">
            <div className="row">
              <div className="col-md-9">
                <div id="pageIntrolat">
                  <PageIntro pageTitle="Latitude" output="intro"/>
                </div>
                <div className="latPanel">
                <div className="resultRow">
                    <h2 id="resultText">You have been to 0%</h2>
                </div>
                <div className="latImages">
                  <img className="latMap img-fluid mx-auto " id="latBackMap" alt="map background" src={latMap} />
                  <img className="latMapOverlay img-fluid mx-auto" id="lat1Act" alt="active latitude range 1" src={lat1Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 2" id="lat2Act" src={lat2Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 3" id="lat3Act" src={lat3Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 4" id="lat4Act" src={lat4Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 5" id="lat5Act" src={lat5Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 6" id="lat6Act" src={lat6Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 7" id="lat7Act" src={lat7Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 8" id="lat8Act" src={lat8Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 9" id="lat9Act" src={lat9Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 10" id="lat10Act" src={lat10Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 11" id="lat11Act" src={lat11Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 12" id="lat12Act" src={lat12Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 13" id="lat13Act" src={lat13Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 14" id="lat14Act" src={lat14Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 15" id="lat15Act" src={lat15Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 16" id="lat16Act" src={lat16Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 17" id="lat17Act" src={lat17Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="active latitude range 18" id="lat18Act" src={lat18Act} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 1" id="lat1Yes" src={lat1Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 2" id="lat2Yes" src={lat2Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 3" id="lat3Yes" src={lat3Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 4" id="lat4Yes" src={lat4Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 5" id="lat5Yes" src={lat5Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 6" id="lat6Yes" src={lat6Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 7" id="lat7Yes" src={lat7Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 8" id="lat8Yes" src={lat8Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 9" id="lat9Yes" src={lat9Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 10" id="lat10Yes" src={lat10Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 11" id="lat11Yes" src={lat11Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 12" id="lat12Yes" src={lat12Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 13" id="lat13Yes" src={lat13Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 14" id="lat14Yes" src={lat14Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 15" id="lat15Yes" src={lat15Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 16" id="lat16Yes" src={lat16Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 17" id="lat17Yes" src={lat17Yes} />
                  <img className="latMapOverlay hidden img-fluid mx-auto" alt="confirmed latitude range 18" id="lat18Yes" src={lat18Yes} />
                </div>
                <div className="latControls">
                  <h3>Have you been to this latitudinal range?</h3>
                  <div className="controlBlock">
                    <div className="container">
                      <div className="row">
                        <div className="col-3">
                          <button className="btn btn-outline-teal btn-sm  rangeButton" type="button" onClick={prevRange} disabled={isPrevDisabled}>Previous</button>
                        </div>
                        <div className="col-6">
                          <div className="sliderControl">
                            <div className="row">
                              <div className="col-4">
                                <p className="sliderLabel" id="noLabel">NO</p>
                              </div>
                              <div className="col-4">
                                <label className="switch">
                                  <input type="checkbox" id="checkboxSlider" onClick={checkYes}></input>
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="col-4">
                                <p className="sliderLabel" id="yesLabel">YES</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <button className="btn btn-outline-teal btn-sm rangeButton" type="button" onClick={nextRange} disabled={isNextDisabled}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="latPlaces">
                  <h4>Here are some places that are within the range.</h4>
                  <div className="container placesList">
                    <div className="row">
                      <div className="col-md-4">
                        <div id="places1Div">
                        <p id="places1">
                          North Pole, Arctic
                        </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div id="places2Div">
                        <p id="places2">
                          Nord, Greenland
                        </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div id="places3Div">
                        <p id="places3">
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="affilAd adLand">
                <PageIntro pageTitle="Latitude" output="advert"/>
                </div>
                <div className="shareButtonsBottom">
            <div className="shareText">
              <p className="shareP">SHARE:</p>
            </div>
            <div className="shareIcon" id="shareFacebook">
              <FacebookShareButton 
                url={linkURL}
                quote={"The Ordinary Tourist - We help you explore the world"}
                hashtag="#theordinarytourist"
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
              </FacebookShareButton>
            </div>
            <div className="shareIcon" id="shareTwitter">
              <TwitterShareButton
                url={linkURL}
                title={"Have you been to all the Latitudes?"}
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
<path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
	S18.6,0,12,0z M5.7,6.1l4.9,6.5L5.7,18h1.1l4.3-4.7l3.5,4.7h3.8L13.2,11l4.6-5h-1.1l-4,4.3L9.5,6.1H5.7z M7.3,6.9h1.7l7.7,10.2H15
	L7.3,6.9z"/>
</svg>
              </TwitterShareButton>
            </div>
          </div>
                <div className="pageLinks">
                  <Link to={'../fun'}>View more fun games</Link>
                </div>
              </div>
              <div className="col-md-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
export default Latitude;
