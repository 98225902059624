import React from 'react';
import { Link } from 'react-router-dom';
import PageIntro from '../components/PageIntro';
import DisplayAdvert from '../components/DisplayAdvert'; 
import {FacebookShareButton, TwitterShareButton} from "react-share";

import longMap from '../images/long/mapBack.png';
import long1Act from '../images/long/long1Act.png';
import long2Act from '../images/long/long2Act.png';
import long3Act from '../images/long/long3Act.png';
import long4Act from '../images/long/long4Act.png';
import long5Act from '../images/long/long5Act.png';
import long6Act from '../images/long/long6Act.png';
import long7Act from '../images/long/long7Act.png';
import long8Act from '../images/long/long8Act.png';
import long9Act from '../images/long/long9Act.png';
import long10Act from '../images/long/long10Act.png';
import long11Act from '../images/long/long11Act.png';
import long12Act from '../images/long/long12Act.png';
import long13Act from '../images/long/long13Act.png';
import long14Act from '../images/long/long14Act.png';
import long15Act from '../images/long/long15Act.png';
import long16Act from '../images/long/long16Act.png';
import long17Act from '../images/long/long17Act.png';
import long18Act from '../images/long/long18Act.png';
import long19Act from '../images/long/long19Act.png';
import long20Act from '../images/long/long20Act.png';
import long21Act from '../images/long/long21Act.png';
import long22Act from '../images/long/long22Act.png';
import long23Act from '../images/long/long23Act.png';
import long24Act from '../images/long/long24Act.png';
import long25Act from '../images/long/long25Act.png';
import long26Act from '../images/long/long26Act.png';
import long27Act from '../images/long/long27Act.png';
import long28Act from '../images/long/long28Act.png';
import long29Act from '../images/long/long29Act.png';
import long30Act from '../images/long/long30Act.png';
import long31Act from '../images/long/long31Act.png';
import long32Act from '../images/long/long32Act.png';
import long33Act from '../images/long/long33Act.png';
import long34Act from '../images/long/long34Act.png';
import long35Act from '../images/long/long35Act.png';
import long36Act from '../images/long/long36Act.png';
import long1Yes from '../images/long/long1Yes.png';
import long2Yes from '../images/long/long2Yes.png';
import long3Yes from '../images/long/long3Yes.png';
import long4Yes from '../images/long/long4Yes.png';
import long5Yes from '../images/long/long5Yes.png';
import long6Yes from '../images/long/long6Yes.png';
import long7Yes from '../images/long/long7Yes.png';
import long8Yes from '../images/long/long8Yes.png';
import long9Yes from '../images/long/long9Yes.png';
import long10Yes from '../images/long/long10Yes.png';
import long11Yes from '../images/long/long11Yes.png';
import long12Yes from '../images/long/long12Yes.png';
import long13Yes from '../images/long/long13Yes.png';
import long14Yes from '../images/long/long14Yes.png';
import long15Yes from '../images/long/long15Yes.png';
import long16Yes from '../images/long/long16Yes.png';
import long17Yes from '../images/long/long17Yes.png';
import long18Yes from '../images/long/long18Yes.png';
import long19Yes from '../images/long/long19Yes.png';
import long20Yes from '../images/long/long20Yes.png';
import long21Yes from '../images/long/long21Yes.png';
import long22Yes from '../images/long/long22Yes.png';
import long23Yes from '../images/long/long23Yes.png';
import long24Yes from '../images/long/long24Yes.png';
import long25Yes from '../images/long/long25Yes.png';
import long26Yes from '../images/long/long26Yes.png';
import long27Yes from '../images/long/long27Yes.png';
import long28Yes from '../images/long/long28Yes.png';
import long29Yes from '../images/long/long29Yes.png';
import long30Yes from '../images/long/long30Yes.png';
import long31Yes from '../images/long/long31Yes.png';
import long32Yes from '../images/long/long32Yes.png';
import long33Yes from '../images/long/long33Yes.png';
import long34Yes from '../images/long/long34Yes.png';
import long35Yes from '../images/long/long35Yes.png';
import long36Yes from '../images/long/long36Yes.png';

let curRange = 1;
let yesArray = [];
let totalYes = 0;

const places1F = ["Pago Pago, American Samoa", "Chatham Is, New Zealand"];
const places1S = ["Egvekinot, Russia","Apia, Samoa"];
const places1T = ["Nuku‘alofa, Tonga","Mata-Utu, Wallis And Futuna"];
const places2F = ["Alofi, Niue"];
const places2S = ["Uelen, Russia"];
const places2T = ["Bethel, United States"];
const places3F = ["Avarua, Cook Islands"];
const places3S = ["Hilo, United States"];
const places3T = ["Honolulu, United States"];
const places4F = ["Papeete, French Polynesia"];
const places4S = ["Anchorage, United States"];
const places4T = ["Fairbanks, United States"];
const places5F = ["Prince Rupert, Canada","Whitehorse, Canada"];
const places5S = ["Adamstown, Pitcairn Islands","Juneau, United States"];
const places5T = ["Sitka, United States"];
const places6F = ["Surrey, Canada","Vancouver, Canada"];
const places6S = ["Portland, United States","Sacramento, United States"];
const places6T = ["San Francisco, United States","Seattle, United States"];
const places7F = ["Calgary, Canada","Edmonton, Canada","Mexicali, Mexico"];
const places7S = ["Tijuana, Mexico","Las Vegas, United States","Los Angeles, United States"];
const places7T = ["Phoenix, United States","San Diego, United States"];
const places8F = ["Saskatoon, Canada","Guadalajara, Mexico","León de los Aldama, Mexico"];
const places8S = ["Monterrey, Mexico","Albuquerque, United States"];
const places8T = ["Denver, United States","El Paso, United States"];
const places9F = ["Winnipeg, Canada","Santa Cruz, Ecuador","Guatemala City, Guatemala","Acapulco de Juárez, Mexico"];
const places9S = ["Mexico City, Mexico","Villahermosa, Mexico","Dallas, United States"];
const places9T = ["Houston, United States","Kansas City, United States","Minneapolis, United States"];
const places10F = ["Belize City, Belize","Kitchener, Canada","George Town, Cayman Islands","San José, Costa Rica","Havana, Cuba","Manta, Ecuador", "San Salvador, El Salvador"];
const places10S = ["Jutiapa, Guatemala","San Pedro Sula, Honduras","Tegucigalpa, Honduras","Cancún, Mexico","Mérida, Mexico","Managua, Nicaragua"];
const places10T = ["David, Panama","Piura, Peru","Atlanta, United States","Chicago, United States","Miami, United States", "New Orleans, United States"];
const places11F = ["Oranjestad, Aruba","Montréal, Canada","Quebec City, Canada","Toronto, Canada","Maipú, Chile","Santiago, Chile","Bogotá, Colombia","Medellín, Colombia"];
const places11S = ["Timbío, Colombia","Santiago de Cuba, Cuba","Santiago, Dominican Republic","Guayaquil, Ecuador","Quito, Ecuador","Port-au-Prince, Haiti","Kingston, Jamaica","Panama City, Panama"];
const places11T = ["Lima, Peru","Nassau, The Bahamas","Grand Turk, Turks And Caicos Islands","Boston, United States","New York, United States","Philadelphia, United States","Washington, United States","Maracaibo, Venezuela"];
const places12F = ["The Valley, Anguilla","Saint John’s, Antigua And Barbuda","Córdoba, Argentina","Hamilton, Bermuda","La Paz, Bolivia","Kralendijk, Bonaire","Manaus, Brazil","Road Town, British Virgin Islands","Halifax, Canada","Calama, Chile"];
const places12S = ["Willemstad, Curaçao","Roseau, Dominica","Santo Domingo, Dominican Republic","Saint George’s, Grenada","Pointe-à-Pitre, Guadeloupe","Fort-de-France, Martinique","Brades, Montserrat","Puerto Maldonado, Peru","San Juan, Puerto Rico","Gustavia, Saint Barthelemy"];
const places12T = ["Basseterre, Saint Kitts And Nevis","Castries, Saint Lucia","Marigot, Saint Martin","Kingstown, Saint Vincent And The Grenadines","Philipsburg, Sint Maarten","Port of Spain, Trinidad And Tobago","Charlotte Amalie, U.S. Virgin Islands","Augusta, United States","Caracas, Venezuela"];
const places13F = ["Buenos Aires, Argentina","Mar del Plata, Argentina","Bridgetown, Barbados","Puerto Suárez, Bolivia","St. John's, Canada"];
const places13S = ["Stanley, Falkland Islands (Islas Malvinas)","Cayenne, French Guiana","Nuuk, Greenland","Georgetown, Guyana"];
const places13T = ["Asunción, Paraguay","Saint-Pierre, Saint Pierre And Miquelon","Paramaribo, Suriname","Montevideo, Uruguay"];
const places14F = ["Belo Horizonte, Brazil","Brasília, Brazil"];
const places14S = ["Rio de Janeiro, Brazil","São Paulo, Brazil"];
const places14T = ["Qaqortoq, Greenland"];
const places15F = ["Fortaleza, Brazil","Recife, Brazil"];
const places15S = ["Salvador, Brazil"];
const places15T = ["King Edward Point, South Georgia And South Sandwich Islands"];
const places16F = ["Praia, Cabo Verde"];
const places16S = ["Reykjavík, Iceland"];
const places16T = ["Ponta Delgada, Portugal"];
const places17F = ["Conakry, Guinea","Bissau, Guinea-Bissau","Monrovia, Liberia","Nouakchott, Mauritania"];
const places17S = ["Laâyoune, Morocco","Funchal, Portugal","Georgetown, Saint Helena, Ascension and Tristan Da Cunha","Dakar, Senegal"];
const places17T = ["Freetown, Sierra Leone","Las Palmas, Spain","Banjul, The Gambia"];
const places18F = ["Oran, Algeria","Ouagadougou, Burkina Faso","Abidjan, Côte d'Ivoire","Tórshavn, Faroe Islands","Bordeaux, France","Nantes, France","Accra, Ghana","Gibraltar, Gibraltar"];
const places18S = ["Dublin, Ireland","Douglas, Isle Of Man","Saint Helier, Jersey","Bamako, Mali","Néma, Mauritania","Casablanca, Morocco","Lisbon, Portugal","Jamestown, Saint Helena, Ascension and Tristan Da Cunha"];
const places18T = ["Madrid, Spain","Sevilla, Spain","Valencia, Spain","Birmingham, United Kingdom","Edinburgh, United Kingdom","London, United Kingdom","Manchester, United Kingdom"];
const places19F = ["Algiers, Algeria","Andorra la Vella, Andorra","Brussels, Belgium","Cotonou, Benin","Douala, Cameroon","Aalborg, Denmark","Malabo, Equatorial Guinea","Marseille, France","Paris, France","Libreville, Gabon"];
const places19S = ["Cologne, Germany","Frankfurt, Germany","Milan, Italy","Vaduz, Liechtenstein","Luxembourg, Luxembourg","Monaco, Monaco","Amsterdam, Netherlands","Niamey, Niger","Abuja, Nigeria","Kano, Nigeria"];
const places19T = ["Lagos, Nigeria","Bergen, Norway","São Tomé, Sao Tome And Principe","Barcelona, Spain","Ibiza, Spain","Geneva, Switzerland","Lomé, Togo","Sidi Bouzid, Tunisia","Cambridge, United Kingdom","Ipswich, United Kingdom"];
const places20F = ["Tirana, Albania","Luanda, Angola","Vienna, Austria","Sarajevo, Bosnia And Herzegovina","Yaoundé, Cameroon","Bangui, Central African Republic","N’Djamena, Chad","Brazzaville, Congo (Brazzaville)","Kinshasa, Congo (Kinshasa)","Zagreb, Croatia"];
const places20S = ["Prague, Czechia","Copenhagen, Denmark","Berlin, Germany","Munich, Germany","Budapest, Hungary","Naples, Italy","Rome, Italy","Tripoli, Libya","Valletta, Malta","Podgorica, Montenegro"];
const places20T = ["Windhoek, Namibia","Maiduguri, Nigeria","Oslo, Norway","Kraków, Poland","San Marino, San Marino","Bratislava, Slovakia","Ljubljana, Slovenia","Stockholm, Sweden","Tunis, Tunisia","Vatican City, Vatican City"];
const places21F = ["Minsk, Belarus","Gaborone, Botswana","Sofia, Bulgaria","Bujumbura, Burundi","Kananga, Congo (Kinshasa)","Alexandria, Egypt","Tallinn, Estonia","Helsinki, Finland"];
const places21S = ["Athens, Greece","Pristina, Kosovo","Riga, Latvia","Maseru, Lesotho","Benghazi, Libya","Vilnius, Lithuania","Skopje, Macedonia","Chisinau, Moldova"];
const places21T = ["Warsaw, Poland","Bucharest, Romania","Belgrade, Serbia","Johannesburg, South Africa","Istanbul, Turkey","Lviv, Ukraine","Lusaka, Zambia","Bulawayo, Zimbabwe"];
const places22F = ["Homyel’, Belarus","Nicosia, Cyprus","Cairo, Egypt","Asmara, Eritrea","Addis Ababa, Ethiopia","Gaza, Gaza Strip","Jerusalem, Israel","Amman, Jordan","Mombasa, Kenya","Nairobi, Kenya"];
const places22S = ["Beirut, Lebanon","Blantyre, Malawi","Maputo, Mozambique","Moscow, Russia","Saint Petersburg, Russia","Kigali, Rwanda","Jeddah, Saudi Arabia","Mecca, Saudi Arabia","Durban, South Africa"];
const places22T = ["Juba, South Sudan","Khartoum, Sudan","Damascus, Syria","Dar es Salaam, Tanzania","Kampala, Uganda","Kharkiv, Ukraine","Kyiv, Ukraine","Al Quds, West Bank","Harare, Zimbabwe"];
const places23F = ["Yerevan, Armenia","Baku, Azerbaijan","Moroni, Comoros","Djibouti, Djibouti","Tbilisi, Georgia","Ahvāz, Iran","Tabrīz, Iran","Al Başrah, Iraq"];
const places23S = ["Baghdad, Iraq","Mosul, Iraq","Kuwait City, Kuwait","Antananarivo, Madagascar","Mamoudzou, Mayotte","Cidade de Nacala, Mozambique","Kazan, Russia"];
const places23T = ["Nizhniy Novgorod, Russia","Volgograd, Russia","Riyadh, Saudi Arabia","Mogadishu, Somalia","Dayr az Zawr, Syria","Van, Turkey","Sanaa, Yemen"];
const places24F = ["Manama, Bahrain","Mashhad, Iran","Tehran, Iran","Port Louis, Mauritius","Muscat, Oman","Doha, Qatar"];
const places24S = ["Saint-Denis, Reunion","Perm, Russia","Samara, Russia","Ufa, Russia","Ad Dammām, Saudi Arabia"];
const places24T = ["Victoria, Seychelles","Ashgabat, Turkmenistan","Abu Dhabi, United Arab Emirates","Dubai, United Arab Emirates","Sharjah, United Arab Emirates"];
const places25F = ["Kandahār, Afghanistan","Zāhedān, Iran","Shymkent, Kazakhstan","Hyderabad City, Pakistan"];
const places25S = ["Karachi, Pakistan","Chelyabinsk, Russia","Yekaterinburg, Russia"];
const places25T = ["Dushanbe, Tajikistan","Türkmenabat, Turkmenistan","Tashkent, Uzbekistan"];
const places26F = ["Jalālābād, Afghanistan","Ahmedabad, India","Bangalore, India","Delhi, India","Hyderābād, India","Mumbai, India"];
const places26S = ["Pune, India","Almaty, Kazakhstan","Bishkek, Kyrgyzstan","Male, Maldives","Faisalabad, Pakistan"];
const places26T = ["Lahore, Pakistan","Rawalpindi, Pakistan","Omsk, Russia","Colombo, Sri Lanka","Namangan, Uzbekistan"];
const places27F = ["Khulna, Bangladesh","Thimphu, Bhutan","Ürümqi, China","Allahābād, India"];
const places27S = ["Chennai, India","Kolkāta, India","Semey, Kazakhstan","Kathmandu, Nepal"];
const places27T = ["Novosibirsk, Russia","Tomsk, Russia","Kandy, Sri Lanka"];
const places28F = ["Chattogram, Bangladesh","Dhaka, Bangladesh","Jakar, Bhutan","Lhasa, China"];
const places28S = ["Guwāhāti, India","Medan, Indonesia","Rangoon, Myanmar"];
const places28T = ["Krasnoyarsk, Russia","Chiang Mai, Thailand","Ko Samui, Thailand"];
const places29F = ["Phnom Penh, Cambodia","Chengdu, China","Chongqing, China","Nanning, China","Xi’an, China","Flying Fish Cove, Christmas Island"];
const places29S = ["Bandung, Indonesia","Jakarta, Indonesia","Vientiane, Laos","Ipoh, Malaysia","Klang, Malaysia","Kuala Lumpur, Malaysia"];
const places29T = ["Ulaanbaatar, Mongolia","Irkutsk, Russia","Singapore, Singapore","Bangkok, Thailand","Hanoi, Vietnam","Ho Chi Minh City, Vietnam"];
const places30F = ["Perth, Australia","Bandar Seri Begawan, Brunei","Baoding, China","Beijing, China","Guangzhou, China","Handan, China","Linyi, China"];
const places30S = ["Nanyang, China","Shenzhen, China","Shijiazhuang, China","Tianjin, China","Zhengzhou, China","Hong Kong, Hong Kong","Denpasar, Indonesia"];
const places30T = ["Makassar, Indonesia","Surabaya, Indonesia","Macau, Macau","Kota Kinabalu, Malaysia","Choibalsan, Mongolia","Puerto Princesa, Philippines"];
const places31F = ["Changchun, China","Nantong, China","Shanghai, China","Yancheng, China"];
const places31S = ["Pyongyang, North Korea","Cebu City, Philippines","Manila, Philippines","Busan, South Korea"];
const places31T = ["Daegu, South Korea","Seoul, South Korea","Taipei, Taiwan","Dili, Timor-Leste"];
const places32F = ["Adelaide, Australia","Jiamusi, China","Sorong, Indonesia","Fukuoka, Japan"];
const places32S = ["Nagoya, Japan","Ōsaka, Japan","Tokyo, Japan"];
const places32T = ["Yokohama, Japan","Ngerulmud, Palau","Vladivostok, Russia"];
const places33F = ["Melbourne, Australia","Hagåtña, Guam","Sapporo, Japan"];
const places33S = ["Sendai, Japan","Capitol Hill, Northern Mariana Islands"];
const places33T = ["Port Moresby, Papua New Guinea","Yuzhno-Sakhalinsk, Russia"];
const places34F = ["Brisbane, Australia","Sydney, Australia"];
const places34S = ["Palikir, Federated States of Micronesia","Magadan, Russia"];
const places34T = ["Petropavlovsk-Kamchatskiy, Russia","Honiara, Solomon Islands"];
const places35F = ["Yaren, Nauru","Nouméa, New Caledonia"];
const places35S = ["Invercargill, New Zealand","Kingston, Norfolk Island"];
const places35T = ["Port-Vila, Vanuatu"];
const places36F = ["Nadi, Fiji","Tarawa, Kiribati","Majuro, Marshall Islands"];
const places36S = ["Auckland, New Zealand","Christchurch, New Zealand"];
const places36T = ["Wellington, New Zealand","Funafuti, Tuvalu"];
let placesObj = {places1F, places1S, places1T, places2F, places2S, places2T, places3F, places3S, places3T, places4F, places4S, places4T, places5F, places5S, places5T, places6F, places6S, places6T, places7F, places7S, places7T, places8F, places8S, places8T, places9F, places9S, places9T, places10F, places10S, places10T, places11F, places11S, places11T, places12F, places12S, places12T, places13F, places13S, places13T, places14F, places14S, places14T, places15F, places15S, places15T, places16F, places16S, places16T, places17F, places17S, places17T, places18F, places18S, places18T, places19F, places19S, places19T, places20F, places20S, places20T, places21F, places21S, places21T, places22F, places22S, places22T, places23F, places23S, places23T, places24F, places24S, places24T, places25F, places25S, places25T, places26F, places26S, places26T, places27F, places27S, places27T, places28F, places28S, places28T, places29F, places29S, places29T, places30F, places30S, places30T, places31F, places31S, places31T, places32F, places32S, places32T, places33F, places33S, places33T, places34F, places34S, places34T, places35F, places35S, places35T, places36F, places36S, places36T};

function Longitude() {
  const linkURL = window.location.href;
  const [isPrevDisabled, setIsPrevDisabled] = React.useState(true);
  const [isNextDisabled, setIsNextDisabled] = React.useState(false);

  const prevRange = (e) => {
    e.preventDefault();
    const places1 = document.getElementById("places1");
    const places2 = document.getElementById("places2");
    const places3 = document.getElementById("places3");
    const checkboxSlider = document.getElementById("checkboxSlider");
    const prevRange = curRange - 1;
    const currAct = "long" + curRange + "Act";
    const prevAct = "long" + prevRange + "Act";
    document.getElementById(currAct).classList.add("hidden");
    document.getElementById(prevAct).classList.remove("hidden");
    if (curRange === 2){
      setIsPrevDisabled(!isPrevDisabled);
    }
    if (curRange === 36){
      setIsNextDisabled(!isNextDisabled);
    }
    curRange--;
    const rangeCheck = yesArray.includes(curRange);
    if (rangeCheck === true) {
      checkboxSlider.checked = true;
    }
    else {
      checkboxSlider.checked = false;
    }
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const key1 = "places" + curRange + "F";
    const key2 = "places" + curRange + "S";
    const key3 = "places" + curRange + "T";
    const lookup1 = placesObj[key1];
    const lookup2 = placesObj[key2];
    const lookup3 = placesObj[key3];
    lookup1.forEach(function(el){
       places1.innerHTML = text1+= el + '</br>';
     });
    lookup2.forEach(function(el){
       places2.innerHTML = text2+= el + '</br>';
     });
    lookup3.forEach(function(el){
         places3.innerHTML = text3+= el + '</br>';
      });
    return true;
  }

  const nextRange = (e) => {
    e.preventDefault();
    const places1 = document.getElementById("places1");
    const places2 = document.getElementById("places2");
    const places3 = document.getElementById("places3");
    const checkboxSlider = document.getElementById("checkboxSlider");
    const nextRange = curRange + 1;
    const currAct = "long" + curRange + "Act";
    const nextAct = "long" + nextRange + "Act";
    document.getElementById(currAct).classList.add("hidden");
    document.getElementById(nextAct).classList.remove("hidden");
    if (curRange === 1){
      setIsPrevDisabled(!isPrevDisabled);
    }
    if (curRange === 35){
      setIsNextDisabled(!isNextDisabled);
    }
    curRange++;
    const rangeCheck = yesArray.includes(curRange);
    if (rangeCheck === true) {
      checkboxSlider.checked = true;
    }
    else {
      checkboxSlider.checked = false;
    }
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const key1 = "places" + curRange + "F";
    const key2 = "places" + curRange + "S";
    const key3 = "places" + curRange + "T";
    const lookup1 = placesObj[key1];
    const lookup2 = placesObj[key2];
    const lookup3 = placesObj[key3];
    lookup1.forEach(function(el){
       places1.innerHTML = text1+= el + '</br>';
     });
    lookup2.forEach(function(el){
       places2.innerHTML = text2+= el + '</br>';
     });
    lookup3.forEach(function(el){
         places3.innerHTML = text3+= el + '</br>';
      });
    return true;
  }

  const checkYes = (e) => {
    const checkboxSlider = document.getElementById("checkboxSlider");
    const resultText = document.getElementById("resultText");
    if (checkboxSlider.checked === true){
      totalYes++;
      const rangeID = 'long' + curRange + 'Yes';
      document.getElementById(rangeID).classList.remove("hidden");
      yesArray.push(curRange);
      let x = Math.round(totalYes/36*100);
      resultText.innerHTML = "You have been to " + x + "% of Longitudinal ranges";
      // console.log("totalYes = ", totalYes);
      return true;
    }
    else {
      totalYes--;
      const rangeID = 'long' + curRange + 'Yes';
      document.getElementById(rangeID).classList.add("hidden");
      const index = yesArray.indexOf(curRange);
      if (index > -1) { // only splice array when item is found
        yesArray.splice(index, 1); // 2nd parameter means remove one item only
      }
      let x = Math.round(totalYes/36*100);
      resultText.innerHTML = "You have been to " + x + "% of Longitudinal ranges";
      // console.log("totalYes = ", totalYes);
      return true
    }


  }

    return (
      <div id="mainContent">
        <PageIntro pageTitle="Longitude" output="meta"/>
        <div id="longSection1" className="pageSection">
            <div className="row">
              <div className="col-md-9">
                <div id="pageIntroLong">
                  <PageIntro pageTitle="Longitude" output="intro"/>
                </div>
                <div className="longPanel">
                <div className="resultRow">
                    <h2 id="resultText">You have been to 0%</h2>
                </div>
                <div className="longImages">
                  <img className="longMap img-fluid mx-auto " id="longBackMap" alt="map background" src={longMap} />
                  <img className="longMapOverlay img-fluid mx-auto" id="long1Act" alt="active longitude range 1" src={long1Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 2" id="long2Act" src={long2Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 3" id="long3Act" src={long3Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 4" id="long4Act" src={long4Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 5" id="long5Act" src={long5Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 6" id="long6Act" src={long6Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 7" id="long7Act" src={long7Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 8" id="long8Act" src={long8Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 9" id="long9Act" src={long9Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 10" id="long10Act" src={long10Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 11" id="long11Act" src={long11Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 12" id="long12Act" src={long12Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 13" id="long13Act" src={long13Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 14" id="long14Act" src={long14Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 15" id="long15Act" src={long15Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 16" id="long16Act" src={long16Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 17" id="long17Act" src={long17Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 18" id="long18Act" src={long18Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 19" id="long19Act" src={long19Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 20" id="long20Act" src={long20Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 21" id="long21Act" src={long21Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 22" id="long22Act" src={long22Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 23" id="long23Act" src={long23Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 24" id="long24Act" src={long24Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 25" id="long25Act" src={long25Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 26" id="long26Act" src={long26Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 27" id="long27Act" src={long27Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 28" id="long28Act" src={long28Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 29" id="long29Act" src={long29Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 30" id="long30Act" src={long30Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 31" id="long31Act" src={long31Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 32" id="long32Act" src={long32Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 33" id="long33Act" src={long33Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 34" id="long34Act" src={long34Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 35" id="long35Act" src={long35Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="active longitude range 36" id="long36Act" src={long36Act} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 1" id="long1Yes" src={long1Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 2" id="long2Yes" src={long2Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 3" id="long3Yes" src={long3Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 4" id="long4Yes" src={long4Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 5" id="long5Yes" src={long5Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 6" id="long6Yes" src={long6Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 7" id="long7Yes" src={long7Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 8" id="long8Yes" src={long8Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 9" id="long9Yes" src={long9Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 10" id="long10Yes" src={long10Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 11" id="long11Yes" src={long11Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 12" id="long12Yes" src={long12Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 13" id="long13Yes" src={long13Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 14" id="long14Yes" src={long14Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 15" id="long15Yes" src={long15Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 16" id="long16Yes" src={long16Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 17" id="long17Yes" src={long17Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 18" id="long18Yes" src={long18Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 19" id="long19Yes" src={long19Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 20" id="long20Yes" src={long20Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 21" id="long21Yes" src={long21Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 22" id="long22Yes" src={long22Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 23" id="long23Yes" src={long23Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 24" id="long24Yes" src={long24Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 25" id="long25Yes" src={long25Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 26" id="long26Yes" src={long26Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 27" id="long27Yes" src={long27Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 28" id="long28Yes" src={long28Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 29" id="long29Yes" src={long29Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 30" id="long30Yes" src={long30Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 31" id="long31Yes" src={long31Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 32" id="long32Yes" src={long32Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 33" id="long33Yes" src={long33Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 34" id="long34Yes" src={long34Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 35" id="long35Yes" src={long35Yes} />
                  <img className="longMapOverlay hidden img-fluid mx-auto" alt="confirmed longitude range 36" id="long36Yes" src={long36Yes} />
                </div>
                <div className="longControls">
                  <h3>Have you been to this longitudinal range?</h3>
                  <div className="controlBlock">
                    <div className="container">
                      <div className="row">
                        <div className="col-3">
                          <button className="btn btn-outline-primary btn-sm  rangeButton" type="button" onClick={prevRange} disabled={isPrevDisabled}>Previous</button>
                        </div>
                        <div className="col-6">
                          <div className="sliderControl">
                            <div className="row">
                              <div className="col-4">
                                <p className="sliderLabel" id="noLabel">NO</p>
                              </div>
                              <div className="col-4">
                                <label className="switch">
                                  <input type="checkbox" id="checkboxSlider" onClick={checkYes}></input>
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="col-4">
                                <p className="sliderLabel" id="yesLabel">YES</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <button className="btn btn-outline-primary btn-sm rangeButton" type="button" onClick={nextRange} disabled={isNextDisabled}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="longPlaces">
                  <h4>Here are some places that are within the range.</h4>
                  <div className="container placesList">
                    <div className="row">
                      <div className="col-md-4">
                        <div id="places1Div">
                        <p id="places1">
                          Pago Pago, American Samoa
                          <br></br>
                          Chatham Is, New Zealand
                          <br></br>
                        </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div id="places2Div">
                        <p id="places2">
                          Egvekinot, Russia
                          <br></br>
                          Apia, Samoa
                          <br></br>
                        </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div id="places3Div">
                        <p id="places3">
                          Nuku‘alofa, Tonga
                          <br></br>
                          Mata-Utu, Wallis And Futuna
                          <br></br>
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="affilAd adLand">
                <PageIntro pageTitle="Longitude" output="advert"/>
                </div>
                <div className="shareButtonsBottom">
            <div className="shareText">
              <p className="shareP">SHARE:</p>
            </div>
            <div className="shareIcon" id="shareFacebook">
              <FacebookShareButton 
                url={linkURL}
                quote={"The Ordinary Tourist - We help you explore the world"}
                hashtag="#theordinarytourist"
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
              </FacebookShareButton>
            </div>
            <div className="shareIcon" id="shareTwitter">
              <TwitterShareButton
                url={linkURL}
                title={"Have you been to all the Longitudes?"}
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
<path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
	S18.6,0,12,0z M5.7,6.1l4.9,6.5L5.7,18h1.1l4.3-4.7l3.5,4.7h3.8L13.2,11l4.6-5h-1.1l-4,4.3L9.5,6.1H5.7z M7.3,6.9h1.7l7.7,10.2H15
	L7.3,6.9z"/>
</svg>
              </TwitterShareButton>
            </div>
          </div>
                <div className="pageLinks">
                  <Link to={'../fun'}>View more fun games</Link>
                </div>
              </div>
              <div className="col-md-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
export default Longitude;
