import React from 'react';
import { Link } from 'react-router-dom';
import useFiveBlogSummaries from '../custom-hooks/useFiveBlogSummaries';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import moment from 'moment';
import ReactHtmlParser from 'html-react-parser';

function FivePosts (props) {
  const skip = props.skip;
  const sortOrder = props.sort;
  const [summaries, isLoading] = useFiveBlogSummaries(skip,sortOrder);

  if (isLoading === true) {
    return <p>Loading...</p>;
  }
  console.log("summaries = ", summaries);

  const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
            `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
    },
};

  // const rawRichTextField = authors[0].content;
  // const outputHTML = documentToHtmlString(rawRichTextField, options);

//fields.title,fields.summary,fields.rollUpImage,fields.tags,fields.publishedDate

  return (
      <div>
      {summaries.map(summaryPost => {
        const updated = moment(summaryPost.fields.publishedDate, "YYYY-MM-DD").format("DD MMMM YYYY");
        const rawRichTextField = summaryPost.fields.summary;
        const outputHTML = documentToHtmlString(rawRichTextField, options);
        const linkURL = "../blog/" + summaryPost.fields.slug;
        // console.log(summaryPost.fields.title + updated);
      return (
        <div className="blogListBlog" key={summaryPost.fields.title}>
          <div className="row">
            <div className="col-3 blogListCol">
              <Link to={linkURL} reloadDocument className="blogListLink">
                <img className="blogListImage roundedImage img-fluid mx-auto" src={summaryPost.fields.rollUpImage.fields.file.url} alt={summaryPost.fields.rollUpImage.fields.title} />
              </Link>
            </div>
            <div className="col-9 blogListCol">
              <div className="summaryBlock" id={summaryPost.fields.title}>
                <Link to={linkURL} reloadDocument className="blogListLink"><h2>{summaryPost.fields.title}</h2></Link>
                <div className="summary">
                  {ReactHtmlParser(outputHTML)}
                </div>
                <p className="published">Published: {updated}</p>
                <div className="tags">
                  {summaryPost.fields.tags.map(tag => {
                    const tagURL = "../search?prod_ATLAS%5BrefinementList%5D%5Btags%5D%5B0%5D=" + tag;
                    return (
                      <Link to={tagURL} reloadDocument className="tagLink"><span className="badge bg-pill bg-primary" key={tag}>{tag}</span></Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })}
      </div>
    );
}

export default FivePosts;
