import React from 'react';
import { Link } from 'react-router-dom';
import PageIntro from '../components/PageIntro';
import DisplayAdvert from '../components/DisplayAdvert';  
import {FacebookShareButton, TwitterShareButton} from "react-share";

import stateMap from '../images/state/mapStatesBack.png';
import state1Act from '../images/state/state1Act.png';
import state2Act from '../images/state/state2Act.png';
import state3Act from '../images/state/state3Act.png';
import state4Act from '../images/state/state4Act.png';
import state5Act from '../images/state/state5Act.png';
import state6Act from '../images/state/state6Act.png';
import state7Act from '../images/state/state7Act.png';
import state8Act from '../images/state/state8Act.png';
import state9Act from '../images/state/state9Act.png';
import state10Act from '../images/state/state10Act.png';
import state11Act from '../images/state/state11Act.png';
import state12Act from '../images/state/state12Act.png';
import state13Act from '../images/state/state13Act.png';
import state14Act from '../images/state/state14Act.png';
import state15Act from '../images/state/state15Act.png';
import state16Act from '../images/state/state16Act.png';
import state17Act from '../images/state/state17Act.png';
import state18Act from '../images/state/state18Act.png';
import state19Act from '../images/state/state19Act.png';
import state20Act from '../images/state/state20Act.png';
import state21Act from '../images/state/state21Act.png';
import state22Act from '../images/state/state22Act.png';
import state23Act from '../images/state/state23Act.png';
import state24Act from '../images/state/state24Act.png';
import state25Act from '../images/state/state25Act.png';
import state26Act from '../images/state/state26Act.png';
import state27Act from '../images/state/state27Act.png';
import state28Act from '../images/state/state28Act.png';
import state29Act from '../images/state/state29Act.png';
import state30Act from '../images/state/state30Act.png';
import state31Act from '../images/state/state31Act.png';
import state32Act from '../images/state/state32Act.png';
import state33Act from '../images/state/state33Act.png';
import state34Act from '../images/state/state34Act.png';
import state35Act from '../images/state/state35Act.png';
import state36Act from '../images/state/state36Act.png';
import state37Act from '../images/state/state37Act.png';
import state38Act from '../images/state/state38Act.png';
import state39Act from '../images/state/state39Act.png';
import state40Act from '../images/state/state40Act.png';
import state41Act from '../images/state/state41Act.png';
import state42Act from '../images/state/state42Act.png';
import state43Act from '../images/state/state43Act.png';
import state44Act from '../images/state/state44Act.png';
import state45Act from '../images/state/state45Act.png';
import state46Act from '../images/state/state46Act.png';
import state47Act from '../images/state/state47Act.png';
import state48Act from '../images/state/state48Act.png';
import state49Act from '../images/state/state49Act.png';
import state50Act from '../images/state/state50Act.png';
import state51Act from '../images/state/state51Act.png';
import state1Yes from '../images/state/state1Yes.png';
import state2Yes from '../images/state/state2Yes.png';
import state3Yes from '../images/state/state3Yes.png';
import state4Yes from '../images/state/state4Yes.png';
import state5Yes from '../images/state/state5Yes.png';
import state6Yes from '../images/state/state6Yes.png';
import state7Yes from '../images/state/state7Yes.png';
import state8Yes from '../images/state/state8Yes.png';
import state9Yes from '../images/state/state9Yes.png';
import state10Yes from '../images/state/state10Yes.png';
import state11Yes from '../images/state/state11Yes.png';
import state12Yes from '../images/state/state12Yes.png';
import state13Yes from '../images/state/state13Yes.png';
import state14Yes from '../images/state/state14Yes.png';
import state15Yes from '../images/state/state15Yes.png';
import state16Yes from '../images/state/state16Yes.png';
import state17Yes from '../images/state/state17Yes.png';
import state18Yes from '../images/state/state18Yes.png';
import state19Yes from '../images/state/state19Yes.png';
import state20Yes from '../images/state/state20Yes.png';
import state21Yes from '../images/state/state21Yes.png';
import state22Yes from '../images/state/state22Yes.png';
import state23Yes from '../images/state/state23Yes.png';
import state24Yes from '../images/state/state24Yes.png';
import state25Yes from '../images/state/state25Yes.png';
import state26Yes from '../images/state/state26Yes.png';
import state27Yes from '../images/state/state27Yes.png';
import state28Yes from '../images/state/state28Yes.png';
import state29Yes from '../images/state/state29Yes.png';
import state30Yes from '../images/state/state30Yes.png';
import state31Yes from '../images/state/state31Yes.png';
import state32Yes from '../images/state/state32Yes.png';
import state33Yes from '../images/state/state33Yes.png';
import state34Yes from '../images/state/state34Yes.png';
import state35Yes from '../images/state/state35Yes.png';
import state36Yes from '../images/state/state36Yes.png';
import state37Yes from '../images/state/state37Yes.png';
import state38Yes from '../images/state/state38Yes.png';
import state39Yes from '../images/state/state39Yes.png';
import state40Yes from '../images/state/state40Yes.png';
import state41Yes from '../images/state/state41Yes.png';
import state42Yes from '../images/state/state42Yes.png';
import state43Yes from '../images/state/state43Yes.png';
import state44Yes from '../images/state/state44Yes.png';
import state45Yes from '../images/state/state45Yes.png';
import state46Yes from '../images/state/state46Yes.png';
import state47Yes from '../images/state/state47Yes.png';
import state48Yes from '../images/state/state48Yes.png';
import state49Yes from '../images/state/state49Yes.png';
import state50Yes from '../images/state/state50Yes.png';
import state51Yes from '../images/state/state51Yes.png';

let curRange = 1;
let yesArray = [];
let totalYes = 0;

const places1F = ["Anchorage", "Badger"];
const places1S = ["Fairbanks"];
const places1T = ["Juneau"];
const places2F = ["Ewa Gentry","Hilo","Honolulu"];
const places2S = ["Kahului","Kailua","Kaneohe"];
const places2T = ["Mililani Town","Pearl City","Waipahu"];
const places3F = ["Bellevue","Bellingham","Bremerton","Everett","Kennewick"];
const places3S = ["Kent","Marysville","Olympia","Renton","Seattle"];
const places3T = ["Spokane","Tacoma","Vancouver","Yakima"];
const places4F = ["Bend","Eugene","Gresham"];
const places4S = ["Hillsboro","Medford"];
const places4T = ["Portland","Salem"];
const places5F = ["Bakersfield","Concord","Fresno","Los Angeles"];
const places5S = ["Mission Viejo","Riverside","Sacramento"];
const places5T = ["San Diego","San Francisco","San Jose"];
const places6F = ["Carson City","Enterprise","Henderson"];
const places6S = ["Las Vegas","Paradise","Reno"];
const places6T = ["Sparks","Spring Valley","Sunrise Manor"];
const places7F = ["Boise","Caldwell","Coeur d'Alene"];
const places7S = ["Idaho Falls","Lewiston","Meridian"];
const places7T = ["Nampa","Pocatello","Twin Falls"];
const places8F = ["Billings","Bozeman","Butte"];
const places8S = ["Great Falls","Helena"];
const places8T = ["Kalispell","Missoula"];
const places9F = ["Casper","Cheyenne"];
const places9S = ["Gillette","Laramie"];
const places9T = ["Rock Springs"];
const places10F = ["Logan","Ogden","Provo"];
const places10S = ["Salt Lake City","St. George"];
const places10T = ["West Jordan","West Valley City"];
const places11F = ["Chandler","Gilbert","Glendale"];
const places11S = ["Mesa","Phoenix"];
const places11T = ["Scottsdale","Tucson"];
const places12F = ["Albuquerque","Farmington","Las Cruces"];
const places12S = ["Los Lunas","Rio Rancho"];
const places12T = ["Roswell","Santa Fe"];
const places13F = ["Aurora","Boulder","Colorado Springs"];
const places13S = ["Denver","Fort Collins","Grand Junction"];
const places13T = ["Lakewood","Pueblo","Thornton"];
const places14F = ["Bismarck","Fargo"];
const places14S = ["Grand Forks","Minot"];
const places14T = ["West Fargo"];
const places15F = ["Aberdeen","Pierre"];
const places15S = ["Rapid City"];
const places15T = ["Sioux Falls"];
const places16F = ["Bellevue","Fremont","Grand Island"];
const places16S = ["Kearney","Lincoln"];
const places16T = ["Norfolk","Omaha"];
const places17F = ["Kansas City","Lawrence","Lenexa"];
const places17S = ["Manhattan","Olathe","Overland Park"];
const places17T = ["Shawnee","Topeka","Wichita"];
const places18F = ["Broken Arrow","Norman"];
const places18S = ["Oklahoma City"];
const places18T = ["Tulsa"];
const places19F = ["Arlington","Austin","Corpus Christi","Dallas","Denton"];
const places19S = ["El Paso","Fort Worth","Houston","Killeen","Laredo"];
const places19T = ["Lubbock","McAllen","McKinney","Plano","San Antonio"];
const places20F = ["Baton Rouge","Houma","Lafayette"];
const places20S = ["Lake Charles","Metairie","Monroe"];
const places20T = ["New Orleans","Shreveport"];
const places21F = ["Fayetteville","Fort Smith"];
const places21S = ["Jonesboro","Little Rock"];
const places21T = ["Springdale"];
const places22F = ["Columbia","Independence"];
const places22S = ["Jefferson City","Kansas City"];
const places22T = ["Springfield","St. Louis"];
const places23F = ["Cedar Rapids","Davenport"];
const places23S = ["Des Moines","Iowa City"];
const places23T = ["Sioux City","Waterloo"];
const places24F = ["Bloomington","Brooklyn Park","Duluth"];
const places24S = ["Minneapolis","Plymouth","Rochester"];
const places24T = ["St. Cloud","St. Paul"];
const places25F = ["Appleton","Eau Claire","Green Bay"];
const places25S = ["Kenosha","La Crosse","Madison"];
const places25T = ["Milwaukee","Racine"];
const places26F = ["Aurora","Bloomington","Champaign","Chicago"];
const places26S = ["Elgin","Joliet","Naperville","Peoria"];
const places26T = ["Rockford","Round Lake Beach","Springfield"];
const places27F = ["Biloxi","Gulfport","Hattiesburg"];
const places27S = ["Jackson","Pascagoula"];
const places27T = ["Southaven","Tupelo"];
const places28F = ["Birmingham","Huntsville"];
const places28S = ["Mobile","Montgomery"];
const places28T = ["Tuscaloosa"];
const places29F = ["Chattanooga","Clarksville","Johnson City"];
const places29S = ["Kingsport","Knoxville","Memphis"];
const places29T = ["Murfreesboro","Nashville"];
const places30F = ["Bowling Green","Covington","Elizabethtown"];
const places30S = ["Frankfort","Lexington","Louisville"];
const places30T = ["Owensboro","Paducah","Richmond"];
const places31F = ["Bloomington","Carmel","Elkhart"];
const places31S = ["Evansville","Fort Wayne","Indianapolis"];
const places31T = ["Lafayette","South Bend"];
const places32F = ["Ann Arbor","Detroit","Flint","Grand Rapids"];
const places32S = ["Holland","Kalamazoo","Lansing","Muskegon"];
const places32T = ["Saginaw","South Lyon","Sterling Heights","Warren"];
const places33F = ["Akron","Canton","Cincinnati"];
const places33S = ["Cleveland","Columbus","Dayton"];
const places33T = ["Lorain","Toledo","Youngstown"];
const places34F = ["Beckley","Charleston","Huntington"];
const places34S = ["Morgantown","Parkersburg"];
const places34T = ["Weirton","Wheeling"];
const places35F = ["Alexandria","Arlington","Charlottesville","Chesapeake"];
const places35S = ["Fredericksburg","Hampton","Lynchburg","Newport News"];
const places35T = ["Norfolk","Richmond","Roanoke","Virginia Beach"];
const places36F = ["Asheville","Charlotte","Concord"];
const places36S = ["Durham","Fayetteville","Greensboro"];
const places36T = ["Raleigh","Wilmington","Winston-Salem"];
const places37F = ["Charleston","Columbia","Greenville"];
const places37S = ["Mauldin","Myrtle Beach","North Charleston"];
const places37T = ["Rock Hill","Spartanburg"];
const places38F = ["Athens","Atlanta","Augusta"];
const places38S = ["Columbus","Gainesville","Macon"];
const places38T = ["Savannah","Warner Robins"];
const places39F = ["Bonita Springs","Cape Coral","Jacksonville","Kissimmee","Miami"];
const places39S = ["Orlando","Palm Bay","Palm Coast","Pensacola","Port St. Lucie"];
const places39T = ["Sarasota","St. Petersburg","Tallahassee","Tampa"];
const places40F = ["Albany","Bronx","Brookhaven","Brooklyn","Buffalo"];
const places40S = ["Hempstead","Huntington","Manhattan","New York","Queens"];
const places40T = ["Rochester","Staten Island","Syracuse","Yonkers"];
const places41F = ["Allentown","Erie","Harrisburg","Lancaster"];
const places41S = ["Philadelphia","Pittsburgh","Pottstown"];
const places41T = ["Reading","Scranton","York"];
const places42F = ["Annapolis","Baltimore","Columbia"];
const places42S = ["Frederick","Germantown","Hagerstown"];
const places42T = ["Salisbury","Silver Spring","Waldorf"];
const places43F = ["Washington D.C"];
const places43S = [""];
const places43T = [""];
const places44F = ["Bear","Dover","Lewes"];
const places44S = ["Middletown","Milford"];
const places44T = ["Newark","Wilmington"];
const places45F = ["Atlantic City","Edison","Elizabeth"];
const places45S = ["Jersey City","Lakewood","Newark"];
const places45T = ["Paterson","Trenton","Woodbridge"];
const places46F = ["Bridgeport","Danbury","Hartford"];
const places46S = ["New Haven","Norwich"];
const places46T = ["Stamford","Waterbury"];
const places47F = ["Cranston","East Providence"];
const places47S = ["Pawtucket","Providence"];
const places47T = ["Warwick","Woonsocket"];
const places48F = ["Barnstable","Boston","Cambridge"];
const places48S = ["Leominster","Lowell","New Bedford"];
const places48T = ["Springfield","Worcester"];
const places49F = ["Barre","Burlington","Colchester"];
const places49S = ["Essex","Montpelier"];
const places49T = ["Rutland","South Burlington"];
const places50F = ["Concord","Dover"];
const places50S = ["Manchester","Nashua"];
const places50T = ["Portsmouth"];
const places51F = ["Auburn","Augusta","Bangor"];
const places51S = ["Lewiston","Portland","Sanford"];
const places51T = ["South Portland","Waterville"];

let placesObj = {places1F, places1S, places1T, places2F, places2S, places2T, places3F, places3S, places3T, places4F, places4S, places4T, places5F, places5S, places5T, places6F, places6S, places6T, places7F, places7S, places7T, places8F, places8S, places8T, places9F, places9S, places9T, places10F, places10S, places10T, places11F, places11S, places11T, places12F, places12S, places12T, places13F, places13S, places13T, places14F, places14S, places14T, places15F, places15S, places15T, places16F, places16S, places16T, places17F, places17S, places17T, places18F, places18S, places18T, places19F, places19S, places19T, places20F, places20S, places20T, places21F, places21S, places21T, places22F, places22S, places22T, places23F, places23S, places23T, places24F, places24S, places24T, places25F, places25S, places25T, places26F, places26S, places26T, places27F, places27S, places27T, places28F, places28S, places28T, places29F, places29S, places29T
    , places30F, places30S, places30T, places31F, places31S, places31T, places32F, places32S, places32T, places33F, places33S, places33T, places34F, places34S, places34T, places35F, places35S, places35T, places36F, places36S, places36T, places37F, places37S, places37T, places38F, places38S, places38T, places39F, places39S, places39T
    , places40F, places40S, places40T, places41F, places41S, places41T, places42F, places42S, places42T, places43F, places43S, places43T, places44F, places44S, places44T, places45F, places45S, places45T, places46F, places46S, places46T, places47F, places47S, places47T, places48F, places48S, places48T, places49F, places49S, places49T
    , places50F, places50S, places50T, places51F, places51S, places51T};

const title1 = ["Alaska"];
const title2 = ["Hawaii"];
const title3 = ["Washington"];
const title4 = ["Oregon"];
const title5 = ["California"];
const title6 = ["Nevada"];
const title7 = ["Idaho"];
const title8 = ["Montana"];
const title9 = ["Wyoming"];
const title10 = ["Utah"];
const title11 = ["Arizona"];
const title12 = ["New Mexico"];
const title13 = ["Colorado"];
const title14 = ["North Dakota"];
const title15 = ["South Dakota"];
const title16 = ["Nebraska"];
const title17 = ["Kansas"];
const title18 = ["Oklahoma"];
const title19 = ["Texas"];
const title20 = ["Lousiana"];
const title21 = ["Arkansas"];
const title22 = ["Missouri"];
const title23 = ["Iowa"];
const title24 = ["Minnesota"];
const title25 = ["Wisconsin"];
const title26 = ["Illinois"];
const title27 = ["Mississippi"];
const title28 = ["Alabama"];
const title29 = ["Tennessee"];
const title30 = ["Kentucky"];
const title31 = ["Indiana"];
const title32 = ["Michigan"];
const title33 = ["Ohio"];
const title34 = ["West Virginia"];
const title35 = ["Virginia"];
const title36 = ["North Carolina"];
const title37 = ["South Carolina"];
const title38 = ["Georgia"];
const title39 = ["Florida"];
const title40 = ["New York"];
const title41 = ["Pennsylvania"];
const title42 = ["Maryland"];
const title43 = ["District of Columbia"];
const title44 = ["Delaware"];
const title45 = ["New Jersey"];
const title46 = ["Connecticut"];
const title47 = ["Rhode Island"];
const title48 = ["Massachusetts"];
const title49 = ["Vermont"];
const title50 = ["New Hampshire"];
const title51 = ["Maine"];

let titleObj = {title1, title2, title3, title4, title5, title6, title7, title8, title9, title10,title11, title12, title13, title14, title15, title16, title17, title18, title19, title20,title21, title22, title23, title24, title25, title26, title27, title28, title29, title30,title31, title32, title33, title34, title35, title36, title37, title38, title39, title40,
    title41, title42, title43, title44, title45, title46, title47, title48, title49, title50, title51};

function States() {
  const linkURL = window.location.href;
  const [isPrevDisabled, setIsPrevDisabled] = React.useState(true);
  const [isNextDisabled, setIsNextDisabled] = React.useState(false);

  const prevRange = (e) => {
    e.preventDefault();
    const places1 = document.getElementById("places1");
    const places2 = document.getElementById("places2");
    const places3 = document.getElementById("places3");
    const stateText = document.getElementById("stateText");
    const checkboxSlider = document.getElementById("checkboxSlider");
    const prevRange = curRange - 1;
    const currAct = "state" + curRange + "Act";
    const prevAct = "state" + prevRange + "Act";
    document.getElementById(currAct).classList.add("hidden");
    document.getElementById(prevAct).classList.remove("hidden");
    if (curRange === 2){
      setIsPrevDisabled(!isPrevDisabled);
    }
    if (curRange === 51){
      setIsNextDisabled(!isNextDisabled);
    }
    curRange--;
    const rangeCheck = yesArray.includes(curRange);
    if (rangeCheck === true) {
      checkboxSlider.checked = true;
    }
    else {
      checkboxSlider.checked = false;
    }
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const key1 = "places" + curRange + "F";
    const key2 = "places" + curRange + "S";
    const key3 = "places" + curRange + "T";
    const lookup1 = placesObj[key1];
    const lookup2 = placesObj[key2];
    const lookup3 = placesObj[key3];
    lookup1.forEach(function(el){
       places1.innerHTML = text1+= el + '</br>';
     });
    lookup2.forEach(function(el){
       places2.innerHTML = text2+= el + '</br>';
     });
    lookup3.forEach(function(el){
         places3.innerHTML = text3+= el + '</br>';
      });
      const stateKey = "title" + curRange;
      const stateLabel = titleObj[stateKey];
      stateText.innerHTML = "Have you been to " + stateLabel + "?";
    return true;
  }

  const nextRange = (e) => {
    e.preventDefault();
    const places1 = document.getElementById("places1");
    const places2 = document.getElementById("places2");
    const places3 = document.getElementById("places3");
    const stateText = document.getElementById("stateText");
    const checkboxSlider = document.getElementById("checkboxSlider");
    const nextRange = curRange + 1;
    const currAct = "state" + curRange + "Act";
    const nextAct = "state" + nextRange + "Act";
    document.getElementById(currAct).classList.add("hidden");
    document.getElementById(nextAct).classList.remove("hidden");
    if (curRange === 1){
      setIsPrevDisabled(!isPrevDisabled);
    }
    if (curRange === 50){
      setIsNextDisabled(!isNextDisabled);
    }
    curRange++;
    const rangeCheck = yesArray.includes(curRange);
    if (rangeCheck === true) {
      checkboxSlider.checked = true;
    }
    else {
      checkboxSlider.checked = false;
    }
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const key1 = "places" + curRange + "F";
    const key2 = "places" + curRange + "S";
    const key3 = "places" + curRange + "T";
    const lookup1 = placesObj[key1];
    const lookup2 = placesObj[key2];
    const lookup3 = placesObj[key3];
    lookup1.forEach(function(el){
       places1.innerHTML = text1+= el + '</br>';
     });
    lookup2.forEach(function(el){
       places2.innerHTML = text2+= el + '</br>';
     });
    lookup3.forEach(function(el){
         places3.innerHTML = text3+= el + '</br>';
      });
    const stateKey = "title" + curRange;
    const stateLabel = titleObj[stateKey];
    stateText.innerHTML = "Have you been to " + stateLabel + "?";
    return true;
  }

  const checkYes = (e) => {
    const checkboxSlider = document.getElementById("checkboxSlider");
    const resultText = document.getElementById("resultText");
    if (checkboxSlider.checked === true){
      totalYes++;
      const rangeID = 'state' + curRange + 'Yes';
      document.getElementById(rangeID).classList.remove("hidden");
      yesArray.push(curRange);
      let x = Math.round(totalYes/51*100);
      resultText.innerHTML = "You have been to " + x + "%";
      // console.log("totalYes = ", totalYes);
      return true;
    }
    else {
      totalYes--;
      const rangeID = 'state' + curRange + 'Yes';
      document.getElementById(rangeID).classList.add("hidden");
      const index = yesArray.indexOf(curRange);
      if (index > -1) { // only splice array when item is found
        yesArray.splice(index, 1); // 2nd parameter means remove one item only
      }
      let x = Math.round(totalYes/51*100);
      resultText.innerHTML = "You have been to " + x + "%";
      // console.log("totalYes = ", totalYes);
      return true
    }


  }

    return (
      <div id="mainContent">
        <PageIntro pageTitle="States" output="meta"/>
        <div id="stateSection1" className="pageSection">
            <div className="row">
              <div className="col-md-9">
                <div id="pageIntrostate">
                  <PageIntro pageTitle="States" output="intro"/>
                </div>
                <div className="statePanel">
                <div className="resultRow">
                    <h2 id="resultText">You have been to 0%</h2>
                </div>
                <div className="stateImages">
                  <img className="stateMap img-fluid mx-auto " id="stateBackMap" alt="map background" src={stateMap} />
                  <img className="stateMapOverlay img-fluid mx-auto" alt="active state range 1" id="state1Act" src={state1Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 2" id="state2Act" src={state2Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 3" id="state3Act" src={state3Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 4" id="state4Act" src={state4Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 5" id="state5Act" src={state5Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 6" id="state6Act" src={state6Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 7" id="state7Act" src={state7Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 8" id="state8Act" src={state8Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 9" id="state9Act" src={state9Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 10" id="state10Act" src={state10Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 11" id="state11Act" src={state11Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 12" id="state12Act" src={state12Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 13" id="state13Act" src={state13Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 14" id="state14Act" src={state14Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 15" id="state15Act" src={state15Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 16" id="state16Act" src={state16Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 17" id="state17Act" src={state17Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 18" id="state18Act" src={state18Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 19" id="state19Act" src={state19Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 20" id="state20Act" src={state20Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 21" id="state21Act" src={state21Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 22" id="state22Act" src={state22Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 23" id="state23Act" src={state23Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 24" id="state24Act" src={state24Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 25" id="state25Act" src={state25Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 26" id="state26Act" src={state26Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 27" id="state27Act" src={state27Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 28" id="state28Act" src={state28Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 29" id="state29Act" src={state29Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 30" id="state30Act" src={state30Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 31" id="state31Act" src={state31Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 32" id="state32Act" src={state32Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 33" id="state33Act" src={state33Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 34" id="state34Act" src={state34Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 35" id="state35Act" src={state35Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 36" id="state36Act" src={state36Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 37" id="state37Act" src={state37Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 38" id="state38Act" src={state38Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 39" id="state39Act" src={state39Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 40" id="state40Act" src={state40Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 41" id="state41Act" src={state41Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 42" id="state42Act" src={state42Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 43" id="state43Act" src={state43Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 44" id="state44Act" src={state44Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 45" id="state45Act" src={state45Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 46" id="state46Act" src={state46Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 47" id="state47Act" src={state47Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 48" id="state48Act" src={state48Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 49" id="state49Act" src={state49Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 50" id="state50Act" src={state50Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 51" id="state51Act" src={state51Act} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 1" id="state1Yes" src={state1Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 2" id="state2Yes" src={state2Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 3" id="state3Yes" src={state3Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 4" id="state4Yes" src={state4Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 5" id="state5Yes" src={state5Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 6" id="state6Yes" src={state6Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 7" id="state7Yes" src={state7Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 8" id="state8Yes" src={state8Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 9" id="state9Yes" src={state9Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 10" id="state10Yes" src={state10Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 11" id="state11Yes" src={state11Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 12" id="state12Yes" src={state12Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 13" id="state13Yes" src={state13Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 14" id="state14Yes" src={state14Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 15" id="state15Yes" src={state15Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 16" id="state16Yes" src={state16Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 17" id="state17Yes" src={state17Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 18" id="state18Yes" src={state18Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 19" id="state19Yes" src={state19Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 20" id="state20Yes" src={state20Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 21" id="state21Yes" src={state21Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 22" id="state22Yes" src={state22Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 23" id="state23Yes" src={state23Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 24" id="state24Yes" src={state24Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 25" id="state25Yes" src={state25Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 26" id="state26Yes" src={state26Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 27" id="state27Yes" src={state27Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 28" id="state28Yes" src={state28Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 29" id="state29Yes" src={state29Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 30" id="state30Yes" src={state30Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 31" id="state31Yes" src={state31Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 32" id="state32Yes" src={state32Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 33" id="state33Yes" src={state33Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 34" id="state34Yes" src={state34Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 35" id="state35Yes" src={state35Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 36" id="state36Yes" src={state36Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 37" id="state37Yes" src={state37Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 38" id="state38Yes" src={state38Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 39" id="state39Yes" src={state39Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 40" id="state40Yes" src={state40Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 41" id="state41Yes" src={state41Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 42" id="state42Yes" src={state42Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 43" id="state43Yes" src={state43Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 44" id="state44Yes" src={state44Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 45" id="state45Yes" src={state45Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 46" id="state46Yes" src={state46Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 47" id="state47Yes" src={state47Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 48" id="state48Yes" src={state48Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 49" id="state49Yes" src={state49Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 50" id="state50Yes" src={state50Yes} />
                  <img className="stateMapOverlay hidden img-fluid mx-auto" alt="active state range 51" id="state51Yes" src={state51Yes} />

                  
                </div>
                <div className="stateControls">
                  <h3  id="stateText" >Have you been to Alaska?</h3>
                  <div className="controlBlock">
                    <div className="container">
                      <div className="row">
                        <div className="col-3">
                          <button className="btn btn-outline-primary btn-sm  rangeButton" type="button" onClick={prevRange} disabled={isPrevDisabled}>Previous</button>
                        </div>
                        <div className="col-6">
                          <div className="sliderControl">
                            <div className="row">
                              <div className="col-4">
                                <p className="sliderLabel" id="noLabel">NO</p>
                              </div>
                              <div className="col-4">
                                <label className="switch">
                                  <input type="checkbox" id="checkboxSlider" onClick={checkYes}></input>
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="col-4">
                                <p className="sliderLabel" id="yesLabel">YES</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <button className="btn btn-outline-primary btn-sm rangeButton" type="button" onClick={nextRange} disabled={isNextDisabled}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="statePlaces">
                  <h4>Here are some places that are within the state.</h4>
                  <div className="container placesList">
                    <div className="row">
                      <div className="col-md-4">
                        <div id="places1Div">
                        <p id="places1">
                        Anchorage
                        <br></br>
                        Badger
                        </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div id="places2Div">
                        <p id="places2">
                          Fairbanks
                        </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div id="places3Div">
                        <p id="places3">
                            Juneau
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="affilAd adLand">
                <PageIntro pageTitle="States" output="advert"/>
                </div>
                <div className="shareButtonsBottom">
            <div className="shareText">
              <p className="shareP">SHARE:</p>
            </div>
            <div className="shareIcon" id="shareFacebook">
              <FacebookShareButton 
                url={linkURL}
                quote={"The Ordinary Tourist - We help you explore the world"}
                hashtag="#theordinarytourist"
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
              </FacebookShareButton>
            </div>
            <div className="shareIcon" id="shareTwitter">
              <TwitterShareButton
                url={linkURL}
                title={"How many states have you been to?"}
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
<path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
	S18.6,0,12,0z M5.7,6.1l4.9,6.5L5.7,18h1.1l4.3-4.7l3.5,4.7h3.8L13.2,11l4.6-5h-1.1l-4,4.3L9.5,6.1H5.7z M7.3,6.9h1.7l7.7,10.2H15
	L7.3,6.9z"/>
</svg>
              </TwitterShareButton>
            </div>
          </div>
                <div className="pageLinks">
                  <Link to={'../fun'}>View more fun games</Link>
                </div>
              </div>
              <div className="col-md-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
export default States;
