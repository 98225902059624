const client = require('contentful').createClient({
  space: "ovr29l94zpl6",
  accessToken: "-65fCEAxxxRPBfJcUV8dREvznjq1ABpyAOKECcj0uSk"
})

const getBlogTotal = () => client.getEntries({
  content_type: 'blogPost',
  select: 'fields.title'
})
.then(response => response.items)

const getPageIntros = () => client.getEntries({
  content_type: "pageIntroductions"
}).then(response => response.items)

const getSinglePageIntro = page =>
client
.getEntries({
  'fields.page': page,
  content_type: 'pageIntroductions'
})
.then(response => response.items)

const getAuthors = () => client.getEntries({
  content_type: "authors",
  order:"sys.createdAt"
}).then(response => response.items)

const getSingleBlogPost = blog =>
client
.getEntries({
  'fields.slug': blog,
  content_type: 'blogPost'
})
.then(response => response.items)

const getMultipleBlogSummaries = blogs =>
client
.getEntries({
  'fields.slug[in]': blogs,
  content_type: 'blogPost',
  select: 'fields.title,fields.summary,fields.publishedDate,fields.tags,fields.rollUpImage,fields.slug'
})
.then(response => response.items)

const getMultipleSummaries = tag =>
client
.getEntries({
  'fields.tags': tag,
  content_type: 'blogPost',
  select: 'fields.title,fields.summary,fields.publishedDate,fields.tags,fields.rollUpImage,fields.slug'
})
.then(response => response.items)

const getFiveBlogSummaries = (skip, sortOrder) =>
client
.getEntries({
  order: sortOrder,
  limit: 5,
  skip: skip,
  content_type: 'blogPost',
  select: 'fields.title,fields.summary,fields.publishedDate,fields.tags,fields.rollUpImage,fields.slug'
})
.then(response => response.items)

const getFiveFeaturedBlogs = (skip, sortOrder) =>
client
.getEntries({
  order: sortOrder,
  limit: 5,
  skip: skip,
  content_type: 'featuredBlogs',
  select: 'fields.title,fields.blog,fields.order'
})
.then(response => response.items)

const getSingleCountry = country =>
client
.getEntries({
  'fields.slug': country,
  content_type: 'country'
})
.then(response => response.items)

const getCountrySummaries = (skip, sortOrder, limit) =>
client
.getEntries({
  order: sortOrder,
  limit: limit,
  skip: skip,
  content_type: 'country',
  select: 'fields.countryName,fields.summary,fields.publishedDate,fields.rollUpImage,fields.slug'
})
.then(response => response.items)

const getCountryTotal = () => client.getEntries({
  content_type: 'country',
  select: 'fields.countryName'
})
.then(response => response.items)

const getSingleQuiz = quiz =>
client
.getEntries({
  'fields.slug': quiz,
  content_type: 'quizzes'
})
.then(response => response.items)

const getQuizSummaries = (skip, sortOrder, limit) =>
client
.getEntries({
  order: sortOrder,
  limit: limit,
  skip: skip,
  content_type: 'quizzes',
  select: 'fields.name,fields.summary,fields.publishedDate,fields.tags,fields.rollUpImage,fields.slug'
})
.then(response => response.items)

const getQuizTotal = () => client.getEntries({
  content_type: 'quizzes',
  select: 'fields.name'
})
.then(response => response.items)

const getSingleAsset = assetID =>
client
.getAssets({
  'sys.id': assetID
})
.then(response => response.items)

const getFeaturedGames = () => client.getEntries({
  content_type: "games",
  order:"sys.createdAt",
  'fields.featured': "Yes"
}).then(response => response.items)

export { getPageIntros, getSinglePageIntro, getAuthors, getSingleBlogPost, getMultipleBlogSummaries, getMultipleSummaries, getFiveBlogSummaries, getBlogTotal,
  getSingleCountry, getCountrySummaries, getCountryTotal, getSingleQuiz, getQuizSummaries, getQuizTotal, getSingleAsset, getFeaturedGames, getFiveFeaturedBlogs}
