import { useEffect, useState } from 'react'
import { getSingleBlogPost } from '../Contentful'

export default function useSingleBlogPost(blog) {
  const promise = getSingleBlogPost(blog)
  //console.log("test 1");
  const [blogPost, setBlogPost] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setBlogPost(result[0])
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [blogPost, isLoading]
}
