import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import SingleCountry from '../components/SingleCountry';
import DisplayAdvert from '../components/DisplayAdvert';  

function Country() {

  const { id } = useParams();
  const navigate = useNavigate();
	const goBack = (e) => {
    e.preventDefault();
		navigate(-1);
	}

    return (
      <div id="mainContent" className="parentDiv">
        <div id="countrySection1" className="pageSection">
          <div className="backLink">
              <Link to='../../country' onClick={goBack}>&lt; BACK </Link>
          </div>
            <div className="row">
              <div className="col-md-9">
                <div className="countryPanel">
                  <SingleCountry countryTitle={id} />
                </div>
              </div>
              <div className="col-md-3">
              <div className="googleAdPort">
                 <DisplayAdvert dataAdSlot='1194019360' />
                </div>
                <div className="googleAdPort">
                  <DisplayAdvert dataAdSlot='1629117721' />
                </div>
              </div>
            </div>
            <div className="backLink">
                <Link to='../../country' onClick={goBack}>&lt; BACK </Link>
            </div>
        </div>
      </div>
    );
  }
export default Country;
