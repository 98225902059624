import { useEffect, useState } from 'react'
import { getSingleAsset } from '../Contentful'

export default function useSingleAsset(assetID) {
  const promise = getSingleAsset(assetID)
  const [asset, setAsset] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setAsset(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [asset, isLoading]
}
