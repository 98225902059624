import { useEffect, useState } from 'react'
import { getSingleQuiz} from '../Contentful'

export default function useSingleQuiz(quiz) {
  
  // console.log("useSingleQuiz ", quiz);
  const promise = getSingleQuiz(quiz)
  //console.log("test 1");
  const [quizPost, setQuizPost] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setQuizPost(result[0])
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [quizPost, isLoading]
}
