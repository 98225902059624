import { useEffect, useState } from 'react'
import { getCountryTotal } from '../Contentful'

export default function useCountryTotal() {
  const promise = getCountryTotal();
  //console.log("test 1");
  const [countryPostTotals, setCountryPostTotals] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    promise.then(result => {
      setCountryPostTotals(result)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [countryPostTotals, isLoading]
}
