import React from 'react';
import { Link } from 'react-router-dom';
import useFiveFeaturedBlogs from '../custom-hooks/useFiveFeaturedBlogs';
import useSingleBlogPost from '../custom-hooks/useSingleBlogPost';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import moment from 'moment';
import ReactHtmlParser from 'html-react-parser';

function FivePostTiles (props) {
  const skip = props.skip;
  const sortOrder = props.sort;
  const [featuredBlogs, isLoading] = useFiveFeaturedBlogs(skip,sortOrder);

  if (isLoading === true) {
    return <p>Loading...</p>;
  }
  // console.log("featuredBlogs = ", featuredBlogs);

  const linkURL0 = "../blog/" + featuredBlogs[0].fields.blog.fields.slug;
  const linkURL1 = "../blog/" + featuredBlogs[1].fields.blog.fields.slug;
  const linkURL2 = "../blog/" + featuredBlogs[2].fields.blog.fields.slug;
  const linkURL3 = "../blog/" + featuredBlogs[3].fields.blog.fields.slug;
  const linkURL4 = "../blog/" + featuredBlogs[4].fields.blog.fields.slug;

  const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
            `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
    },
};

  // const rawRichTextField = authors[0].content;
  // const outputHTML = documentToHtmlString(rawRichTextField, options);

//fields.title,fields.summary,fields.rollUpImage,fields.tags,fields.publishedDate

  return (
    <div className="row featuredArticleTiles">
      <div className="mainArticle">
        <div className="imgHolder">
          <Link to={linkURL0} reloadDocument className="blogTileLink">
            <img className="blogTileImage img-fluid mx-auto" src={featuredBlogs[0].fields.blog.fields.rollUpImage.fields.file.url} alt={featuredBlogs[0].fields.blog.fields.rollUpImage.fields.title} />
            <div className="cardContent">
              <h2>{featuredBlogs[0].fields.blog.fields.title}</h2>
            </div>
          </Link>
        </div>
      </div>
      <div className="mobileTileLayout">
        <div className="moreArticles">
          <div className="imgHolder imgHolderTop imgHolderLeft">
            <Link to={linkURL1} reloadDocument className="blogTileLink">
              <img className="blogTileImage img-fluid mx-auto" src={featuredBlogs[1].fields.blog.fields.rollUpImage.fields.file.url} alt={featuredBlogs[1].fields.blog.fields.rollUpImage.fields.title} />
              <div className="cardContent">
                <h3>{featuredBlogs[1].fields.blog.fields.title}</h3>
              </div>
            </Link>
          </div>
          <div className="imgHolder imgHolderBottom imgHolderLeft">
            <Link to={linkURL2} reloadDocument className="blogTileLink">
              <img className="blogTileImage img-fluid mx-auto" src={featuredBlogs[2].fields.blog.fields.rollUpImage.fields.file.url} alt={featuredBlogs[2].fields.blog.fields.rollUpImage.fields.title} />
              <div className="cardContent">
                <h3>{featuredBlogs[2].fields.blog.fields.title}</h3>
              </div>
            </Link>
          </div>
        </div>
        <div className="moreArticles">
          <div className="imgHolder imgHolderTop imgHolderRight">
            <Link to={linkURL3} reloadDocument className="blogTileLink">
              <img className="blogTileImage img-fluid mx-auto" src={featuredBlogs[3].fields.blog.fields.rollUpImage.fields.file.url} alt={featuredBlogs[3].fields.blog.fields.rollUpImage.fields.title} />
              <div className="cardContent">
                <h3>{featuredBlogs[3].fields.blog.fields.title}</h3>
              </div>
            </Link>
          </div>
          <div className="imgHolder imgHolderBottom imgHolderRight">
            <Link to={linkURL4} reloadDocument className="blogTileLink">
              <img className="blogTileImage img-fluid mx-auto" src={featuredBlogs[4].fields.blog.fields.rollUpImage.fields.file.url} alt={featuredBlogs[4].fields.blog.fields.rollUpImage.fields.title} />
              <div className="cardContent">
                <h3>{featuredBlogs[4].fields.blog.fields.title}</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FivePostTiles;
