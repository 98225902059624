import React from 'react';
import useSingleCountry from '../custom-hooks/useSingleCountry';
import ReactMarkdown from 'react-markdown';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import remarkGfm from 'remark-gfm';
import remarkUnwrapImages from 'remark-unwrap-images';
import fbIcon from '../images/facebookIcon.png';
import twitterIcon from '../images/twitterIcon.png';
import { useNavigate, useLocation } from 'react-router-dom';
import RelatedBlogs from "./RelatedBlogs.js";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import remarkBreaks from 'remark-breaks';
import ReactHtmlParser from 'html-react-parser';
import {FacebookShareButton, TwitterShareButton} from "react-share";

function SingleCountry (props) {
  const navigate = useNavigate();
  const country = props.countryTitle;
  // console.log("props.countryTitle = ", props.countryTitle);
  const [countryPost, isLoading] = useSingleCountry(country);
  // console.log("isLoading = ", isLoading);

  if(isLoading === true) {
    return <p>Loading...</p>;
  }

  if(!countryPost) {
    const newPath = "../countries";
    navigate(newPath);
  }
  // console.log("countryPost = ", countryPost);

   // const rawRichTextField = countryPost.content1;
   // const outputHTML = documentToHtmlString(rawRichTextField, options);
   //to display above use {ReactHtmlParser(outputHTML)} inside a div

   const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
            `<img src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
    },
};
   const details = countryPost.fields.details;
   const highlights = countryPost.fields.highlights;
  //  console.log("highlights = ", highlights);
   const rawRichTextField = countryPost.fields.summary;
   const outputHTML = documentToHtmlString(rawRichTextField, options);
   let plainString = outputHTML.replace(/<[^>]+>/g, '');
   plainString = plainString.replace(/&quot;/g, '');
   let titleString = countryPost.fields.countryName + " - The Ordinary Tourist";
   const linkURL = window.location.href;

  return (
      <div className="countryPostContent">
        <HelmetProvider>
      <Helmet>
        <meta name="keywords" content={countryPost.fields.keywords} />
        <meta name="description" content={plainString} />
        <meta property="og:url" content={linkURL} />
        <meta property="description" content={plainString} />
        <meta property="title" content={titleString} />
        <meta property="og:image" content={countryPost.fields.flag.fields.file.url} />
        <title>{countryPost.fields.countryName} - The Ordinary Tourist</title>
      </Helmet>
      </HelmetProvider>
        <div className="introSection">
          <div className="row">
            <div className="col-10">
              <h1>{countryPost.fields.countryName}</h1>
              <p className="authorMeta">By {countryPost.fields.author.fields.title}</p>
              <p className="blogMeta"><span className="boldText">Visited: </span>{countryPost.fields.when}</p>
            </div>
            <div className="col-2">
              <div className="flagImage">
                <img className="flagImg img-fluid mx-auto" src={countryPost.fields.flag.fields.file.url} alt={countryPost.fields.flag.fields.title} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
            </div>
            <div className="col-6">
              <div className="shareButtonsTop">
                <div className="shareText">
                  <p className="shareP">SHARE:</p>
                </div>
                <div className="shareIcon" id="shareFacebook">
              <FacebookShareButton 
                url={linkURL}
                quote={"The Ordinary Tourist - We help you explore the world"}
                hashtag="#theordinarytourist"
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
              </FacebookShareButton>
            </div>
            <div className="shareIcon" id="shareTwitter">
              <TwitterShareButton
                url={linkURL}
                title={countryPost.fields.countryName}
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
<path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
	S18.6,0,12,0z M5.7,6.1l4.9,6.5L5.7,18h1.1l4.3-4.7l3.5,4.7h3.8L13.2,11l4.6-5h-1.1l-4,4.3L9.5,6.1H5.7z M7.3,6.9h1.7l7.7,10.2H15
	L7.3,6.9z"/>
</svg>
              </TwitterShareButton>
            </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bannerImage">
          <img className="bannerImg img-fluid mx-auto" src={countryPost.fields.bannerImage.fields.file.url} alt={countryPost.fields.bannerImage.fields.title} />
        </div>
        <div className="mainCountryContent">
        <div className="countryContent" id="countryContent1">
          <ReactMarkdown remarkPlugins={[remarkGfm, remarkUnwrapImages]}>{details}</ReactMarkdown>
        </div>
        <div className="affilAd adLand">
        {ReactHtmlParser(countryPost.fields.advert1.fields.advertHtml)}
        </div>
        <div className="countryContent" id="countryContent2">
          <h2>Highlights</h2>
          <ReactMarkdown remarkPlugins={[remarkBreaks]}>{highlights}</ReactMarkdown>
        </div>
        <div className="affilAd adLand">
        {ReactHtmlParser(countryPost.fields.advert2.fields.advertHtml)}
        </div>
        <div className="relatedBlogs">
            <RelatedBlogs tag={countryPost.fields.countryName} />
          </div>
          <div className="affilAd adLand">
        {ReactHtmlParser(countryPost.fields.advert3.fields.advertHtml)}
        </div>
        </div>
        <div className="shareButtonsBottom">
            <div className="shareText">
              <p className="shareP">SHARE:</p>
            </div>
            <div className="shareIcon" id="shareFacebook">
              <FacebookShareButton 
                url={linkURL}
                quote={"The Ordinary Tourist - We help you explore the world"}
                hashtag="#theordinarytourist"
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg>
              </FacebookShareButton>
            </div>
            <div className="shareIcon" id="shareTwitter">
              <TwitterShareButton
                url={linkURL}
                title={countryPost.fields.countryName}
                className="socialMediaButton">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
<path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12
	S18.6,0,12,0z M5.7,6.1l4.9,6.5L5.7,18h1.1l4.3-4.7l3.5,4.7h3.8L13.2,11l4.6-5h-1.1l-4,4.3L9.5,6.1H5.7z M7.3,6.9h1.7l7.7,10.2H15
	L7.3,6.9z"/>
</svg>
              </TwitterShareButton>
            </div>
          </div>
      </div>
    )
}

export default SingleCountry;
